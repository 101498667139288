/* ----------- RESOURCES ----------- */
import { forwardRef, Ref } from "react"

/* ----------- RESOURCES ----------- */
import { TextareaProps } from "contracts/Admin"

/* ----------- RESOURCES ----------- */
import styles from 'components/admin/Textarea/styles.module.scss'

const Textarea = forwardRef((
    {
        className = '',
        height='195px',
        ...props
    }: TextareaProps,
    ref: Ref<HTMLTextAreaElement>
) => {
    return (
        <textarea
            ref={ref}
            className={`${styles.textarea} ${className}`}
            style={{ height }}
            {...props}
        ></textarea>
    );
})

export default Textarea