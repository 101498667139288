interface FormatError {
    name: string;
    message: string;
}

function formatErrors400(
    errors: object,
    callable: (value: FormatError, index: number, array: FormatError[]) => void
) {
    const formattedErrors = Object.keys(errors).map((key) => ({
        name: key,
        message: errors[key][0],
    }));

    formattedErrors.forEach(callable);
}

export { formatErrors400 };
