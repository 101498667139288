/* ----------- RESOURCES ----------- */
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "providers/AuthProvider";
import { menusHome } from "constants/company-menus";

/* ----------- COMPONENTS ----------- */
import { Col, Card } from "react-bootstrap";

/* ----------- STYLES ----------- */
import styles from "pages/company/home/styles.module.scss";
import Icon from "components/Icon";

export default function Home() {
    const navigate = useNavigate();
    const { token, typeUser, signOut } = useAuth();

    if (!token || typeUser !== 'company') {
        return <Navigate to='/' replace />
    }

    return (
        <Col
            className={`col-12 ${styles.home_container} py-3 py-xxl-2 py-xl-2 py-lg-2 py-md-3 py-sm-3 px-1 px-xxl-0 px-xl-0 px-lg-0 px-md-1 px-sm-1 d-flex justify-content-center align-items-center`}
        >
            <Col className="col-12 mx-auto col-xxl-9 col-xl-9 col-lg-9 col-md-10 col-sm-12 text-center">
                <h1 className={styles.h1}>Bem-vindo(a)!</h1>

                <p
                    className={`${styles.description} col-12 col-xxl-5 col-xl-5 col-lg-5 col-md-7 col-sm-12 mx-auto mb-5`}
                >
                    Acesse abaixo os módulos de gestão de subsídios.
                </p>

                <Col className="d-flex gap-3 justify-content-center align-items-center flex-wrap">
                    {menusHome.map(({ title, icon, path }, index) => (
                        <Card key={index} className={styles.card_container}>
                            <Card.Body
                                className={`${styles.card_body} d-flex flex-column justify-content-center align-items-center`}
                                onClick={() => navigate(path)}
                            >
                                <Icon name={icon} alt={title} className={`${styles.icon} mb-3`} />
                                <label className={`${styles.title}`}>{title}</label>
                            </Card.Body>
                        </Card>
                    ))}

                    <Card className={styles.card_logout_container}>
                        <Card.Body
                            className={`${styles.card_body} d-flex flex-column justify-content-center align-items-center`}
                            onClick={() => signOut()}
                        >
                            {/* <img src={LogoutIcon}  /> */}
                            <Icon name="logout" alt="logout" className={`${styles.icon} mb-3`} />

                            <label className={`${styles.title}`}>Logout</label>
                        </Card.Body>
                    </Card>
                </Col>
            </Col>
        </Col>
    );
}
