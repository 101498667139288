/* ----------- RESOURCES ----------- */
import { forwardRef, Ref } from "react";

/* ----------- COMPONENTS ----------- */
import Button from "components/Button";

/* ----------- CONTRACTS ----------- */
import { ButtonIconProps } from "contracts/Buttons";

/* ----------- COMPONENTS ----------- */
import { Spinner } from "react-bootstrap";
import Icon from "components/Icon";

/* ----------- STYLES ----------- */
import styles from "components/ButtonIcon/styles.module.scss";

const ButtonIcon = forwardRef(
    ({ children, text, icon, ...props }: ButtonIconProps, ref: Ref<HTMLButtonElement>) => {
        let classIcon = styles.icon;

        if (children || text) {
            classIcon += ` ${styles.icon_left}`;
        }

        return (
            <Button ref={ref} {...props}>
                {children || text || ""}
                {icon &&
                    (icon === "loading" ? (
                        <Spinner className="loading_spinner_small ms-2" animation="border" />
                    ) : (
                        <Icon
                            className={classIcon}
                            classPlaceholder={styles.icon_placeholder}
                            name={icon}
                            alt="ícone ilustrativo para cada ação como menu, botão de criar, editar entre outros"
                        />
                    ))}
            </Button>
        );
    }
);

export default ButtonIcon;
