import api from "./api";

const fileService = {
    store: (file) =>
        api.post("/file/upload", file, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }),
};

export default fileService;
