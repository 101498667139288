import { AxiosRequestConfig } from "axios";
import api from "services/api";

interface Subsidy {
    id?: number;
    value?: string;
    value_approved?: string;
    client_id?: number;
    fiscal_document_url?: string;
    document_url?: string;
    status?: "pending" | "approved" | "disapproved";
    telenet_transaction_id?: number;
    approved_at?: string;
    disapproved_at?: string;
    disapproved_reason?: string;
    updated_by_admin_id?: number;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
    client?: number;
    admin?: number;
    company_id?: number;
}

const subsidyService = {
    update: (id: number | string, data: Subsidy) => api.put(`/subsidy/${id}`, data),
    delete: (id: number) => api.delete(`/subsidy/${id}`),
    export: (config?: AxiosRequestConfig) => api.get<{ subsidies_excel: string }>("/subsidy-export", config),
};

export default subsidyService;
