/* ----------- COMPONENTS ----------- */
import { forwardRef, Ref } from "react";

/* ----------- CONTRACTS ----------- */
import { ModalCustomProps } from "contracts/Admin";

/* ----------- COMPONENTS ----------- */
import { CloseButton, Col, Modal as ModalBs } from "react-bootstrap";

/* ----------- COMPONENTS ----------- */
import styles from "components/admin/Modal/styles.module.scss";

const Modal = forwardRef(
    (
        {
            title,
            children,
            buttons,
            onClose,
            modalClassName = "",
            modalBodyClassName = "",
            buttonCloseClassName = "",
            buttonVariant = "dark",
            keyboard = true,
            ...props
        }: ModalCustomProps,
        ref: Ref<HTMLDivElement>
    ) => {
        const button_border_class = buttonVariant === 'light'
            ? styles.custom_border_light
            : styles.custom_border_gray

        return (
            <ModalBs
                className={`${styles.modal} ${modalClassName}`}
                backdrop="static"
                keyboard={keyboard}
                centered
                {...props}
            >
                <ModalBs.Body className={`${styles.modal_body} ${modalBodyClassName}`} ref={ref}>
                    <Col className={styles.modal_header}>
                        <h3 className={styles.modal_title}>{title}</h3>

                        {onClose && (
                            <CloseButton
                                variant={buttonVariant}
                                className={`${styles.modal_close_button} ${button_border_class} ${buttonCloseClassName}`}
                                onClick={onClose}
                            />
                        )}
                    </Col>

                    <Col className={styles.modal_content}>{children}</Col>

                    <Col className={styles.modal_buttons}>{buttons}</Col>
                </ModalBs.Body>
            </ModalBs>
        );
    }
);

export default Modal;
