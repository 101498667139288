/* ----------- RESOURCES ----------- */
import { ChangeEvent, useMemo, useRef, useState } from "react";
import { useFetch } from "hooks/useFetch";

/* ----------- LIBS ----------- */
import moment from "moment";

/* ----------- UTILS ----------- */
import { useForm } from "hooks/useForm";
import { useDebounce } from "hooks/useDebounce";

/* ----------- COMPONENTS ----------- */
import { Form, Col, Image, Carousel } from "react-bootstrap";
import Actions from "components/admin/Actions";
import DataTable from "components/admin/DataTable";
import Label from "components/admin/Label";
import Input from "components/admin/Input";
import InputDate from "components/admin/InputDate";
import Select from "components/admin/Select";
import loadingDataTable from "components/admin/LoadingDataTable";
import Badge from "components/admin/Badge";
import Modal from "components/admin/Modal";
import Reloading from "components/Reloading";

/* --------- TYPES ---------- */
import { FormFilters, ModalPhotoProps, Subsidy, ZoomProps } from "./types";

/* ---------- STYLES ---------- */
import styles from "pages/admin/subsidies/styles.module.scss";

let idZoomMoveTimeout: any = null;

const initStateModalPhoto: ModalPhotoProps = {
    show: false,
    photo: {
        name: "",
        urls: [],
    },
};

const initStateZoom: ZoomProps = { scale: 1, x: 0, y: 0 };

const status = [
    { key: "pending", item: "Pendente" },
    { key: "approved", item: "Aprovado" },
    { key: "disapproved", item: "Reprovado" },
];

const status_color = {
    pending: "warning",
    approved: "success",
    disapproved: "danger",
};

const breadcrumbs = [
    {
        label: "Inicio",
        path: "/admin/home",
    },
    {
        label: "Gestão de Subsídios",
        path: "/admin/subsidios",
    },
];

const fields = [
    "Usuário",
    "CPF",
    "Empresa",
    "Cartão",
    "Telefone",
    "Data",
    "Fotos",
    "Valor",
    "Valor subsidiado",
    "Status",
];

const form = {
    page: 1,
    perPage: 10,
    status: null,
    search: "",
    created_at: {
        start: null,
        end: null,
    },
};

function formatCPF(cpf: string) {
    return cpf.replaceAll(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
}

function formatCellphone(cellphone: string | number) {
    cellphone ??= "";
    cellphone = typeof cellphone == "number" ? String(cellphone) : cellphone;

    return cellphone.replaceAll(/(\d{2})(\d{5})(\d{4})/g, "($1) $2-$3");
}

function formatValue(value: string) {
    if (!value) return "";

    return parseFloat(value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
    });
}

function calcPositionEvent(event: any) {
    const rect = event.target.getBoundingClientRect() as DOMRect;
    const x = Math.floor(rect.width / 2 - (event.clientX - rect.left));
    const y = Math.floor(rect.height / 2 - (event.clientY - rect.top));

    return { x, y };
}

function formatFilterDate(date: { start: Date | null; end: Date | null }) {
    if (!date.start && !date.end) return null;

    if (date.start && date.end)
        return `${moment(date.start).format("YYYY-MM-DD")},${moment(date.end).format("YYYY-MM-DD")}`;

    return moment(date.start ?? date.end).format("YYYY-MM-DD");
}

function Status({ subsidy_status }: { subsidy_status: { value: string; label: string } }) {
    return (
        <Badge className={styles.badge_status} color={status_color[subsidy_status.value] ?? "primary"} pill>
            {subsidy_status.label}
        </Badge>
    );
}

export default function Subsidies() {
    const [formFilters, updateFormFilters] = useForm<FormFilters>(form);

    const [modalPhoto, setModalPhoto] = useState(initStateModalPhoto);
    const [isZoom, setIsZoom] = useState(false);
    const [zoomPosition, setZoomPosition] = useState(initStateZoom);

    const imgRef = useRef<HTMLImageElement | null>(null);
    const debounce = useDebounce();
    const debounceClearFilter = useDebounce();

    const { data, error, isValidating, mutate } = useFetch(
        `user-company/subsidies?page=${formFilters.page}&perPage=${formFilters.perPage}`,
        {
            params: {
                include: "company,client,client.cards",
                "filter[status]": formFilters.status,
                "filter[search]": formFilters.search,
                "filter[created_at]": formatFilterDate(formFilters.created_at),
            },
        }
    );

    const isLoadingSubsidy = !data && !error;
    const isReloadingSubsidy = data && isValidating;
    const isEmptySubsidy = !data || !data?.data || error;

    const class_zoom = isZoom ? styles.zoom_out : styles.zoom_in;

    const filters = [
        <Form.Group className="col-11 col-lg-4 col-md-6 p-0 mx-auto mx-md-0" key="filter-search">
            <Label text="Pesquisar" />

            <Input
                type="search"
                iconRight="search"
                placeholder="Buscar por cliente, empresa, cartão ou CPF"
                aria-label="Digite o CPF"
                value={formFilters.search}
                onChange={handleFilterSearch}
            />
        </Form.Group>,

        <Form.Group className="row col-11 col-lg-4 col-md-6 p-0 mx-auto mx-md-0" key="filter-date">
            <Label text="Data de criação" />

            <Col className="col-6 p-0">
                <InputDate
                    className={styles.input_datetime_left}
                    placeholder="Data inicial"
                    selectsStart
                    iconLeft="calendar"
                    selected={formFilters.created_at.start}
                    dateFormat="dd/MM/yyyy"
                    startDate={formFilters.created_at.start}
                    endDate={formFilters.created_at.end}
                    onChange={(date) => handleFilterCreatedAt(date, "start")}
                />
            </Col>

            <Col className="col-6 p-0">
                <InputDate
                    className={styles.input_datetime_right}
                    placeholder="Data final"
                    selectsEnd
                    style={{ borderLeft: "none !important" }}
                    iconLeft="calendar"
                    selected={formFilters.created_at.end}
                    dateFormat="dd/MM/yyyy"
                    startDate={formFilters.created_at.start}
                    endDate={formFilters.created_at.end}
                    minDate={formFilters.created_at.start}
                    onChange={(date) => handleFilterCreatedAt(date, "end")}
                />
            </Col>
        </Form.Group>,

        <Form.Group className="col-11 col-lg-3 col-md-5 p-0 mx-auto mx-md-0" key="filter-status">
            <Label text="Status" />

            <Select
                placeholder="Selecione o status"
                items={status}
                value={formFilters.status}
                onChange={(key) => updateFormFilters("status", key)}
            />
        </Form.Group>,
    ];

    /* --- FILTERS --- */

    function handleFilterSearch(event: ChangeEvent<HTMLInputElement>) {
        const search = event.target.value;
        updateFormFilters("search", search);

        debounce(() => {
            updateFormFilters("page", 1);
            mutate();
        }, 500)();
    }

    function handleFilterCreatedAt(date: Date | null, field: string) {
        updateFormFilters("created_at", {
            ...formFilters.created_at,
            [field]: date,
        });
    }

    function handleClearFilters() {
        updateFormFilters(
            ["search", "status", "page", "created_at"],
            ["", null, 1, { start: null, end: null }]
        );

        debounceClearFilter(() => mutate(), 20)();
    }

    /*  --- MODAL PHOTO --- */
    function handleHiddenDetailsPhoto() {
        setModalPhoto({ show: false, photo: { name: null, urls: [] } });

        setIsZoom(false);
        setZoomPosition({ scale: 1, x: 0, y: 0 });
    }

    function handleZoomableImage(event: any) {
        const { x, y } = calcPositionEvent(event);

        setZoomPosition(isZoom ? { scale: 1, x: 0, y: 0 } : { scale: 1.5, x, y });

        setIsZoom((z) => !z);
    }

    function handleMousemove(event: any) {
        if (!isZoom) return;

        idZoomMoveTimeout = setTimeout(() => {
            idZoomMoveTimeout && clearTimeout(idZoomMoveTimeout);

            if (event.pageY < 100 || event.pageY > 850) return;

            const y = Math.floor(event.target.clientHeight - event.pageY + 10);
            setZoomPosition((e) => ({ ...e, y }));
        }, 15);
    }

    const listSubsidies = useMemo(() => {
        function handleShowDetailsPhoto(photo: { name: string; urls: string[] }) {
            setModalPhoto({ show: true, photo });
        }

        return data?.data?.map((subsidy: Subsidy) => (
            <tr key={`subsidy-${subsidy.id}`} data-id={subsidy.id}>
                <td>{subsidy.client.name}</td>
                <td>{formatCPF(subsidy.client.cpf)}</td>
                <td>{subsidy.company?.name}</td>
                <td>
                    {subsidy.telenet_card_label && (
                        <Col className="d-flex flex-wrap justify-content-center">
                            <Badge color="primary" key={`card-badge-${subsidy.telenet_card_id}`} pill>
                                {subsidy.telenet_card_label}
                            </Badge>
                        </Col>
                    )}
                </td>
                <td>{formatCellphone(subsidy.client.cellphone)}</td>
                <td>{moment(subsidy.created_at).format("DD/MM/YYYY")}</td>
                <td>
                    <Col className="d-flex flex-column flex-wrap justify-content-center">
                        <Col
                            className="col-auto"
                            onClick={() =>
                                handleShowDetailsPhoto({
                                    name: "Documento Fiscal",
                                    urls: subsidy.fiscal_document_url,
                                })
                            }
                        >
                            <p className={styles.document_url}>Ver Documento Fiscal</p>
                        </Col>

                        <Col
                            className="col-auto"
                            onClick={() =>
                                handleShowDetailsPhoto({
                                    name: "Documento",
                                    urls: subsidy.document_url,
                                })
                            }
                        >
                            <p className={styles.document_url}>Ver Documento</p>
                        </Col>
                    </Col>
                </td>
                <td> {formatValue(subsidy.value)}</td>
                <td> {formatValue(subsidy.value_approved ?? "")}</td>
                <td>
                    <Status subsidy_status={subsidy.status} />
                </td>
            </tr>
        ));
    }, [data]);

    return (
        <>
            <Actions
                title="Gestão de Subsídios"
                breadcrumb={breadcrumbs}
            />

            <DataTable
                filters={filters}
                fields={fields}
                currentPage={formFilters.page}
                perPage={formFilters.perPage}
                total={data?.total}
                onSearch={() => mutate()}
                onPaginate={(p) => updateFormFilters("page", p)}
                onPerPage={(p) => updateFormFilters("perPage", p)}
                onClearFilters={handleClearFilters}
                data={
                    isLoadingSubsidy
                        ? loadingDataTable(fields.length, formFilters.perPage)
                        : isEmptySubsidy
                            ? []
                            : listSubsidies
                }
            />

            {/* MODAL PHOTO */}
            <Modal
                ref={imgRef}
                show={modalPhoto.photo.urls.length > 0}
                contentClassName={styles.modal_content_photo}
                modalBodyClassName={styles.modal_photo_body}
                title={modalPhoto.photo?.name}
                buttonVariant="white"
                onClose={handleHiddenDetailsPhoto}
                fullscreen
                keyboard
            >
                <Carousel className="col-12" style={{ height: "95%" }} interval={null} touch>
                    {modalPhoto.photo.urls.map((url) => (
                        <Carousel.Item key={url} className="col-12" style={{ height: "95vh" }}>
                            <Col className="col-12 h-100 d-flex justify-content-center align-items-center">
                                <Col className={`${styles.container_modal_photo} ${class_zoom} mx-auto`}>
                                    <Image
                                        style={{
                                            transform: `scale(${zoomPosition.scale}) translate(${zoomPosition.x}px, ${zoomPosition.y}px)`,
                                        }}
                                        src={url}
                                        alt={modalPhoto.photo?.name}
                                        title="Click  para ampliar a imagem"
                                        fluid
                                        onClick={handleZoomableImage}
                                        onMouseMove={handleMousemove}
                                    />
                                </Col>
                            </Col>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Modal>
            {/* ---------------- */}

            {/* RELOADING SPINNER */}
            {isReloadingSubsidy && <Reloading />}
            {/* ----------------- */}
        </>
    );
}
