/* ----------- RESOURCES ----------- */
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { useMultipleSelect } from "hooks/useMultipleSelect";
import { useDebounce } from "hooks/useDebounce";
import api from "services/api";
import { formatErrors400 } from "utils/formatErrors";
import benefitService from "services/benefitService";
import fileService from "services/fileService";
import cardService from "services/cardService";

/* -----------  ICONS ----------- */
import ChevronBottomIcon from "assets/icons/chevron-right-bottom.svg";

/* ----------- COMPONENTS ----------- */
import Actions from "components/admin/Actions";
import Label from "components/admin/Label";
import Input from "components/admin/Input";
import InputDate from "components/admin/InputDate";
import UploadFile from "components/admin/UploadFile";
import Select from "components/admin/Select";
import MultipleSelect from "components/admin/MultipleSelect";
import Textarea from "components/admin/Textarea";
import Badge from "components/admin/Badge";
import ButtonIcon from "components/ButtonIcon";
import Modal from "components/admin/Modal";
import { Col, Form, Card as CardBC, FormCheck } from "react-bootstrap";

/* ----------- COMPONENTS ----------- */
import styles from "pages/admin/benefitClub/new/styles.module.scss";

interface FormState {
    type: string;
    title: string;
    details: string;
    start_date: Date | null;
    end_date: Date | null;
    picture_url: any;
}

type KeyFormState = "type" | "title" | "details" | "start_date" | "end_date" | "picture_url";

interface Establishments {
    id: number;
    name: string;
    cnpj: string;
}

interface Card {
    id: number;
    name: string;
}

const breadcrumbs = [
    {
        label: "Início",
        path: "/admin/home",
    },
    {
        label: "Clube de benefícios",
        path: "/admin/clube-beneficios",
    },
    {
        label: "Novo Clube de Benefícios",
        path: "/admin/clube-beneficios/novo-beneficio",
    },
];

const segments = [];

const typeTitle = [
    { key: "percent", item: "Porcentagem" },
    { key: "description", item: "Descrição" },
];

function formatCard(cards: Card[]) {
    return cards?.map((card) => ({
        key: card.id,
        checked: false,
        item: card.name,
    }));
}

function formatEstablishment(establishments: Establishments[]) {
    return establishments?.map(({ id, name, cnpj }) => ({
        key: id,
        checked: false,
        item: `${name.substring(0, 30)} (${cnpj})`,
    }));
}

const getDateEnd = () => moment(new Date()).add(2, 'days').format("DD/MM/YYYY");
const getDate = () => moment(new Date()).format("DD/MM/YYYY");

const hasItemSelected = ({ checked, key }) => checked && key !== "all";

const getKey = ({ key }: { key: string | number }) => key;

const validator = yup.object({
    type: yup.string().required("O Tipo do benefício é obrigatório"),
    title: yup.string().required("O título do benefício é obrigatório"),
    details: yup.string().required("Os Detalhes do benefício é obrigatório"),
    picture_url: yup.string().required("A imagem do benefício é obrigatória"),
    start_date: yup
        .string()
        .test({
            name: "start_date",
            test: (value) => /(\d){4}-(\d){2}-(\d){2}/.test(value),
            message: "A data é inválida",
        })
        .required("A data de inicio da validade é obrigatório"),
    end_date: yup
        .string()
        .test({
            name: "end_data",
            test: (value) => /(\d){4}-(\d){2}-(\d){2}/.test(value),
            message: "A data é inválida",
        })
        .required("A data de termino da validade é obrigatório"),
    establishments: yup
        .array()
        .of(yup.number())
        .required("Selecione pelo menos 1 estabelecimento")
        .min(1, "Selecione pelo menos 1 estabelecimento"),
    cards: yup
        .array()
        .of(yup.number())
        .required("O card é obrigatório")
        .min(1, "Selecione pelo menos 1 cartão"),
});
export default function NewBenefitClub() {
    const navigation = useNavigate();
    const debounce = useDebounce();

    const [isSave, setIsSave] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalEstablishments, setShowModalEstablishments] = useState(false);
    const [formState, setFormState] = useState<FormState>({
        type: "",
        title: "",
        details: "",
        start_date: null,
        end_date: null,
        picture_url: null,
    });

    const [cards, selectCard, setCards] = useMultipleSelect([], true);
    const [establishments, selectEstablishment, setEstablishments] = useMultipleSelect([], true);
    const [searchEstablishment, selectSearchEstablishment, setSearchEstablishment] = useMultipleSelect(
        [],
        true
    );

    const {
        handleSubmit,
        setError,
        formState: { isValid, errors },
        setValue,
    } = useForm({
        resolver: yupResolver(validator),
        mode: "onChange",
    });

    const establishmentsSelected = establishments.filter(hasItemSelected);

    const cardsSelected = cards.filter(hasItemSelected);

    function closeModalEstablishment() {
        setShowModalEstablishments(false);
    }

    function closeModalAdvancedSearch() {
        setShowModal(false);
    }

    /**
     * Essa função atribui valores sem nenhuma regra ou lógica.
     * Para caso mais complexo, que precise de alguma regra/lógica crie uma outra função.
     *
     * @param {string} key A chave que está em formState
     * @param {any} value O valor a ser atribuído na propriedade
     */
    function handleFormState(key: KeyFormState, value: any) {
        if (!(key in formState)) return;

        setFormState((state) => ({
            ...state,
            [key]: value,
        }));

        setValue(key, value, { shouldValidate: true });
    }

    function handleType(type) {
        setFormState({
            ...formState,
            title: "",
            type,
        });

        setValue("title", "", { shouldValidate: true });
        setValue("type", type, { shouldValidate: true });
    }

    function handleFile(files) {
        if (files) {
            handleFormState("picture_url", files[0]);
            setValue("picture_url", files[0]?.name ?? "", { shouldValidate: true });
        }
    }

    function handleDate(key: "start_date" | "end_date", date) {
        handleFormState(key, date);
        setValue(key, date ? moment(date).format("YYYY-MM-DD") : "", { shouldValidate: true });
    }

    function handleChangeTitle(event: ChangeEvent<any>) {
        const integers = /[0-9]{1,3}/;

        if (
            formState.type === "percent" &&
            (event.target.value?.length > 3 || !integers.test(event.target.value)) &&
            event.target.value !== ""
        )
            return;

        if (formState.type === "description" && event.target.value?.length > 40) return;

        const title = formState.type === "percent" ? event.target.value : event.target.value;
        handleFormState("title", title);
        setValue("title", title, { shouldValidate: true });
    }

    function handleChangeDetails(event: ChangeEvent<any>) {
        if (event.target.value.length > 255) return;

        handleFormState("details", event.target.value);
        setValue("details", event.target.value, { shouldValidate: true });
    }

    function handleShowModalEstablishments() {
        setShowModalEstablishments(true);
        setSearchEstablishment([{ key: "all", checked: false, item: " Selecionar todos " }]);
    }

    function handleSelectEstablishment(key: string | number) {
        selectEstablishment(key);
        selectSearchEstablishment(key);
    }

    function handleSearchEstablishment(event) {
        if (event.target.value?.length < 2) return;

        api.get("/establishment", {
            params: {
                "filter[establishment]": event.target.value?.trim()?.toLowerCase(),
            },
        })
            .then(({ data: es }) => {
                const establishmentsSelected = establishments.filter(
                    ({ key, checked }) => key === "all" || checked
                );

                setEstablishments([...establishmentsSelected, ...formatEstablishment(es ?? [])]);
                setSearchEstablishment([
                    { key: "all", checked: false, item: " Selecionar todos " },
                    ...formatEstablishment(es ?? []),
                ]);
            })
            .catch(() => {
                setEstablishments([]);
            });
    }

    function handleStoreBenefit(form) {
        setIsSave(true);

        const formData = new FormData();

        formData.append("file", formState.picture_url);

        fileService
            .store(formData)
            .then(async ({ data }) => {
                try {
                    form.picture_url = data?.file;
                    form.title = formState.type === "percent" ? `${form.title}% de desconto` : form.title;

                    await benefitService.store(form);

                    toast.success("Benefício criado com sucesso!", { autoClose: 3000 });

                    setIsSave(false);
                    setTimeout(() => navigation("/admin/clube-beneficios"), 1000);
                } catch (err) {
                    setIsSave(false);
                    if (err.status === 400) {
                        formatErrors400(err.data?.errors ?? [], (e) => {
                            setError(e.name, {
                                type: "manual",
                                message: e.message,
                            });
                        });
                        return toast.error("Verifique os dados e tente novamente", { autoClose: 3000 });
                    }

                    toast.error("Ocorreu um erro ao criar o clube de benefícios", { autoClose: 3000 });
                }
            })
            .catch((err) => {
                setIsSave(false);
                if (err.status === 400) {
                    setError("file", {
                        type: "manual",
                        message: err.data.errors["file"][0],
                    });

                    return toast.error("Por favor, verifique a imagem escolhida", { autoClose: 3000 });
                }

                toast.error("Ocorreu um erro ao fazer upload do arquivo", { autoClose: 3000 });
            });
    }

    function optionEstablishments({ key, checked, item }) {
        return {
            key,
            checked,
            item: (
                <Col>
                    <p className={styles.option_title}>{item}</p>
                    <p className={styles.option_info}>{item}</p>
                </Col>
            ),
        };
    }

    useEffect(() => {
        cardService
            .list()
            .then((response) => {
                setCards([...cards, ...formatCard(response.data ?? [])]);
            })
            .catch(() => {
                toast.error("Ocorreu um erro ao buscar cartões", { autoClose: 2000 });
            });
    }, []);

    useEffect(
        () =>
            setValue(
                "establishments",
                establishmentsSelected.map(getKey),
                { shouldValidate: true }
            ),
        [establishments]
    );

    useEffect(
        () =>
            setValue(
                "cards",
                cardsSelected.map(getKey),
                { shouldValidate: true }
            ),
        [cards]
    );

    return (
        <>
            <Actions
                title="Novo benefício"
                breadcrumb={breadcrumbs}
                actionButtons={
                    <>
                        <Col className="col-auto col-lg-3  d-grid">
                            <ButtonIcon
                                icon="removeorcancel-action"
                                className={styles.button_cancel}
                                onClick={() => navigation("/admin/clube-beneficios")}
                            >
                                Cancelar
                            </ButtonIcon>
                        </Col>

                        <Col className="col-auto col-lg-3  d-grid">
                            <ButtonIcon
                                icon={isSave ? "loading" : "save-action"}
                                className={styles.button_save}
                                disabled={!isValid || isSave}
                                onClick={handleSubmit(handleStoreBenefit)}
                            >
                                {isSave ? "Salvando" : "Salvar"}
                            </ButtonIcon>
                        </Col>
                    </>
                }
            />

            <Form
                onSubmit={(e) => e.preventDefault()}
                className="mx-auto px-3 d-flex flex-column flex-lg-row gap-lg-3 justify-xl-content-between"
            >
                <Col className={styles.box_form_card}>
                    <CardBC className={`${styles.form_card} mb-3`}>
                        <CardBC.Body>
                            <Form.Group className={formState.type.trim() && "mb-4"}>
                                <Label text="Título do benefício" />

                                <Select
                                    placeholder="Selecione o tipo de benefício"
                                    aria-label="Selecione o tipo de benefício"
                                    items={typeTitle}
                                    onChange={(type) => handleType(type)}
                                />

                                {errors.type && (
                                    <Form.Text className="text_error">{errors.type?.message}</Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group>
                                {(formState.type === "percent" && (
                                    <>
                                        <Col className="d-flex ">
                                            <Col className="col-2">
                                                <Label text="Porcentagem" />

                                                <Input value={formState.title} onChange={handleChangeTitle} />
                                            </Col>

                                            <Col className="col-8 d-flex align-items-end">
                                                <p className={`${styles.text_info_title} ps-3`}>
                                                    % de desconto
                                                </p>
                                            </Col>
                                        </Col>

                                        {errors.type && (
                                            <Form.Text className="text_error">{errors.type?.message}</Form.Text>
                                        )}
                                    </>
                                )) ||
                                    (formState.type === "description" && (
                                        <>
                                            <Label text="Descrição" />

                                            <Textarea
                                                value={formState.title}
                                                placeholder="Digite aqui a descrição do benefício"
                                                height={60}
                                                onChange={handleChangeTitle}
                                            />

                                            <p className={`${styles.text_info_title} mt-2 mb-0 float-end`}>
                                                {`Caracteres ${formState.title.length}/40`}
                                            </p>
                                        </>
                                    ))}

                                {formState.type && errors.title && (
                                    <Form.Text className="text_error">{errors.title?.message}</Form.Text>
                                )}
                            </Form.Group>
                        </CardBC.Body>
                    </CardBC>

                    <CardBC className={`${styles.form_card} mb-3`}>
                        <CardBC.Body>
                            <Form.Group>
                                <Label text="Detalhes" />

                                <p className={styles.text_info}>Dê mais detalhes sobre esse benefício</p>

                                <Textarea
                                    height={120}
                                    placeholder="Digite aqui sobre os detalhes do benefício"
                                    value={formState.details}
                                    onChange={handleChangeDetails}
                                />

                                {errors.details && (
                                    <Form.Text className="text_error">{errors.details?.message}</Form.Text>
                                )}
                            </Form.Group>

                            <p className={`${styles.text_info_title} mt-2 mb-0 float-end`}>
                                {`Caracteres ${formState.details.length}/255`}
                            </p>
                        </CardBC.Body>
                    </CardBC>

                    <CardBC className={`${styles.form_card} mb-3`}>
                        <CardBC.Body>
                            <Form.Group>
                                <Col className="d-flex justify-content-between">
                                    <Form.Group className="col-4">
                                        <Label text="Inicio da validade" />

                                        <InputDate
                                            className={styles.input_datetime}
                                            placeholder={getDate()}
                                            iconLeft={"calendar"}
                                            selected={formState.start_date}
                                            onChange={(date) => handleDate("start_date", date)}
                                            dateFormat="dd/MM/yyyy"
                                            selectsStart
                                            startDate={formState.start_date}
                                            endDate={formState.end_date}
                                            minDate={new Date()}
                                        />

                                        {errors.start_date && (
                                            <Form.Text className="text_error">
                                                {errors.start_date?.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>

                                    <Form.Group className="col-4">
                                        <Label text="Fim da validade" />

                                        <InputDate
                                            className={styles.input_datetime}
                                            placeholder={getDateEnd()}
                                            iconLeft={"calendar"}
                                            selected={formState.end_date}
                                            onChange={(date) => handleDate("end_date", date)}
                                            dateFormat="dd/MM/yyyy"
                                            selectsEnd
                                            startDate={formState.start_date}
                                            endDate={formState.end_date}
                                            minDate={formState.start_date}
                                        />

                                        {errors.end_date && (
                                            <Form.Text className="text_error">
                                                {errors.end_date?.message}
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Form.Group>
                        </CardBC.Body>
                    </CardBC>
                </Col>

                <Col className={styles.box_form_card}>
                    <CardBC className={`${styles.form_card} mb-3`}>
                        <CardBC.Body>
                            {/* <Col>
                                <ButtonIcon className="float-end" onClick={() => setShowModal(true)}>
                                    Busca Avançada
                                </ButtonIcon>
                            </Col> */}

                            <Form.Group>
                                <Label text="Estabelecimentos" />

                                <p className={styles.text_info}>
                                    Selecione o(s) estabelecimento(s) onde esse benefício estará disponível
                                </p>

                                <div
                                    className={styles.div_multiple_select}
                                    onClick={handleShowModalEstablishments}
                                >
                                    <div className={styles.placeholder}>Buscar por estabelecimento(s)</div>

                                    <img
                                        className={styles.chevron_icon}
                                        src={ChevronBottomIcon}
                                        alt="Ícone de seleção"
                                    />
                                </div>

                                {errors.establishments && (
                                    <Form.Text className="text_error">
                                        {errors.establishments?.message}
                                    </Form.Text>
                                )}
                            </Form.Group>

                            <Col className="mt-4">
                                <Label text="Estabelecimento(s) selecionado(s):" />

                                <Col className="d-flex gap-2 flex-wrap">
                                    {establishmentsSelected.length <= 0 ? (
                                        <p className={styles.text_info}>
                                            Nenhum estabelecimento selecionado. Selecione para serem listados
                                            aqui
                                        </p>
                                    ) : (
                                        establishmentsSelected.slice(0, 10).map(({ key, item }) => (
                                            <Badge
                                                key={`badge-establishment-${key}`}
                                                onRemoveBadge={() => selectEstablishment(key)}
                                                iconClosed
                                                pill
                                            >
                                                {item}
                                            </Badge>
                                        ))
                                    )}

                                    {establishmentsSelected.length > 10 && (
                                        <Badge key={`badge-establishment-more`} pill>
                                            ...
                                        </Badge>
                                    )}
                                </Col>
                            </Col>
                        </CardBC.Body>
                    </CardBC>

                    <CardBC className={`${styles.form_card} mb-3`}>
                        <CardBC.Body>
                            <Form.Group>
                                <Label text="Cartões" />

                                <p className={styles.text_info}>
                                    Selecione o(s) cartão(ões) para esse benefício
                                </p>

                                <MultipleSelect
                                    placeholder="Procure aqui um ou mais cartão(ões)"
                                    aria-label="Procure aqui um ou mais cartão(ões)"
                                    items={cards}
                                    onChange={selectCard}
                                />

                                {errors.cards && (
                                    <Form.Text className="text_error">{errors.cards?.message}</Form.Text>
                                )}
                            </Form.Group>

                            <Col className="mt-4">
                                <Label text="Cartão(ões) selecionado(s):" />

                                <Col className="d-flex gap-2 flex-wrap">
                                    {cardsSelected.length <= 0 ? (
                                        <p className={styles.text_info}>
                                            Nenhum cartão selecionado. Selecione cartões para serem listados
                                            aqui
                                        </p>
                                    ) : (
                                        cardsSelected.slice(0, 10).map(({ key, item: name }) => (
                                            <Badge
                                                key={`badge-cards-${key}`}
                                                onRemoveBadge={() => selectCard(key)}
                                                iconClosed
                                                pill
                                            >
                                                {name}
                                            </Badge>
                                        ))
                                    )}

                                    {cardsSelected.length > 10 && (
                                        <Badge key={`badge-establishment-more`} pill>
                                            ...
                                        </Badge>
                                    )}
                                </Col>
                            </Col>
                        </CardBC.Body>
                    </CardBC>

                    <CardBC className={`${styles.form_card} mb-3`}>
                        <CardBC.Body>
                            <UploadFile
                                inputFileState={formState.picture_url}
                                onChange={handleFile}
                                onRemoveFile={() => handleFormState("picture_url", "")}
                            />

                            {errors.picture_url && (
                                <Form.Text className="text_error">{errors.picture_url?.message}</Form.Text>
                            )}
                        </CardBC.Body>
                    </CardBC>
                </Col>
            </Form>

            {/* MODAL MULTIPLE SELECT */}
            <Modal
                onSubmit={(e) => e.preventDefault()}
                show={showModalEstablishments}
                title="Selecione um ou mais estabelecimentos"
                size="lg"
                onClose={closeModalEstablishment}
            >
                <Form className="mb-3" onSubmit={(e) => e.preventDefault()}>
                    <Form.Group className="mb-3">
                        <Label text="Pesquisar" />

                        <Input
                            iconRight="search"
                            placeholder="Pesquise aqui o(s) estabelecimento(s)"
                            onKeyUp={debounce(handleSearchEstablishment, 500)}
                        />
                    </Form.Group>

                    <Col className={`col-12 ${styles.box_show_search} rounded`}>
                        {searchEstablishment.map(({ key, checked, item }) => (
                            <Col
                                key={`option-establishment-${key}`}
                                className="d-flex align-items-start py-3 px-4 cursor-pointer"
                                onClick={() => handleSelectEstablishment(key)}
                            >
                                <FormCheck checked={checked} disabled />
                                {item}
                            </Col>
                        ))}
                    </Col>
                </Form>
            </Modal>
            {/* ----------------------- */}

            {/* MODAL DE BUSCA AVANÇADA */}
            <Modal
                onSubmit={(e) => e.preventDefault()}
                show={showModal}
                title="Busca Avançada"
                size="lg"
                onClose={closeModalAdvancedSearch}
                buttons={
                    <>
                        <Col className="col-auto col-xl-2  d-grid">
                            <ButtonIcon icon="removeorcancel-action" className={styles.button_cancel}>
                                Cancelar
                            </ButtonIcon>
                        </Col>

                        <Col className="col-auto col-xl-2  d-grid">
                            <ButtonIcon icon="save-action" className={styles.button_save}>
                                Salvar
                            </ButtonIcon>
                        </Col>
                    </>
                }
            >
                <Form className="mb-3">
                    <Form.Group className="mb-3">
                        <Col className="d-flex justify-content-between">
                            <Col className="col-5">
                                <Label text="Localização" />
                                <Select placeholder="Selecione uma localização" items={[]} />
                            </Col>

                            <Col className="col-5">
                                <Label text="Segmento" />
                                <Select placeholder="Selecione um segmento" items={segments} />
                            </Col>
                        </Col>
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Label text="Pesquisar" />
                        <Input iconRight="search" placeholder="Pesquise pelos detalhes do estabelecimento" />
                    </Form.Group>

                    <Col className={`col-12 ${styles.box_show_search}`}>
                        {establishments.map(optionEstablishments).map(({ key, checked, item }) => (
                            <Col
                                className="d-flex align-items-start pb-4 pt-3 px-4 mb-1"
                                onClick={() => selectEstablishment(key)}
                            >
                                <FormCheck checked={checked} disabled />
                                {item}
                            </Col>
                        ))}
                    </Col>
                </Form>
            </Modal>
            {/* ----------------------- */}
        </>
    );
}
