import { AxiosRequestConfig, AxiosResponse } from "axios";
import api from "./api";

type User = {
    id: number;
    name: string;
    email: string;
    email_verified_at?: string;
    type: "admin" | "company";
};

interface SignIn {
    token: string;
    user: User;
    message?: string;
}

interface SignOut {
    success: boolean;
    message?: string;
}

interface UsersData {
    name: string;
    email: string;
    email_verified_at?: string;
    password: string;
    current_password: string;
    password_changed_at?: string;
    deleted_at?: string;
    created_at?: string;
    updated_at?: string;
}

const prefix = "admin";

const adminService = {
    signIn: (email: string, password: string): Promise<AxiosResponse<SignIn>> =>
        api.post<SignIn>("/auth", {
            email,
            password,
        }),

    signOut: (): Promise<AxiosResponse<SignOut>> => api.get<SignOut>("/logout"),

    create: (data: UsersData, config?: AxiosRequestConfig) => api.post(`/${prefix}`, data, config),
};

export default adminService;
