/* ----------- COMPONENTS ----------- */
import FormLabel from 'react-bootstrap/FormLabel'

/* ----------- CONTRACTS ----------- */
import { LabelProps } from 'contracts/Admin'

/* ----------- STYLES ----------- */
import styles from 'components/admin/Label/styles.module.scss'

export default function Label({ text, children, className = "", ...props }: LabelProps) {
    return (
        <FormLabel className={`${styles.form_label} ${className}`} aria-label={text} {...props}>
            {children || text || ""}
        </FormLabel>
    );
}