/* ----------- RESOURCES ----------- */
import { forwardRef, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react'
import ChevronBottomIcon from 'assets/icons/chevron-right-bottom.svg'

/* ----------- CONTRACTS ----------- */
import { SelectProps } from 'contracts/Admin'

/* ----------- STYLES ----------- */
import styles from 'components/admin/Select/styles.module.scss'

interface DivRef {
    current: HTMLDivElement | null
}

const Select = forwardRef((
    {
        items,
        onChange,
        defaultValue,
        value,
        className = '',
        placeholder = '',
        ...props
    }: SelectProps,
    divRef?: DivRef
) => {
    const [show, setShow] = useState(false)
    const [selected, setSelected] = useState<string | ReactNode>(
        (defaultValue && items.find(x => x.key === defaultValue)?.item) ||
        placeholder
    )
    const divCustomRef = useRef<HTMLDivElement | null>(null)
    const dropdownRef = useRef<HTMLUListElement | null>(null)

    divRef ??= divCustomRef

    useEffect(() => {
        function pageClick(event: globalThis.MouseEvent) {
            if (
                show &&
                !Object.is(event.target, divRef.current) &&
                !Object.is(event.target, dropdownRef.current) &&
                !divRef.current?.contains(event.target as Node)
            )
                setShow(false)

        }

        let doc = document.querySelector('body')
        doc && doc.addEventListener('click', pageClick)

        return () => {
            doc && doc.removeEventListener('click', pageClick)
        }

    }, [show, divRef])


    /**
     * Mostra e esconde os items do select quando clicado no próprio select
     * @param event MouseEvent
     */
    function showDropdown(event: MouseEvent<HTMLDivElement>) {
        if (
          Object.is(event.target, divRef.current) ||
          divRef.current?.contains(event.target as Node) ||
          dropdownRef.current?.contains(event.target as Node)
        )
          setShow((x) => !x);
    }

    /**
     * Seleciona um item do select e mostra o valor selecionado
     * @param key string | number
     */
    function selectedItem(key: string | number | boolean) {
        value === undefined && setSelected(items.find((x) => x.key === key)?.item);
        onChange && onChange(key);
    }

    return (
        <div
            ref={divRef}
            className={`${styles.multiple_select} ${className}`}
            onClick={showDropdown}
            {...props}
        >
            <div className={styles.placeholder}>
                {items.find(x => x.key === value)?.item || selected || placeholder}
            </div>

            <img className={styles.chevron_icon} src={ChevronBottomIcon} alt='Ícone de seleção' />

            <ul ref={dropdownRef} className={`${styles.dropdown} ${show ? styles.active : ''}`} >
                {
                    items.map(({ key, item }, i) => (
                        <li
                            key={`dropdown-item-${i}`}
                            className={styles.dropdown_item}
                            onClick={() => selectedItem(key)}
                        >
                            {item}
                        </li>
                    ))
                }
            </ul>
        </div>
    )
})

export default Select