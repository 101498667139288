/* ---------- COMPONENTS ---------- */
import { Placeholder } from "react-bootstrap";

/* ---------- STYLES ---------- */
import styles from "components/admin/LoadingDataTable/styles.module.scss";

export default function loadingDataTable(columns: number, lines: number = 5) {
    const columnsElements = Array.from({ length: columns }, (_, i) => i);

    const linesElements = Array.from({ length: lines });

    return (
        linesElements.map((_, i) => (
            <tr key={`tr-${i + 1}`}> {
                columnsElements.map((_2, cI) => (
                    <td key={`td-${cI + 1}`}>
                        <Placeholder sm={12} lg={6} animation="wave" className={styles.text_loading} />
                    </td>
                ))
            } </tr>
        ))
    );
}
