/* ----------- RESOURCES ----------- */
import { forwardRef, Ref } from 'react'

/* ----------- CONTRACTS ----------- */
import { BadgeProps } from 'contracts/Admin'

/* ----------- COMPONENTS ----------- */
import CloseButton from 'react-bootstrap/CloseButton'

/* ----------- STYLES ----------- */
import styles from 'components/admin/Badge/styles.module.scss'

const Badge = forwardRef(
    (
        { children, pill = false, iconClosed = false, className = "", onRemoveBadge, color, ...props }: BadgeProps,
        ref: Ref<HTMLSpanElement>
    ) => {
        const badge_rounded = pill ? styles.badge_pill : styles.badge_rounded;
        const badge_color = color ? styles[color] : styles.primary;

        return (
            <span
                ref={ref}
                className={`${styles.badge_container} ${badge_color} ${badge_rounded} ${className}`}
                {...props}
            >
                {children}
                {iconClosed && (
                    <span onClick={onRemoveBadge} className={styles.box_button_close}>
                        <CloseButton />
                    </span>
                )}
            </span>
        );
    }
);

export default Badge