/* ----------- RESOURCES ----------- */
import { forwardRef, Ref } from "react";
import * as reactIs from "react-is";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

/* -----------  CONTRACTS ----------- */
import { InputDateProps } from "contracts/Admin";

/* ----------- COMPONENTS ----------- */
import Icon from "components/Icon";
import { InputGroup, FormControl, FormControlProps } from "react-bootstrap";

/* ----------- STYLES ----------- */
import styles from "components/admin/InputDate/styles.module.scss";

const Input = forwardRef(
    (
        { iconLeft, iconRight, eventClick, mask = null, className = "", ...props }: InputDateProps,
        ref: Ref<HTMLInputElement>
    ) => {
        return (
            <InputGroup className={`${styles.input_group} ${className}`}>
                {/* Div do icon esquerdo */}
                <InputGroup.Text className={styles.box_icon_left}>
                    {iconLeft &&
                        (reactIs.isElement(iconLeft) ? (
                            iconLeft
                        ) : (
                            <Icon
                                name={(iconLeft as string) ?? ""}
                                className={styles.icon}
                                classPlaceholder={styles.icon}
                                alt="Ícone representativo"
                            />
                        ))}
                </InputGroup.Text>

                {/* recurso de mascara do input */}
                <FormControl
                    ref={ref}
                    className={`${styles.input} p-0`}
                    {...(props as unknown as FormControlProps)}
                />

                {/* Div do icon direito */}
                <InputGroup.Text className={styles.box_icon_right} onClick={eventClick}>
                    {iconRight &&
                        (reactIs.isElement(iconRight) ? (
                            iconRight
                        ) : (
                            <Icon
                                name={(iconRight as string) ?? ""}
                                className={styles.icon}
                                classPlaceholder={styles.icon}
                                alt="Ícone representativo de alguma ação ou do tipo de entrada de formulário"
                            />
                        ))}
                </InputGroup.Text>
            </InputGroup>
        );
    }
);

const InputDate = ({
    iconLeft,
    iconRight,
    eventClick,
    onChange,
    placeholder,
    locale: _,
    showMonthDropdown,
    showYearDropdown,
    mask,
    ...props
}: ReactDatePickerProps & InputDateProps) => (
    <DatePicker
        showMonthDropdown
        showYearDropdown
        closeOnScroll
        timeCaption="Hora"
        placeholderText={placeholder}
        onChange={onChange}
        {...props}
        customInput={
            <Input
                mask={mask}
                iconLeft={iconLeft}
                iconRight={iconRight}
                eventClick={eventClick}
            />
        }
    />
);

export default InputDate;
