/* ----------- RESOURCES ----------- */
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import moment from "moment";

/* ---------- UTILS ---------- */
import { useForm } from "hooks/useForm";
import { useFetch } from "hooks/useFetch";
import { useDebounce } from "hooks/useDebounce";

/* ---------- ICONS ---------- */
import CalendarIcon from "assets/icons/calendar.svg";
import ClockIcon from "assets/icons/clock.svg";
import UnionIcon from "assets/icons/union.svg";

/* ----------- COMPONENTS ----------- */
import ButtonIcon from "components/ButtonIcon";
import Actions from "components/admin/Actions";
import DataTable from "components/admin/DataTable";
import Input from "components/admin/Input";
import InputDate from "components/admin/InputDate";
import Label from "components/admin/Label";
import Badge from "components/admin/Badge";
import loadingDataTable from "components/admin/LoadingDataTable";
import PopoverLocation from "components/admin/PopoverLocation";
import Reloading from "components/Reloading";
import { Col, Form, Tabs, Tab, OverlayTrigger, Popover } from "react-bootstrap";
import Select from "components/admin/Select";


/* ----------- CONTRACTS ----------- */
import { Paginate } from "contracts/Paginate";
import { Card, Message, Notification, TabMessageFormFilters, TabProps, TabTargetAudienceFormFilters, TargetAudience, tabNotificationFormFilters } from "./types"

/* ----------- STYLES ----------- */
import styles from "./styles.module.scss";


const actionTargetName = `location_`;

const breadcrumbs = [
    {
        label: "Início",
        path: "/admin/home",
    },
    {
        label: "Gestão de Notificações",
        path: "/admin/notificacoes",
    },
];

const statusNotification = [
    { key: "yes", item: "Enviada" },
    { key: "no", item: "Pendente" },
];

const paginate = {
    page: 1,
    perPage: 10
}

const tabNotificationFields = ["ID", "Title", "Mensagem", "Data e hora d. disparo", "Notificação enviada", "Público alvo"];
const tabNotificationForm = {
    ...paginate,
    message_title: '',
    scheduled_at: { start: null, end: null },
    status: null
}

const tabMessageFields = ["Títulos da mensagem", "Mensagem", "Data de criação"];
const tabMessageForm = {
    ...paginate,
    title: "",
    created_at: {
        start: null,
        end: null
    }
}

const tabTargetAudienceFields = ["Título", "Localidades", "Cartões"];
const tabTargetAudienceForm = {
    ...paginate,
    name: ""
}

function formatFilterDate(date: { start: Date | null; end: Date | null }) {
    if (!date.start && !date.end) return null;

    if (date.start && date.end)
        return `${moment(date.start).format("YYYY-MM-DD")},${moment(date.end).format("YYYY-MM-DD")}`;

    return moment(date.start ?? date.end).format("YYYY-MM-DD");
}

function listNotifications(paginate: Paginate<Notification[]>) {
    return paginate?.data?.map((n: Notification) => (
        <tr key={`notification-${n.id}`} data-id={n.id}>
            <td>{n.id}</td>
            <td>
                <OverlayTrigger
                    trigger="click"
                    placement="top"
                    overlay={
                        <Popover className={styles.popover_message}>
                            <Popover.Body className={styles.popover_body}>{n.message.title}</Popover.Body>
                        </Popover>
                    }
                >
                    <span>{`${n.message.title.substring(0, 25)}...`}</span>
                </OverlayTrigger>
            </td>
            <td>
                <OverlayTrigger
                    trigger="click"
                    placement="top"
                    overlay={
                        <Popover className={styles.popover_message}>
                            <Popover.Body className={styles.popover_body}>{n.message.message}</Popover.Body>
                        </Popover>
                    }
                >
                    <span>{`${n.message.message.substring(0, 50)}...`}</span>
                </OverlayTrigger>
            </td>
            <td>
                <p className={styles.datetime}>
                    <img src={CalendarIcon} alt="Calendar icon" className={styles.table_icon} />

                    <span>{moment(n.scheduled_at).format("DD/MM/YYYY")}</span>

                    <img src={ClockIcon} alt="Clock icon" className={styles.table_icon} />

                    <span>{moment(n.scheduled_at).format("HH:mm")}</span>
                </p>
            </td>
            <td>
                <Col className="col-4 mx-auto">
                    <Badge className="py-1" color={n.processed_at ? "success" : "warning"} pill>
                        {n.processed_at ? "Sim" : "Não"}
                    </Badge>
                </Col>
            </td>
            <td>{n.target_audiences[0]?.name}</td>
        </tr>
    ));
}

function listMessages(paginate: Paginate<Message[]>) {
    return paginate?.data?.map((m: Message) => (
        <tr key={`message-${m.id}`} data-id={m.id}>
            <td className={styles.table_title}>{m.title}</td>

            <td>
                <OverlayTrigger
                    trigger="click"
                    placement="top"
                    overlay={
                        <Popover className={styles.popover_message}>
                            <Popover.Body className={styles.popover_body}>{m.message}</Popover.Body>
                        </Popover>
                    }
                >
                    <span> {`${m.message.substring(0, 80)}...`}</span>
                </OverlayTrigger>
            </td>

            <td className={styles.datetime} style={{ width: "100%" }}>
                <img src={CalendarIcon} alt="Calendar icon" className={styles.tableIcon} />{" "}
                {moment(m.created_at).format("DD/MM/YYYY [ás] HH:mm")}
            </td>
        </tr>
    ));
}

function TabNotification({ setItemIdEditable }: TabProps) {
    const [formFilters, updateFormFilters] = useForm<tabNotificationFormFilters>(tabNotificationForm);

    const debounce = useDebounce();
    const debounceClearFilter = useDebounce();

    const { data, error, isValidating, mutate } = useFetch(
        `/notification?page=${formFilters.page}&perPage=${formFilters.perPage}`, {
        params: {
                include: "message,targetAudiences",
                "filter[message.title]": formFilters.message_title,
                "filter[scheduled_at_date]": formatFilterDate(formFilters.scheduled_at),
                "filter[status]": formFilters.status,
        },
    }
    );

    const isLoadingNotification = !data && !error;
    const isReloadingNotification = data && isValidating;
    const isEmptyNotification = !data || !data?.data || error;

    const filters = [
        <Form.Group className="col-11 col-lg-4 mx-auto mx-lg-0" key="notification-filter-search">
            <Label text="Pesquisar" />

            <Input
                type="text"
                iconRight="search"
                placeholder="Digite aqui sua pesquisa"
                aria-label="Digite aqui sua pesquisa"
                value={formFilters.message_title}
                onChange={handleFilterSearch}
            />
        </Form.Group>,

        <Form.Group className="row col-11 col-lg-4 p-0 mx-auto mx-lg-0" key="notification-filter-date">
            <Label text="Data de criação" />

            <Col className="col-6 p-0">
                <InputDate
                    className={styles.input_datetime_left}
                    placeholder="Data inicial"
                    iconLeft="calendar"
                    selected={formFilters.scheduled_at.start}
                    onChange={(date) => handleFilterCreatedAt(date, 'start')}
                    dateFormat="dd/MM/yyyy"
                    selectsStart
                    startDate={formFilters.scheduled_at.start}
                    endDate={formFilters.scheduled_at.end}
                />
            </Col>

            <Col className="col-6 p-0">
                <InputDate
                    className={styles.input_datetime_right}
                    placeholder="Data final"
                    style={{ borderLeft: "none !important" }}
                    iconLeft="calendar"
                    selected={formFilters.scheduled_at.end}
                    onChange={(date) => handleFilterCreatedAt(date, 'end')}
                    dateFormat="dd/MM/yyyy"
                    selectsEnd
                    startDate={formFilters.scheduled_at.start}
                    endDate={formFilters.scheduled_at.end}
                    minDate={formFilters.scheduled_at.start}
                />
            </Col>
        </Form.Group>,

        <Form.Group className="row col-11 col-lg-3 p-0 mx-auto mx-lg-0">
            <Label text="Status da notificação" />

            <Select
                placeholder="Selecione o status "
                items={statusNotification}
                value={formFilters.status}
                onChange={(key) => updateFormFilters("status", key)}
            />
        </Form.Group>,
    ];

    function handleFilterSearch(event: ChangeEvent<HTMLInputElement>) {
        const name = event.target.value;
        updateFormFilters("message_title", name);

        debounce(() => {
            updateFormFilters("page", 1);
            mutate();
        }, 500)();
    }

    function handleFilterCreatedAt(date: Date | null, field: string) {
        updateFormFilters("scheduled_at", {
            ...formFilters.scheduled_at,
            [field]: date,
        });
    }

    function handleClearFilters() {
        updateFormFilters(
            ['page', 'message_title', 'scheduled_at', 'status'],
            [1, '', { start: null, end: null }, null]
        );

        debounceClearFilter(() => mutate(), 20)();
    }

    return (
        <>
            <DataTable
                className={styles.data_table_custom}
                filters={filters}
                fields={tabNotificationFields}
                currentPage={formFilters.page}
                total={data?.total}
                onPaginate={(p) => updateFormFilters("page", p)}
                onPerPage={(p) => updateFormFilters("perPage", p)}
                onSearch={() => mutate()}
                onSelectLine={(id) => setItemIdEditable(id)}
                onClearFilters={handleClearFilters}
                data={
                    isLoadingNotification
                        ? loadingDataTable(tabNotificationFields.length, formFilters.perPage)
                        : isEmptyNotification
                            ? []
                            : listNotifications(data)
                }
            />

            {/* RELOADING SPINNER */}
            {isReloadingNotification && <Reloading />}
            {/* ----------------- */}
        </>
    );
}

function TabMessage({ setItemIdEditable }: TabProps) {
    const [formFilters, updateFormFilters] = useForm<TabMessageFormFilters>(tabMessageForm);

    const debounce = useDebounce();
    const debounceClearFilter = useDebounce();

    const {
        data: message,
        error,
        isValidating,
        mutate,
    } = useFetch(`/message?page=${formFilters.page}&perPage=${formFilters.perPage}`, {
        params: {
            "filter[title]": formFilters.title,
            "filter[created_at]": formatFilterDate(formFilters.created_at),
        },
    });

    const isLoadingMessage = !message && !error;
    const isReloadingMessage = message && isValidating;
    const isEmptyMessage = !message || !message?.data || error;

    const filters = [
        <Form.Group className="col-11 col-lg-5 mx-auto mx-lg-0" key="message-filter-search">
            <Label text="Pesquisar" />

            <Input
                type="text"
                iconRight="search"
                placeholder="Digite aqui sua pesquisa"
                aria-label="Digite aqui sua pesquisa"
                value={formFilters.title}
                onChange={handleFilterSearch}
            />
        </Form.Group>,

        <Form.Group className="row col-11 col-lg-4 p-0 mx-auto mx-lg-0" key="message-filter-date">
            <Label text="Data de criação" />

            <Col className="col-6 p-0">
                <InputDate
                    className={styles.input_datetime_left}
                    placeholder="Data inicial"
                    iconLeft="calendar"
                    selected={formFilters.created_at.start}
                    onChange={(date) => handleFilterCreatedAt(date, 'start')}
                    dateFormat="dd/MM/yyyy"
                    selectsStart
                    startDate={formFilters.created_at.start}
                    endDate={formFilters.created_at.end}
                />
            </Col>

            <Col className="col-6 p-0">
                <InputDate
                    className={styles.input_datetime_right}
                    placeholder="Data final"
                    style={{ borderLeft: "none !important" }}
                    iconLeft="calendar"
                    selected={formFilters.created_at.end}
                    onChange={(date) => handleFilterCreatedAt(date, 'end')}
                    dateFormat="dd/MM/yyyy"
                    selectsEnd
                    startDate={formFilters.created_at.start}
                    endDate={formFilters.created_at.end}
                    minDate={formFilters.created_at.start}
                />
            </Col>
        </Form.Group>,
    ];

    function handleFilterSearch(event: ChangeEvent<HTMLInputElement>) {
        const name = event.target.value;
        updateFormFilters('title', name);

        debounce(() => {
            updateFormFilters("page", 1);
            mutate();
        }, 500)();
    }

    function handleFilterCreatedAt(date: Date | null, field: string) {
        updateFormFilters("created_at", {
            ...formFilters.created_at,
            [field]: date,
        });
    }

    function handleClearFilters() {
        updateFormFilters(
            ['page', 'title', 'created_at'],
            [1, '', { start: null, end: null }]
        )

        debounceClearFilter(() => mutate(), 20)();
    }

    return (
        <>
            <DataTable
                className={styles.data_table_custom}
                filters={filters}
                fields={tabMessageFields}
                total={message?.total}
                currentPage={formFilters.page}
                onPaginate={(p) => updateFormFilters("page", p)}
                onPerPage={(p) => updateFormFilters("perPage", p)}
                onSearch={() => mutate()}
                onSelectLine={(id) => setItemIdEditable(id)}
                onClearFilters={handleClearFilters}
                data={
                    isLoadingMessage
                        ? loadingDataTable(tabMessageFields.length, formFilters.perPage)
                        : isEmptyMessage
                            ? []
                            : listMessages(message)
                }
            />

            {/* RELOADING SPINNER */}
            {isReloadingMessage && <Reloading />}
            {/* ----------------- */}
        </>
    );
}

function TabTargetAudience({ setItemIdEditable }: TabProps) {
    const [formFilters, updateFormFilters] = useForm<TabTargetAudienceFormFilters>(tabTargetAudienceForm);
    const [showLocation, setShowLocation] = useState({});
    const [actionTarget, setActionTarget] = useState(null);

    const debounce = useDebounce();
    const debounceClearFilter = useDebounce();

    const {
        data: targetAudience,
        error,
        isValidating,
        mutate,
    } = useFetch(`target-audience?page=${formFilters.page}&perPage=${formFilters.perPage}`, {
        params: {
            sort: "id",
            include: "locations,cards",
            "filter[name]": formFilters.name,
        },
    });

    const isLoadingTargetAudience = !targetAudience && !error;
    const isReloadingTargetAudience = targetAudience && isValidating;
    const isEmptyTargetAudience = !targetAudience || !targetAudience?.data || error;

    const filters = [
        <Form.Group className="col-11 col-lg-5 mx-auto mx-lg-0" key="target-audience-filter-search">
            <Label text="Pesquisar" />

            <Input
                type="text"
                iconRight="search"
                placeholder="Digite aqui sua pesquisa"
                aria-label="Digite aqui sua pesquisa"
                value={formFilters.name}
                onChange={handleFilterSearch}
            />
        </Form.Group>,
    ];

    function handleFilterSearch(event: ChangeEvent<HTMLInputElement>) {
        const name = event.target.value;
        updateFormFilters("name", name);

        debounce(() => {
            updateFormFilters("page", 1);
            mutate();
        }, 500)();
    }

    function handleClearFilters() {
        updateFormFilters(["name", "page"], ["", 1]);
        debounceClearFilter(() => mutate(), 20)();
    }

    const listTargetAudience = useMemo(() => {
        function handleMouseEnterLocation(id: number, event: MouseEvent<HTMLSpanElement>) {
            showLocation[`${actionTargetName}${id}`] = true;
            setShowLocation({ ...showLocation });
            setActionTarget(event.currentTarget);
        }

        function handleMouseLeaveLocation(id: number) {
            showLocation[`${actionTargetName}${id}`] = false;
            setShowLocation({ ...showLocation });
            setActionTarget(null);
        }

        return targetAudience?.data?.map((t: TargetAudience) => (
            <tr key={t.id} data-id={t.id}>
                <td className={styles.table_title}>{t.name}</td>
                <td className={styles.table_title}>
                    {t.locations[0]?.name}
                    <span
                        className={`${styles.badge_circle} ${styles.badge_success}`}
                        onMouseEnter={(event) => handleMouseEnterLocation(t.id, event)}
                        onMouseLeave={() => handleMouseLeaveLocation(t.id)}
                    >
                        <img className={styles.union_icon} src={UnionIcon} alt="ícone da marca union" />

                        {t.locations?.length > 0 && (
                            <PopoverLocation
                                classPopover={styles.popover_location}
                                show={showLocation[`${actionTargetName}${t.id}`]}
                                target={actionTarget}
                                placement="top-start"
                                location={{
                                    title: t.locations[0]?.name,
                                    lat: parseFloat(t.locations[0]?.lat),
                                    lng: parseFloat(t.locations[0]?.lng),
                                    radius: t.locations[0]?.radius_km,
                                }}
                            />
                        )}
                    </span>
                </td>
                <td>
                    <Col className="d-flex gap-1">
                        {t.cards.slice(0, 3).map((c: Card) => (
                            <Badge key={`card-${c.id}`} pill>
                                {" "}
                                {c.name}{" "}
                            </Badge>
                        ))}

                        {t.cards.length > 3 && (
                            <Badge key="card-more" pill>
                                ...
                            </Badge>
                        )}
                    </Col>
                </td>
            </tr>
        ));
    }, [targetAudience, showLocation, actionTarget]);

    useEffect(() => {
        if (!isEmptyTargetAudience) {
            targetAudience.data.forEach(({ id }: TargetAudience) => {
                showLocation[`${actionTargetName}${id}`] = false;
            });

            setShowLocation({ ...showLocation });
        }

        // eslint-disable-next-line
    }, [targetAudience, isEmptyTargetAudience]);

    return (
        <>
            <DataTable
                className={styles.data_table_custom}
                filters={filters}
                fields={tabTargetAudienceFields}
                currentPage={formFilters.page}
                total={targetAudience?.total}
                onPaginate={(p) => updateFormFilters("page", p)}
                onPerPage={(p) => updateFormFilters("perPage", p)}
                onSearch={() => mutate()}
                onSelectLine={(id) => setItemIdEditable(id)}
                onClearFilters={handleClearFilters}
                data={
                    isLoadingTargetAudience
                        ? loadingDataTable(tabTargetAudienceFields.length, formFilters.perPage)
                        : isEmptyTargetAudience
                            ? []
                            : listTargetAudience
                }
            />

            {/* RELOADING SPINNER */}
            {isReloadingTargetAudience && <Reloading />}
            {/* ----------------- */}
        </>
    );
}

export default function Notifications() {
    const navigate = useNavigate();
    const location = useLocation();

    const currentTab = (new URLSearchParams(location.search)?.get('tab') ?? 'notifications');

    const [currentTabId, setCurrentTabId] = useState(currentTab);
    const [itemIdEditable, setItemIdEditable] = useState<number | null>(null);

    const activeTabClass = (tabId: string) => (currentTabId === tabId ? styles.active : "");

    const handleChangeIdEEditable = useCallback((id) => {
        setItemIdEditable(id)
    }, []);

    const Button = () => {
        switch (currentTabId) {
            case "notifications":
                return (
                    <>
                        {itemIdEditable && (
                            <Col className="col-auto col-lg-3 d-grid">
                                <ButtonIcon
                                    icon="edit-action"
                                    onClick={() => navigate(`editar-disparo/${itemIdEditable}`)}
                                >
                                    Editar Disparo
                                </ButtonIcon>
                            </Col>
                        )}

                        <Col className="col-auto col-lg-3 d-grid">
                            <ButtonIcon icon="add-action" onClick={() => navigate("novo-disparo")}>
                                Novo Disparo
                            </ButtonIcon>
                        </Col>
                    </>
                );

            case "messages":
                return (
                    <>
                        {itemIdEditable && (
                            <Col className="col-auto col-lg-3 d-grid">
                                <ButtonIcon
                                    icon="edit-action"
                                    onClick={() => navigate(`editar-mensagem/${itemIdEditable}`)}
                                >
                                    Editar mensagem
                                </ButtonIcon>
                            </Col>
                        )}

                        <Col className="col-auto col-lg-3 d-grid">
                            <ButtonIcon icon="add-action" onClick={() => navigate("nova-mensagem")}>
                                Nova mensagem
                            </ButtonIcon>
                        </Col>
                    </>
                );

            case "target-public":
                return (
                    <>
                        {itemIdEditable && (
                            <Col className="col-auto col-lg-3 d-grid">
                                <ButtonIcon
                                    icon="edit-action"
                                    onClick={() => navigate(`editar-publico/${itemIdEditable}`)}
                                >
                                    Editar público
                                </ButtonIcon>
                            </Col>
                        )}

                        <Col className="col-auto col-lg-3 d-grid">
                            <ButtonIcon icon="add-action" onClick={() => navigate("novo-publico")}>
                                Novo público
                            </ButtonIcon>
                        </Col>
                    </>
                );
        }
    };

    return (
        <>
            <Actions title="Gestão de Notificações" breadcrumb={breadcrumbs} actionButtons={<Button />} />

            <Tabs
                defaultActiveKey={currentTabId}
                className={`${styles.tabs_container} col mx-auto gap-2`}
                onSelect={(eventKey) => setCurrentTabId(eventKey)}
            >
                <Tab
                    eventKey="notifications"
                    title="Notificações"
                    tabClassName={activeTabClass("notifications")}
                >
                    <TabNotification setItemIdEditable={handleChangeIdEEditable} />
                </Tab>

                <Tab eventKey="messages" title="Mensagens" tabClassName={activeTabClass("messages")}>
                    <TabMessage setItemIdEditable={handleChangeIdEEditable} />
                </Tab>

                <Tab
                    eventKey="target-public"
                    title="Público alvo"
                    tabClassName={activeTabClass("target-public")}
                >
                    <TabTargetAudience setItemIdEditable={handleChangeIdEEditable} />
                </Tab>
            </Tabs>
        </>
    );
}
