/* ----------- RESOURCES ----------- */
import Router from "pages/router";


import { ToastContainer } from "react-toastify";

/* ----------- STYLES ----------- */
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "styles/global.scss";

export default function App() {
    return (
        <>
            <Router />

            <ToastContainer />
        </>
    );
}
