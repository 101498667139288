/* ----------- RESOURCES ----------- */
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { formatErrors400 } from "utils/formatErrors";
import adminService from "services/adminService";

/* ----------- COMPONENTS ----------- */
import Actions from "components/admin/Actions";
import ButtonIcon from "components/ButtonIcon";
import Label from "components/admin/Label";
import Input from "components/admin/Input";
import SwitchSimples from "components/admin/SwitchSimples";
import { Col, Card, Form } from "react-bootstrap";

/* ----------- STYLES ----------- */
import styles from "pages/admin/users/add/styles.module.scss";

const breadcrumbs = [
    {
        label: "Início",
        path: "/admin/home",
    },
    {
        label: "Gestão de Usuários",
        path: "/admin/usuarios",
    },
    {
        label: "Novo usuário",
        path: "/admin/usuarios/adicionar",
    },
];

const validator = yup.object({
    name: yup.string().required("Nome é obrigatório"),
    email: yup.string().email("Por favor, Digite um e-mail válido").required("E-mail é obrigatório"),
    password: yup
        .string()
        .min(8, "A senha deve conter pelo menos 8 caracteres")
        .required("Senha é obrigatória"),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref("password"), null], "As senhas não conferem")
        .required("Confirmação de senha é obrigatória"),
    active: yup.boolean(),
});

export default function AddUser() {
    const {
        handleSubmit,
        register,
        setError,
        formState: { isValid, errors },
    } = useForm({
        resolver: yupResolver(validator),
        mode: "onChange",
    });

    const navigation = useNavigate();

    const [isSave, setIsSave] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const typePasword = showPassword ? "text" : "password";
    const icon = showPassword ? "eye-open" : "eye-closed";

    function handleShowPassword() {
        setShowPassword((s) => !s)
    }

    function handleCreateAdmin(data) {
        setIsSave(true);
        adminService
            .create(data)
            .then(() => {
                toast.success("Conta administrativa criada com sucesso!", { autoClose: 3000 });
                setTimeout(() => navigation("/admin/usuarios"), 1000);
            })
            .catch((err) => {
                if (err.status === 400) {
                    formatErrors400(err.data?.errors ?? [], (e) => {
                        setError(e.name, {
                            type: "manual",
                            message: e.message,
                        });
                    });

                    return toast.error("Verifique os dados e tente novamente", { autoClose: 3000 });
                }

                toast.error("Ocorreu um erro ao criar a conta", { autoClose: 3000 });
            })
            .finally(() => setIsSave(false));
    }

    return (
        <>
            <Actions
                title="Novo usuário"
                breadcrumb={breadcrumbs}
                actionButtons={
                    <>
                        <Col className="col-auto col-lg-3  d-grid">
                            <ButtonIcon
                                icon="removeorcancel-action"
                                className={styles.button_cancel}
                                onClick={() => navigation("/admin/usuarios")}
                            >
                                Cancelar
                            </ButtonIcon>
                        </Col>

                        <Col className="col-3  d-grid">
                            <ButtonIcon
                                icon={isSave ? "loading" : "save-action"}
                                className={styles.button_save}
                                onClick={handleSubmit(handleCreateAdmin)}
                                disabled={!isValid || isSave}
                            >
                                {isSave ? "Salvando" : "Salvar"}
                            </ButtonIcon>
                        </Col>
                    </>
                }
            />

            <Form onSubmit={(e) => e.preventDefault()} className="col-7 mx-auto">
                <Card className={`${styles.form_card} mb-3`}>
                    <Card.Body>
                        <Col className="row p-0 m-0">
                            <Form.Group className="col-6">
                                <Label text="Nome" />

                                <Input placeholder="Nome" aria-label="Nome" {...register("name")} />

                                {errors.name && (
                                    <Form.Text className="text_error">{errors.name?.message}</Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group className="col-6">
                                <Label text="Email" />

                                <Input
                                    type="email"
                                    placeholder="exemplo@mail.com"
                                    aria-label="exemplo@mail.com"
                                    {...register("email")}
                                />

                                {errors.email && (
                                    <Form.Text className="text_error">{errors.email?.message}</Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                    </Card.Body>
                </Card>

                <Card className={`${styles.form_card} mb-3`}>
                    <Card.Body className="px-1">
                        <Col className="row p-0 m-0">
                            <Form.Group className="col-6">
                                <Label text="Senha" />

                                <Input
                                    type={typePasword}
                                    className={`${styles.input_password}`}
                                    iconLeft="key"
                                    iconRight={icon}
                                    placeholder="Digite aqui sua senha"
                                    aria-label="Digite aqui sua senha"
                                    {...register("password")}
                                    eventClick={handleShowPassword}
                                />

                                {errors.password && (
                                    <Form.Text className="text_error">{errors.password?.message}</Form.Text>
                                )}
                            </Form.Group>

                            <Form.Group className="col-6">
                                <Label text="Confirmar senha" />

                                <Input
                                    type={typePasword}
                                    className={`${styles.input_password}`}
                                    iconLeft="key"
                                    iconRight={icon}
                                    placeholder="Repita a senha"
                                    aria-label="Repita a senha"
                                    eventClick={handleShowPassword}
                                    {...register("password_confirmation")}
                                />

                                {errors.password_confirmation && (
                                    <Form.Text className="text_error">
                                        {errors.password_confirmation?.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                    </Card.Body>
                </Card>

                <Card className={`${styles.form_card} mb-3`}>
                    <Card.Body>
                        <Form.Group className="col-6">
                            <Label text="Ativar conta ?" />

                            <SwitchSimples checked {...register("active")} />
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Form>
        </>
    );
}
