/* ----------- RESOURCES ----------- */
import { useNavigate } from 'react-router-dom'
import ImgIllustration from "assets/admin_esqueceu_senha.svg";

/* ----------- COMPONENTS ----------- */
import Input from 'components/auth/Input'
import Label from 'components/auth/Label'
import Link from 'components/auth/Link'
import Button from 'components/Button'
import {
    Card,
    Col,
    Form
} from 'react-bootstrap'

/* ----------- STYLES ----------- */
import styles from 'pages/auth/recoverPassword/styles.module.scss'

export default function RecoverPassword() {
    const navigate = useNavigate()

    return (
        <Card className={`${styles.card_container} col-11 col-xxl-7 col-xl-7 col-lg-7 col-sm-11`}>
            <Card.Body className={`${styles.card_body} col-12 d-flex m-0`}>
                <Col className="col-12 col-xll-6 col-xl-6 col-lg-6 col-sm-12">
                    <Col className="col-9 mx-auto mt-3">
                        <h1 className={styles.h1}>Recuperação de senha</h1>

                        <p className={styles.description}>
                            Informe o seu e-mail para enviarmos o link para a recuperação de senha.
                        </p>

                        <Form style={{ marginBottom: "4rem" }}>
                            <Form.Group style={{ marginBottom: "2.2rem" }}>
                                <Label text="Email" />

                                <Input
                                    type="email"
                                    iconLeft="user"
                                    placeholder="Digite seu e-mail"
                                    aria-label="Digite seu e-mail"
                                />
                            </Form.Group>

                            <Form.Group className="col-12 mx-auto p-0">
                                <Col className="d-grid gap-2">
                                    <Button text="Enviar" eventClick={() => navigate("/nova-senha")} />
                                </Col>
                            </Form.Group>
                        </Form>

                        <Col className="col-12 text-center">
                            <Link text="Voltar para o login" back />
                        </Col>
                    </Col>
                </Col>

                <Col className="d-none d-xxl-flex d-xl-flex d-lg-flex col-6 justify-content-center align-items-center">
                    <img
                        src={ImgIllustration}
                        className={styles.img_illustration}
                        alt="Imagem ilustrativa sobre recuperação de senha"
                        aria-label="Imagem ilustrativa sobre recuperação de senha"
                    />
                </Col>
            </Card.Body>
        </Card>
    );
}