/* ---------- RESOURCES ---------- */
import { ChangeEventHandler, memo, useMemo, useState } from "react";
import { useFetch } from "hooks/useFetch";
import moment from "moment";

/* ---------- COMPONENTS ---------- */
import Actions from "components/admin/Actions";
import Icon from "components/Icon";
import Label from "components/admin/Label";
import InputDate from "components/admin/InputDate";
import ButtonIcon from "components/ButtonIcon";
import { Col, Card, Placeholder, ListGroup, Form } from "react-bootstrap";

/* ---------- STYLES ---------- */
import styles from "pages/admin/dashboard/styles.module.scss";
import { Paginate } from "contracts/Paginate";

interface Client {
    id: number;
    name: string;
    cpf: string;
    cellphone: string;
}

interface Log {
    id: number;
    created_at: string;
    client: Client;
}

interface Summary {
    today: number;
    month: number;
    lastMonth: number;
    year: number;
    total: number;
    period_access: number;
}

interface CardStatisticProps {
    keyItem: string;
    title: string;
    value: number;
    icon?: string;
}

interface ListStatisticProps {
    summary: Summary;
    summaryError: any;
}

interface ListLogsAccessProps {
    logs: Paginate<Log> | null;
    logsError: any;
}

interface FiltersProps {
    periodStart: Date;
    onFilterPeriodStart: ((date: Date, event: React.SyntheticEvent<any, Event>) => void) &
        ChangeEventHandler<any>;
    periodEnd: Date;
    onFilterPeriodEnd: ((date: Date, event: React.SyntheticEvent<any, Event>) => void) &
        ChangeEventHandler<any>;
    onSearch: (event: any) => void;
}

const summaryKeys = ["today", "month", "lastMonth", "year", "total", "period_access"];

// --- FORMATS --- //
function formatCPF(cpf: string) {
    return cpf.replaceAll(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
}

function formatCellphone(cellphone: string | number) {
    cellphone = typeof cellphone === "number" ? String(cellphone) : cellphone;

    return cellphone.replaceAll(/(\d{2})(\d{5})(\d{4})/g, "($1) $2-$3");
}

function formatDatetime(date: string) {
    return moment(date).format("DD/MM/YYYY HH:mm");
}

function getSummaryIcon(key: string) {
    const icons = {
        today: "dashboard-today",
        month: "dashboard-month",
        lastMonth: "dashboard-last-month",
        year: "dashboard-year",
        total: "dashboard-total",
        period_access: "dashboard-month",
    };

    return icons[key] ?? null;
}

function getSummaryTitle(key: string) {
    const titles = {
        today: "Acessos do dia",
        month: "Acessos no mês",
        lastMonth: "Acesso do ul. mês",
        year: "Acessos no ano",
        total: "Total de acessos",
        period_access: "Total de acesso por período",
    };

    return titles[key] ?? null;
}

// --- COMPONENTS --- //
function SkeletonStatistic({ keyItem }: { keyItem: string }) {
    return (
        <Card className={`${styles.card_statistic} ${styles[keyItem]} ${styles.card_skeleton} shadow mb-3`}>
            <Card.Body>
                <Placeholder as={Card.Title} style={{ marginBottom: "0.8rem" }} animation="wave">
                    <Placeholder className={styles.skeleton_title} />
                </Placeholder>

                <Placeholder as="span" style={{ marginBottom: "0.8rem" }} animation="wave">
                    <Placeholder className={styles.skeleton_value} />
                </Placeholder>
            </Card.Body>
        </Card>
    );
}

function SkeletonLogs() {
    return (
        <ListGroup.Item as="li" className={styles.list_item}>
            <Placeholder animation="wave">
                <Placeholder as="span" style={{ width: "120px" }} />
            </Placeholder>

            <Placeholder animation="wave">
                <Placeholder as="span" style={{ width: "120px" }} />
            </Placeholder>

            <Placeholder animation="wave">
                <Placeholder as="span" style={{ width: "120px" }} />
            </Placeholder>

            <Placeholder animation="wave">
                <Placeholder as="span" style={{ width: "120px" }} />
            </Placeholder>
        </ListGroup.Item>
    );
}

function CardStatistic({ keyItem, title, value, icon }: CardStatisticProps) {
    return (
        <Card className={`${styles.card_statistic} ${styles[keyItem]} shadow mb-3`}>
            <Card.Body>
                <Card.Title className={styles.title}>{title}</Card.Title>

                <span className={styles.value}>{value}</span>

                {icon && (
                    <div className={`${styles.icon} ${styles[keyItem]} shadow-sm`}>
                        <Icon name={icon} alt={`icon-${icon}`} classPlaceholder={styles.icon_skeleton} />
                    </div>
                )}
            </Card.Body>
        </Card>
    );
}

function Filters({ periodStart, periodEnd, onFilterPeriodStart, onFilterPeriodEnd, onSearch }: FiltersProps) {
    return (
        <Col className="col-11 mx-auto mb-5">
            <Form onSubmit={(e) => e.preventDefault()}>
                <Form.Group className="col-12" key="filter-date">
                    <Label className="px-0" text="Filtrar por período" />

                    <Col className="row col-12">
                        <Col className="row col-12 mb-3 mx-auto col-lg-4 mb-lg-0 mx-lg-0">
                            <Col className="col-lg-5 p-0">
                                <InputDate
                                    className={styles.input_datetime_left}
                                    placeholder="Data inicial"
                                    iconLeft="calendar"
                                    selected={periodStart}
                                    onChange={onFilterPeriodStart}
                                    dateFormat="dd/MM/yyyy"
                                    selectsStart
                                    startDate={periodStart}
                                    endDate={periodEnd}
                                />
                            </Col>

                            <Col className="col-lg-5 p-0">
                                <InputDate
                                    className={styles.input_datetime_right}
                                    placeholder="Data final"
                                    style={{ borderLeft: "none !important" }}
                                    iconLeft="calendar"
                                    selected={periodEnd}
                                    onChange={onFilterPeriodEnd}
                                    dateFormat="dd/MM/yyyy"
                                    selectsEnd
                                    startDate={periodStart}
                                    endDate={periodEnd}
                                    minDate={periodStart}
                                />
                            </Col>
                        </Col>

                        <Col className="col-12 col-lg-5">
                            <Col className={`${styles.box_button_search} col-auto col-lg-4 d-grid gap-1`}>
                                <ButtonIcon
                                    text="Pesquisar"
                                    icon="search-action"
                                    onClick={onSearch}
                                    aria-label="Pesquisar"
                                />
                            </Col>
                        </Col>
                    </Col>
                </Form.Group>
            </Form>
        </Col>
    );
}

const ListStatistic = memo(({ summary, summaryError }: ListStatisticProps) => {
    const isLoadingDataSummary = !summary && !summaryError;
    const summaryData = summary ?? {};

    const listSummary = useMemo(
        () =>
            Object.keys(summaryData).map((key) => (
                <CardStatistic
                    keyItem={key}
                    key={`card-statistic-${key}`}
                    icon={getSummaryIcon(key)}
                    title={getSummaryTitle(key)}
                    value={summary[key]}
                />
            )),
        [summary, summaryData]
    );

    return (
        <>
            <Col className="col-11 mb-4 mx-0 col-lg-8 mx-auto d-flex flex-wrap justify-content-around gap-2 ">
                {isLoadingDataSummary
                    ? summaryKeys.map((k, i) => (
                          <SkeletonStatistic keyItem={k} key={`skeleton-statistic-${i}`} />
                      ))
                    : listSummary}
            </Col>
        </>
    );
});

const ListLogsAccess = memo(({ logs, logsError }: ListLogsAccessProps) => {
    const isLoading = !logs && !logsError;
    let logsData = [];

    if (!logsError && logs) {
        logsData = logs?.data ?? [];
    }

    const listLogsAccess = (log: Log) => (
        <ListGroup.Item key={`log-item-${log.id}`} as="li" className={styles.list_item}>
            <span>{log.client?.name}</span>
            <span>{formatCPF(log.client?.cpf ?? "")}</span>
            <span>{formatCellphone(log.client?.cellphone ?? "")}</span>
            <span>{formatDatetime(log.created_at)}</span>
        </ListGroup.Item>
    );

    return (
        <Col className="col-12 pb-3">
            <Col className={`${styles.container_list} mx-auto`}>
                <h3 className={styles.list_title}>Últimos 10 Acessos</h3>

                <ListGroup as="ul" className={styles.list} variant="flush">
                    {isLoading
                        ? Array(5)
                              .fill(0)
                              .map((_, i) => <SkeletonLogs key={`skeleton-log-${i}`} />)
                        : logsData.map(listLogsAccess)}
                </ListGroup>
            </Col>
        </Col>
    );
});

export default function Dashboard() {
    const [periodStart, setPeriodStart] = useState(moment().startOf("month").toDate());
    const [periodEnd, setPeriodEnd] = useState(moment().endOf("month").toDate());

    const periodStartFormatted = moment(periodStart).format("YYYY-MM-DD HH:mm:ss");
    const periodEndFormatted = moment(periodEnd).format("YYYY-MM-DD HH:mm:ss");

    const {
        data: logs,
        error: logsError,
        mutate: mutateLogs,
    } = useFetch<Paginate<Log>>(`/access-log`, {
        params: {
            "filter[period]": `${periodStartFormatted},${periodEndFormatted}`,
        },
    });

    const {
        data: summary,
        error: summaryError,
        mutate: mutateSummary,
    } = useFetch<Summary>(`/dashboard/summary`, {
        params: {
            period_start: periodStart,
            period_end: periodEnd,
        },
    });

    function handleFilterPeriodStart(date) {
        setPeriodStart(date);
    }

    function handleFilterPeriodEnd(date) {
        setPeriodEnd(date);
    }

    function handleSearch() {
        mutateLogs();
        mutateSummary();
    }

    const breadcrumbs = [
        {
            label: "Inicio",
            path: "/admin/home",
        },
        {
            label: "Estatísticas",
            path: "/admin/dashboard",
        },
    ];

    return (
        <>
            <Actions title="Estatísticas" breadcrumb={breadcrumbs} />

            <ListStatistic summary={summary} summaryError={summaryError} />

            <Filters
                periodStart={periodStart}
                periodEnd={periodEnd}
                onFilterPeriodStart={handleFilterPeriodStart}
                onFilterPeriodEnd={handleFilterPeriodEnd}
                onSearch={handleSearch}
            />

            <ListLogsAccess logs={logs} logsError={logsError} />
        </>
    );
}
