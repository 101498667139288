/* ----------- RESOURCES ----------- */
import { ImgHTMLAttributes, useState } from "react"

/* ----------- COMPONENTES ----------- */
import { Image as ImageBs, ImageProps as ImagePropsBs } from "react-bootstrap";
import NoImage from "assets/no_image.svg";

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement>, ImagePropsBs {

}


export default function Image({ src, ...props }: ImageProps) {
    const [notImage, setNotImage] = useState(false)

    function handleImgError() {
        setNotImage(true)
    }

    return (
        <ImageBs
            src={notImage ? NoImage : src}
            {...props}
            onError={handleImgError}
        />
    )
}