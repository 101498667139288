/* ----------- RESOURCES ----------- */
import { createContext, useContext, useEffect, useState } from "react";

/* ----------- SERVICES ----------- */
import api from "services/api";
import adminService from "services/adminService";

/* ----------- CONTRACTS ----------- */
import { keyToken, keyTypeUser, ignoreUrl } from "constants/auth";
import { AuthProviderProps, AuthContextProps, SignIn, TypeUser } from "contracts/AuthProvider";


const AuthContext = createContext({} as AuthContextProps);

function AuthProvider({ children }: AuthProviderProps) {
    const [token, setToken] = useState<string | null>(() => localStorage.getItem(keyToken));
    const [typeUser, setTypeUser] = useState<TypeUser | null>(
        () => localStorage.getItem(keyTypeUser) as TypeUser
    );

    async function signIn(email, password): Promise<SignIn> {
        try {
            const { status, data } = await adminService.signIn(email, password);

            if (status !== 200) {
                return { error: true, message: data.message, status };
            }

            window.localStorage?.setItem(keyToken, data.token);
            window.localStorage?.setItem(keyTypeUser, data.user.type);

            setTypeUser(data.user.type);
            setToken(data.token);

            return { error: false, data, status };
        } catch (error) {
            return {
                error: true,
                message: error.message,
                status: error.status,
            };
        }
    }

    async function signOut() {
        try {
            await adminService.signOut()

        } finally {
            window.localStorage?.removeItem(keyTypeUser);
            window.localStorage?.removeItem(keyToken);
            setToken(null);
            setTypeUser(null);
        }
    };

    useEffect(() => {
        const responseInterceptor = api.interceptors.response.use(
            (res) => res,
            (err) => {
                if (err.response.status === 401 && !ignoreUrl.includes(window.location.pathname)) {
                    localStorage.removeItem(keyToken);
                    localStorage.removeItem(keyTypeUser);
                    setToken(null);
                    setTypeUser(null);
                }

                return Promise.reject(err.response);
            }
        );

        return () => {
            api.interceptors.response.eject(responseInterceptor);
        }
    }, [api]);

    const authProps: AuthContextProps = {
        token,
        typeUser,
        signIn,
        signOut,
    };

    return <AuthContext.Provider value={authProps}>{children}</AuthContext.Provider>;
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
