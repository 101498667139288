/* ----------- RESOURCES ----------- */
import { useState, forwardRef, Ref } from "react"
import EyeClosed from 'assets/icons/eye-closed.svg'
import EyeOpen from 'assets/icons/eye-open.svg'

/* -----------  CONTRACTS ----------- */
import { InputProps } from 'contracts/Auth'


/* ----------- COMPONENTS ----------- */
import Icon from "components/Icon";
import { InputGroup, FormControl, Button } from "react-bootstrap";

/* ----------- STYLES ----------- */
import styles from "components/auth/Input/styles.module.scss";

const Input = forwardRef(
    (
        {
            type = "text",
            iconLeft,
            iconEye = false,
            className = "",
            classInputGroup = "",
            ...props
        }: InputProps,
        ref: Ref<HTMLInputElement>
    ) => {
        const [typeInput, setTypeInput] = useState(type);

        const changeTypeInput = () => setTypeInput((x) => (x === "password" ? "text" : "password"));

        return (
            <InputGroup className={classInputGroup}>
                <InputGroup.Text className={`${styles.box_icon_left} ${className}`}>
                    {iconLeft && (
                        <Icon
                            className={styles.icon}
                            classPlaceholder={styles.icon}
                            name={iconLeft}
                            alt="Ícone do olho bloqueado simbolizando senha a senha "
                        />
                    )}
                </InputGroup.Text>

                <FormControl className={`${styles.input} p-0`} type={typeInput} ref={ref} {...props} />

                {iconEye && (
                    <InputGroup.Text as={Button} onClick={changeTypeInput} className={styles.box_icon_right}>
                        <img
                            className={`${styles.icon}`}
                            src={typeInput === "text" ? EyeClosed : EyeOpen}
                            alt="Ícone do olho bloqueado simbolizando senha a senha "
                        />
                    </InputGroup.Text>
                )}
            </InputGroup>
        );
    }
);

export default Input