/* ----------- RESOURCES ----------- */
import { useState, useRef, useEffect, forwardRef } from "react";

/* ----------- CONTRACTS ----------- */
import { UploadFileProps } from "contracts/Admin";

/* ----------- COMPONENTS ----------- */
import Label from "components/admin/Label";
import ButtonIcon from "components/ButtonIcon";
import { Col, Form, Image } from "react-bootstrap";

/* ----------- STYLES ----------- */
import styles from "components/admin/UploadFile/styles.module.scss";

interface InputRef {
    current: HTMLInputElement | null;
}

const UploadFile = forwardRef(
    (
        {
            classNameForm,
            classNameButtonUpload,
            classNameUpload,
            inputFileState,
            onChange,
            onRemoveFile,
            label,
            accept = "image/jpg, image/jpeg, image/png",
            labelAccept = "JPG, JPEG, PNG",
            ...props
        }: UploadFileProps,
        ref: InputRef
    ) => {
        const [showDropdown, setShowDropdown] = useState(false);

        const buttonDropdownRef = useRef(null);
        const inputFileRef = useRef(null);

        ref ??= inputFileRef;

        function formatPreviousImage() {
            if (/^(http|https):\/\//.test(inputFileState)) {
                return inputFileState;
            }

            return URL.createObjectURL(inputFileState);
        }

        useEffect(() => {
            function handleClickElement(event: MouseEvent) {
                if (!Object.is(buttonDropdownRef.current, event.target)) {
                    setShowDropdown(false);
                }
            }

            const doc = document.querySelector("body");

            doc && doc.addEventListener("click", handleClickElement);

            return () => {
                doc && doc.removeEventListener("click", handleClickElement);
            };
        }, []);

        return (
            <Form.Group className={`${styles.form_inputFIle} ${classNameForm}`}>
                <Label text={label ?? "Imagem da publicação"} />

                <input
                    ref={ref}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onChange && onChange(e.target.files)}
                    accept={accept}
                    {...props}
                />

                <Col
                    className={`${styles.box_upload_image} ${classNameUpload} ${
                        inputFileState ? styles.active_upload : ""
                    } d-flex align-items-center`}
                >
                    {!inputFileState ? (
                        <>
                            <Col className="d-grid col-auto">
                                <ButtonIcon
                                    className={`${styles.button_upload} ${classNameButtonUpload} px-4`}
                                    icon="upload"
                                    onClick={() => ref.current && ref.current.click()}
                                >
                                    Carregar arquivo
                                </ButtonIcon>
                            </Col>

                            <Col className={`${styles.text_info} ms-3`}>
                                <p className="mb-0">Formatos permitidos:</p>
                                <span className="mb-0 fw-bold" style={{ fontSize: "14px" }}>
                                    {labelAccept}
                                </span>
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col className="col-3 h-auto">
                                <Col className={styles.box_image}>
                                    {accept.match(/image\/(.)+/g) && (
                                        <Image src={formatPreviousImage()} fluid thumbnail />
                                    )}
                                </Col>
                            </Col>

                            <Col className="col-4">
                                <p className={`${styles.text_info} mb-0 text-truncate`}>
                                    {inputFileState.name}
                                </p>
                            </Col>

                            <Col className="col-3 offset-1 d-grid position-relative" style={{ height: 40 }}>
                                <ButtonIcon
                                    ref={buttonDropdownRef}
                                    icon="edit-action"
                                    className={styles.button_inactive}
                                    onClick={() => setShowDropdown((x) => !x)}
                                >
                                    <span className="d-none d-sm-inline">Editar</span>
                                </ButtonIcon>

                                {showDropdown && (
                                    <ul className={styles.dropdown_option}>
                                        <li
                                            className={styles.dropdown_item}
                                            onClick={() => onRemoveFile && onRemoveFile()}
                                        >
                                            Excluir
                                        </li>
                                        <li
                                            className={styles.dropdown_item}
                                            onClick={() => ref.current && ref.current.click()}
                                        >
                                            Editar
                                        </li>
                                    </ul>
                                )}
                            </Col>
                        </>
                    )}
                </Col>
            </Form.Group>
        );
    }
);

export default UploadFile