/* ----------- RESOURCES ----------- */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

/* ----------- COMPONENTS ----------- */
import Input from "components/auth/Input";
import Label from "components/auth/Label";
import Link from "components/auth/Link";
import Button from "components/Button";
import { Card, Col, Form, Spinner } from "react-bootstrap";

/* ----------- STYLES ----------- */
import styles from "pages/auth/login/styles.module.scss";

import { useAuth } from "providers/AuthProvider";

const validator = yup.object({
    email: yup.string().email("Por favor, Digite um e-mail válido").required("E-mail é obrigatório"),
    password: yup
        .string()
        .min(8, "A senha deve conter pelo menos 8 caracteres")
        .required("Senha é obrigatória"),
});

export default function Login() {
    const {
        handleSubmit,
        register,
        formState: { isValid, errors },
    } = useForm({
        resolver: yupResolver(validator),
        mode: "onChange",
    });

    const navigate = useNavigate();
    const { signIn } = useAuth();

    const [isSave, setIsSave] = useState(false);

    const handleLogin = ({ email, password }) => {
        setIsSave(true);

        signIn(email, password)
            .then(({ error, status, data }) => {
                let message = "";
                const redirect = {
                    admin: "/admin/home",
                    company: "/empresa/home",
                };

                if (error && status === 401) {
                    message = "Por favor, verifique se os dados estão corretos.";
                }

                if (error && status !== 401) {
                    message = "Erro no servidor, tente mais tarde.";
                }

                if (error) {
                    return toast.error(message, { autoClose: 5000 });
                }

                return navigate(redirect[data.user.type] ?? "/");
            })
            .finally(() => setIsSave(false));
    };

    return (
        <Card className={`${styles.card_container}`}>
            <Card.Body className="col-12 p-2 py-4 p-md-5 p-sm-5">
                <Form onSubmit={handleSubmit(handleLogin)} className="col-auto">
                    <Form.Group className={`col-10 mx-auto ${styles.form_group_login}`}>
                        <Label text="LOGIN" />

                        <Input
                            type="email"
                            placeholder="Digite seu e-mail"
                            iconLeft="user"
                            aria-label="Digite seu e-mail"
                            {...register("email")}
                        />

                        {errors.email && <Form.Text className="text_error">{errors.email?.message}</Form.Text>}
                    </Form.Group>

                    <Form.Group className={`col-10 mx-auto ${styles.form_group_password}`}>
                        <Label text="SENHA" />

                        <Input
                            type="password"
                            placeholder="Digite aqui sua senha"
                            iconLeft="key"
                            iconEye
                            aria-label="Digite aqui sua senha"
                            {...register("password")}
                        />

                        {errors.password && (
                            <Form.Text className="text_error">{errors.password?.message}</Form.Text>
                        )}
                    </Form.Group>

                    <Form.Group className="col-10 mx-auto mb-4 p-0">
                        <Col className="d-grid gap-2">
                            <Button type="submit" disabled={!isValid || isSave}>
                                {!isSave ? (
                                    "Entrar"
                                ) : (
                                    <Spinner className="loading_spinner" animation="border" />
                                )}
                            </Button>
                        </Col>
                    </Form.Group>
                </Form>

                <div className="col-12 text-center">
                    <Link text="Esqueceu sua senha?" to="/recuperacao-senha" />
                </div>
            </Card.Body>
        </Card>
    );
}
