/* -----------  RESOURCES ----------- */
import { forwardRef, memo } from "react";
import { MapContainer, TileLayer } from "react-leaflet";

/* ----------- CONTRACTS ----------- */
import { MapCustomProps } from "contracts/Admin";

/* -----------  STYLES ----------- */
import styles from "components/admin/MapCustom/styles.module.scss";

const MapCustom = forwardRef(({ children, ...props }: MapCustomProps, ref?: any) => {
    const center: [number, number] = [-22.59372606, -48.515625];
    delete props?.ref;

    return (
        <MapContainer
            className={styles.map_container}
            center={props.center ?? center}
            zoom={13}
            ref={ref}
            {...props}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {children}
        </MapContainer>
    );
});

export default memo(MapCustom);
