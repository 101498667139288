/* ----------- RESOURCES ----------- */
import { forwardRef, Ref } from "react"

/* ----------- CONTRACTS ----------- */
import { SwitchSimplesProps } from "contracts/Admin"

/* ----------- COMPONENTS ----------- */
import FormCheck from 'react-bootstrap/FormCheck'

/* ----------- STYLES ----------- */
import styles from 'components/admin/SwitchSimples/styles.module.scss'

const SwitchSimples = forwardRef((
    {
        type,
        children,
        checked=false,
        ...props
    }: SwitchSimplesProps,
    ref: Ref<any>
) => {
    return (
        <FormCheck
            ref={ref}
            className={`${styles.switch_simples_container}`}
            type='switch'
            id='switch-admin'
            defaultChecked={checked}
            {...props}
        />
    )
})

export default SwitchSimples