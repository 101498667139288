/* ----------- RESOURCES ----------- */
import { Navigate, Outlet } from "react-router-dom";
import { menusNavbar } from "constants/admin-menus";

/* ----------- CONTRACTS ----------- */
import { useAuth } from 'providers/AuthProvider';

/* ----------- COMPONENTS ----------- */
import Navbar from "components/Navbar";
import { Col } from "react-bootstrap";

export default function AdminLayout() {
    const { token, typeUser } = useAuth();

    if (!token || typeUser !== 'admin') {
        return <Navigate to='/' replace />
    }

    return (
        <Col className="col-12 h-auto m-0 p-0">
            <Navbar menus={menusNavbar} />

            <Outlet />
        </Col>
    );
}
