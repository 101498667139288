/* ----------- RESOURCES ----------- */
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

/* ----------- LIBS ----------- */
import moment from "moment";
import { toast } from "react-toastify";

/* ----------- UTILS ----------- */
import { useFetch } from "hooks/useFetch";
import { useForm } from "hooks/useForm";
import { useDebounce } from "hooks/useDebounce";
import { useMultipleSelect } from "hooks/useMultipleSelect";
import benefitService from "services/benefitService";

/* ----------- UTILS ----------- */
import cardService from "services/cardService";

/* ----------- ICONS ----------- */
import ImageIcon from "assets/icons/image-unknown.svg";
import CalendarIcon from "assets/icons/calendar.svg";

/* ----------- COMPONENTS ----------- */
import { Col, Form } from "react-bootstrap";
import Image from "components/admin/Image";
import Actions from "components/admin/Actions";
import ButtonIcon from "components/ButtonIcon";
import DataTable from "components/admin/DataTable";
import loadingDataTable from "components/admin/LoadingDataTable";
import Label from "components/admin/Label";
import Input from "components/admin/Input";
import Select from "components/admin/Select";
import MultipleSelect from "components/admin/MultipleSelect";
import Badge from "components/admin/Badge";
import Switch from "components/admin/Switch";
import Reloading from "components/Reloading";

/* -----------  ----------- */
import { FormFilters, BenefitClubModel, Card, Establishments } from "./types";

/* ----------- STYLES ----------- */
import styles from "pages/admin/benefitClub/styles.module.scss";

const breadcrumbs = [
    {
        label: "Início",
        path: "/admin/home",
    },
    {
        label: "Clube de benefícios",
        path: "/admin/clube-beneficios",
    },
];

const status = [
    { key: "todos", item: "Todos" },
    { key: "ativo", item: "Ativo" },
    { key: "inativo", item: "Inativo" },
];

const form = {
    page: 1,
    perPage: 10,
    title: "",
    active: "todos",
};

function establishmentsBadge(e: Establishments[]) {
    const badges = e.slice(0, 2).map((item) => (
        <Badge key={`establishment-${item.id}`} pill>
            {item.name}
        </Badge>
    ));

    if (e.length > 2) {
        badges.push(
            <Badge key={`establishment-item-infinity-${Math.random() + 2}`} pill>
                ...
            </Badge>
        );
    }

    return badges;
}

function cardBadge(e: Card[]) {
    const badges = e.slice(0, 2).map((item) => (
        <Badge key={`card-${item.id}`} pill>
            {item.name}
        </Badge>
    ));

    if (e.length > 2) {
        badges.push(
            <Badge key={`card-item-infinity-${Math.random() + 3}`} pill>
                ...
            </Badge>
        );
    }

    return badges;
}

function formatCardMultipleSelect(cards: Card[]) {
    return cards?.map((card) => ({
        key: card.id,
        checked: false,
        item: card.name,
    }));
}

export default function BenefitClub() {
    const [formFilters, updateFormFilters] = useForm<FormFilters>(form);
    const [itemIdEditable, setItemIdEditable] = useState<number | null>(null);

    const navigate = useNavigate();
    const debounce = useDebounce();
    const debounceClearFilter = useDebounce();

    const [cards, selectedCards, updateCards] = useMultipleSelect([], true);

    const active =
        formFilters.active !== "todos" ? (formFilters.active === "ativo" ? true : false) : null;

    const { data, error, isValidating, mutate } = useFetch(
        `benefit?page=${formFilters.page}&perPage=${formFilters.perPage}`,
        {
            params: {
                include: "establishments,cards",
                "filter[title]": formFilters.title,
                "filter[active]": active,
                "filter[cards_id]": cards
                    ?.filter((x) => x.checked)
                    ?.map((x) => x.key)
                    ?.join(","),
            },
        }
    );

    const isLoadingBenefit = !data && !error;
    const isReloadingBenefit = data && isValidating;
    const isEmptyBenefit = !data || !data?.data || error;

    const fields = [
        <td style={{ width: "50px" }}>
            <Image src={ImageIcon} className="d-block mx-auto" alt="Uma imagem illustrative em ícone" fluid />
        </td>,
        "Título do benefício",
        "Inicio da validade",
        "Fim da validade",
        "Estabelecimento",
        "Cartões",
        "Status",
    ];

    const filters = [
        <Form.Group className="col-11 col-lg-5 mx-auto mx-lg-0" key="filter-search">
            <Label text="Pesquisar" />

            <Input
                type="search"
                iconRight="search"
                placeholder="Digite aqui sua pesquisa"
                aria-label="Digite aqui sua pesquisa"
                value={formFilters.title}
                onChange={handleFilterSearch}
            />
        </Form.Group>,

        <Form.Group className="col-11 col-lg-3 mx-auto mx-lg-0" key="filter-status">
            <Label text="Status" />

            <Select
                placeholder="Selecione o status"
                aria-label="Selecione o status"
                items={status}
                value={formFilters.active}
                onChange={(key) => updateFormFilters("active", key)}
            />
        </Form.Group>,

        <Form.Group className="col-11 col-lg-4 p-0 mx-auto mx-lg-0" key="filter-card">
            <Label text="Cartão" />

            <MultipleSelect placeholder={selectCardPlaceHolder()} items={cards} onChange={selectedCards} />
        </Form.Group>,
    ];

    function handleFilterSearch(event: ChangeEvent<HTMLInputElement>) {
        const title = event.target.value;

        updateFormFilters("title", title);

        debounce(() => {
            updateFormFilters("page", 1);
            mutate();
        }, 500)();
    }

    function handleClearFilters() {
        updateFormFilters(["title", "active", "page"], ["", "todos", 1]);
        selectedCards("none");
        debounceClearFilter(() => mutate(), 20)();
    }

    function selectCardPlaceHolder() {
        let showSelectedCard = cards
            .filter(({ key, checked }) => checked && key !== "all")
            .slice(0, 2)
            .map(({ key, item }, i) => (
                <Badge key={`badge-card-${i}`} onRemoveBadge={() => selectedCards(key)} pill iconClosed>
                    {" "}
                    {item}
                </Badge>
            ));

        showSelectedCard.length > 2 &&
            showSelectedCard.push(
                <Badge key="badge-card-none" pill>
                    ...
                </Badge>
            );

        return showSelectedCard.length > 0 ? showSelectedCard : "Selecione o(s) cartão(ões)";
    }

    const listBeneficies = useMemo(() => {
        function handleActive(id: number, active: boolean) {
            benefitService
                .update(id, { active })
                .then(() => toast.success("Status do benefício atualizado"))
                .catch(() =>
                    toast.error("Não foi possível atualizar o status do benefício", {
                        autoClose: 3000,
                    })
                );
        }

        return data?.data?.map((item: BenefitClubModel) => (
            <tr key={`benefit-club-${item.id}`} data-id={item.id}>
                <td>
                    <Col className={styles.image_thumbnail}>
                        <Image src={item.picture_url} alt="Imagem do benefício" fluid thumbnail />
                    </Col>
                </td>

                <td className={styles.table_title}>{item.title}</td>

                <td>
                    <p className={styles.datetime}>
                        <img src={CalendarIcon} alt="Calendar icon" className={styles.tableIcon} />{" "}
                        {moment(item.start_date).format("DD/MM/YYYY")}
                    </p>
                </td>
                <td>
                    <p className={styles.datetime}>
                        <img src={CalendarIcon} alt="Calendar icon" className={styles.tableIcon} />{" "}
                        {moment(item.end_date).format("DD/MM/YYYY")}
                    </p>
                </td>
                <td>
                    <Col className="d-flex gap-1">{establishmentsBadge(item.establishments)}</Col>
                </td>
                <td>
                    <Col className="d-flex gap-1">{cardBadge(item.cards)}</Col>
                </td>
                <td>
                    <Switch
                        className="mx-auto"
                        label={["Ativo", "Inativo"]}
                        checked={item.active}
                        onChange={(active) => handleActive(item.id, active)}
                    />
                </td>
            </tr>
        ));
    }, [data]);

    useEffect(() => {
        cardService
            .list()
            .then(({ data: cardsList }) =>
                updateCards([...cards, ...formatCardMultipleSelect(cardsList ?? [])])
        );
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Actions
                title="Clube de Benefícios"
                breadcrumb={breadcrumbs}
                actionButtons={
                    <>
                        {itemIdEditable && (
                            <Col className="col-auto col-xl-4  d-grid">
                                <ButtonIcon
                                    icon="edit-action"
                                    onClick={() => navigate(`editar-beneficio/${itemIdEditable}`)}
                                >
                                    Editar Clube de Benefícios
                                </ButtonIcon>
                            </Col>
                        )}

                        <Col className="col-auto col-xl-4  d-grid">
                            <ButtonIcon icon="add-action" onClick={() => navigate("novo-beneficio")}>
                                Novo Clube de Benefícios
                            </ButtonIcon>
                        </Col>
                    </>
                }
            />

            <DataTable
                filters={filters}
                fields={fields}
                currentPage={formFilters.page}
                perPage={formFilters.perPage}
                total={data?.total}
                onPaginate={(p) => updateFormFilters("page", p)}
                onPerPage={(p) => updateFormFilters("perPage", p)}
                onSearch={() => mutate()}
                onSelectLine={(id) => setItemIdEditable(id)}
                onClearFilters={handleClearFilters}
                data={
                    isLoadingBenefit
                        ? loadingDataTable(fields.length, formFilters.perPage)
                        : isEmptyBenefit
                            ? []
                            : listBeneficies
                }
            />

            {/* RELOADING SPINNER */}
            {isReloadingBenefit && <Reloading />}
            {/* ----------------- */}
        </>
    );
}
