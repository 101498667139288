/* ----------- RESOURCES ----------- */
import { useReducer } from "react";

interface FormProperty<P = any> {
    field: string | string[];
    value?: P | P;
}

function reducer(state: any, property: FormProperty) {
    const newState = { ...state };

    if (Array.isArray(property.field) && Array.isArray(property.value)) {
        property.field.forEach((field, indice) => (newState[field] = property.value[indice]));

        return newState;
    }

    newState[property.field as string] = property.value;

    return newState;
}

export function useForm<T = any>(initialState: T): [T, <K extends keyof T>(field: K | K[], value: any | any[]) => void] {
    const [state, dispatch] = useReducer<(state: T, property: FormProperty<T>) => T>(reducer, initialState);

    function handleInput<K extends keyof T>(field: K | K[], value: any | any[]) {
        dispatch({ field: field as string, value });
    }

    return [state, handleInput];
}
