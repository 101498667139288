/* ----------- RESOURCES ----------- */
import { Link } from "react-router-dom";

/* ----------- CONTRACTS ----------- */
import { LinkProps } from "contracts/Auth";

/* ----------- STYLES ----------- */
import styles from "components/auth/Link/styles.module.scss";

export default function LinkAuth({ text, back, to = "", className = "", ...props }: LinkProps) {
    if (!to && !back) throw new Error('LinkAuth: "to" or "rollback" is required');

    function handleLinkClick(event) {
        if (back) {
            event.preventDefault();
            window.history.back();
        }
    }

    return (
        <Link className={`${styles.link} ${className}`} to={to} onClick={handleLinkClick} {...props}>
            {text}
        </Link>
    );
}
