/* ----------- RESOURCES ----------- */
import { Link } from 'react-router-dom'

/* ----------- CONTRACTS ----------- */
import { ActionsProps } from 'contracts/Admin'

/* ----------- COMPONENTS ----------- */
import Col from 'react-bootstrap/Col'

/* ----------- STYLES ----------- */
import styles from 'components/admin/Actions/styles.module.scss'

export default function Actions({ breadcrumb, title, actionButtons, children, className='', ...props }: ActionsProps) {

    return (
        <Col
            className={`${styles.action_container} ${className} col-12 py-4 px-3 pb-3`}
            {...props}
        >
            <Col
                className={`${styles.action_sub_container} d-flex justify-content-between align-items-center`}
            >
                <Col className="col-6">
                    <ul className={`${styles.breadcrumb} d-flex`}>
                        {breadcrumb.map(({ label, path }, i) => (
                            <li key={path} className={styles.breadcrumb_item}>
                                {i !== 0 ? (
                                    <span className={styles.breadcrumb_span}>
                                        {" "}
                                        {">"}{" "}
                                    </span>
                                ) : (
                                    ""
                                )}
                                <Link
                                    to={path}
                                    className={`${styles.breadcrumb_link} text-link`}
                                >
                                    {label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </Col>

                <Col className="col-6 d-flex gap-2 justify-content-end">
                    {actionButtons || children || ""}
                </Col>
            </Col>

            {title && <h1 className={styles.title}>{title}</h1>}
        </Col>
    );
}