/* ----------- RESOURCES ----------- */
import { Navigate, Outlet, } from 'react-router-dom';

/* ----------- CONTRACTS ----------- */
import { useAuth } from 'providers/AuthProvider';

/* ----------- COMPONENTS ----------- */
import styles from 'layouts/auth/styles.module.scss'
import { Col } from 'react-bootstrap'

/* ----------- COMPONENTS ----------- */

export default function AuthLayout() {
    const { token, typeUser } = useAuth();

    if (token) {
        return <Navigate to={typeUser === 'admin' ? '/admin/home' : '/empresa/home'} replace />
    }

    return (
        <Col className={`col-12 ${styles.container} d-flex flex-row justify-content-center align-items-center p-0 m-0`}>
            <Outlet />
        </Col>
    );
}