/* ----------- RESOURCES ----------- */
import { useState } from "react";

/* ----------- CONTRACTS ----------- */
import { SwitchProps } from "contracts/Admin";

/* ----------- COMPONENTS ----------- */
import Col from "react-bootstrap/Col";

/* ----------- STYLES ----------- */
import styles from "components/admin/Switch/styles.module.scss";

export default function Switch({
    label,
    checked = false,
    className = "",
    disabled = false,
    onChange = null,
    ...props
}: SwitchProps) {
    const [isChecked, setIsChecked] = useState(checked);

    const setLabel = () => (Array.isArray(label) ? (isChecked ? label[0] : label[1]) : label);

    function handleChange() {
        onChange && onChange(!isChecked);
        setIsChecked((ck) => !ck);
    }

    return (
        <Col className={`${styles.switch_container} ${isChecked ? styles.active : ""} ${className}`} {...props}>
            <input
                type="checkbox"
                className={styles.switch_check}
                checked={isChecked}
                disabled={disabled}
                onChange={handleChange}
            />

            <div className={`${styles.switch} ${isChecked ? styles.active : styles.inactive}`}></div>

            {
                <label
                    htmlFor="switchCustom"
                    className={`${styles.switch_label} ${isChecked ? styles.active : styles.inactive}`}
                >
                    {setLabel()}
                </label>
            }
        </Col>
    );
}
