/* ----------- RESOURCES ----------- */

/* ----------- COMPONENTS ----------- */
import { Col, Spinner } from 'react-bootstrap';


/* ----------- STYLES ----------- */
import styles from 'components/Reloading/styles.module.scss'


export default function Reloading() {
    return (
        <Col className={`${styles.spinner_container} p-2`}>
            <Spinner className={styles.custom_spinner} animation='border' role='status' />
        </Col>
    );
}