import axios from "axios";
import { keyToken, ignoreUrl } from "constants/auth";

const durationMinutes = 30;

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: durationMinutes * 60 * 1000,
    withCredentials: false,
    headers: {
        "Content-type": "application/json; charset=utf-8",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Cross-Domain": true,
    },
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem(keyToken);

    if (token && !ignoreUrl.includes(window.location.pathname)) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

export default api;
