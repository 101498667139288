/* ----------- RESOURCES ----------- */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import moment from "moment";

import { formatErrors400 } from "utils/formatErrors";
import { useMultipleSelect } from "hooks/useMultipleSelect";
import notificationService from "services/notificationService";
import messageService from "services/messageService";
import targetService from "services/targetService";

/* ----------- CONSTANTS ----------- */
import { SelectItems } from "contracts/Admin";

/* ----------- COMPONENTS ----------- */
import Actions from "components/admin/Actions";
import ButtonIcon from "components/ButtonIcon";
import Label from "components/admin/Label";
import Input from "components/admin/Input";
import InputDate from "components/admin/InputDate";
import Select from "components/admin/Select";
import MultipleSelect from "components/admin/MultipleSelect";
import Textarea from "components/admin/Textarea";
import Badge from "components/admin/Badge";
import Modal from "components/admin/Modal";
import { Col, Card as CardBC, Form } from "react-bootstrap";

/* ----------- STYLES ----------- */
import styles from "pages/admin/notifications/newShot/styles.module.scss";

interface Location {
    id: number;
    name: string;
    lat: string;
    lng: string;
    radius_km: number;
}

interface Card {
    id: number;
    name: string;
}

interface TargetAudience {
    id: number;
    name: string;
    created_at: string;
    locations: Location[];
    cards: Card[];
}

interface Message {
    id: number;
    title: string;
    message: string;
    created_at: string;
}


const breadcrumbs = [
    {
        label: "Início",
        path: "/admin/home",
    },
    {
        label: "Gestão de notificações",
        path: "/admin/notificacoes?tab=notifications",
    },
    {
        label: "Novo Disparo",
        path: "/admin/notificacoes/novo-disparo",
    },
];

const formatMessageSelect = (msgs: Message[]) =>
    msgs.map(({ id, message }) => ({
        key: id,
        item: `${message.substring(0, 300)}...`,
    }));

const formatTargetMultipleSelect = (tgs: TargetAudience[]) =>
    tgs.map(({ id, name }) => ({
        key: id,
        checked: false,
        item: name,
    }));

const getHour = () => moment(new Date()).format("HH:mm");
const getDate = () => moment(new Date()).format("DD/MM/YYYY");

const hasItemSelected = ({ checked, key }) => checked && key !== "all";

const validator = yup.object({
    message_id: yup.string().required("A mensagem é obrigatória"),
    scheduled_at: yup.string().required("A data de publicação é obrigatório"),
    target_audiences: yup
        .array()
        .of(yup.number())
        .required("O Público Alvo é obrigatório")
        .min(1, "Selecione pelo menos 1 Público Alvo"),
});
export default function NewShot() {
    const navigation = useNavigate();
    const {
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        setError,
    } = useForm({
        resolver: yupResolver(validator),
        mode: "onChange",
    });

    const [isSave, setIsSave] = useState(false);
    const [messages, setMessages] = useState<SelectItems[]>([]);
    const [showModalAddMessage, setShowModalAddMessage] = useState(false);
    const [showModalAddPublication, setShowModalAddPublication] = useState(false);
    const [datePublication, setDatePublication] = useState(null);
    const [hourPublication, setHourPublication] = useState(null);

    const [targets, selectTarget, setTargets] = useMultipleSelect([], true);
    const [locations, selectLocation] = useMultipleSelect([], true);
    const [cards, selectCard] = useMultipleSelect([], true);

    const targetsSelected = targets.filter(hasItemSelected);

    function handleCloseModalAddMessage() {
        setShowModalAddMessage(false);
    }

    function handleCloseModalAddPublication() {
        setShowModalAddPublication(false);
    }

    function handleChangeMessage(value) {
        setValue("message_id", value);
    }

    function handleStoreNotification(data) {
        setIsSave(true);

        notificationService
            .store(data)
            .then(() => {
                toast.success("Notificação salva com sucesso!", { autoClose: 3000 });
                setTimeout(() => navigation("/admin/notificacoes?tab=notifications"), 1000);
            })
            .catch((err) => {
                if (err.status === 400) {
                    formatErrors400(err.data?.errors ?? [], (e) => {
                        setError(e.name, {
                            type: "manual",
                            message: e.message,
                        });
                    });
                    return toast.error("Verifique os dados e tente novamente", { autoClose: 3000 });
                }

                toast.error("Ocorreu um erro ao criar notificação", { autoClose: 3000 });
            })
            .finally(() => setIsSave(false));
    }

    function handleChangeDate(field: 'date' | 'hour') {
        return (dateOrHour: any) => {
            if (field === 'date') {
                setDatePublication(dateOrHour);

                setValue(
                    "scheduled_at",
                    moment(
                        `${moment(dateOrHour).format("YYYY-MM-DD")} ${moment(hourPublication).format(
                            "HH:mm:ss"
                        )}`
                    ).toISOString(true),
                    { shouldValidate: true }
                )
            } else {
                setHourPublication(dateOrHour);

                setValue(
                    "scheduled_at",
                    moment(
                        `${moment(datePublication).format("YYYY-MM-DD")} ${moment(dateOrHour).format(
                            "HH:mm:ss"
                        )}`
                    ).toISOString(true),
                    { shouldValidate: true }
                )
            }

        }
    }

    useEffect(() => {
        messageService
            .list({ params: { sort: "title" } })
            .then(({ data: messagesList }) => setMessages(formatMessageSelect(messagesList ?? [])))
            .catch(() => toast.error("Ocorreu um erro ao carregar as mensagens", { autoClose: 3000 }));

        targetService
            .list({ params: { sort: "name" } })
            .then(({ data: targetAudiencesList }) => setTargets([...targets, ...formatTargetMultipleSelect(targetAudiencesList ?? [])]))
            .catch(() => toast.error("Ocorreu um erro ao carregar os públicos alvo", { autoClose: 3000 }));
    }, []);

    useEffect(() => {
        const targetsSelected = targets.filter(hasItemSelected).map(({ key }) => key);

        setValue("target_audiences", targetsSelected, { shouldValidate: true });
    }, [targets]);

    return (
        <>
            <Actions
                title="Novo Disparo"
                breadcrumb={breadcrumbs}
                actionButtons={
                    <>
                        <Col className="col-auto col-lg-3 d-grid">
                            <ButtonIcon
                                icon="removeorcancel-action"
                                className={styles.button_cancel}
                                onClick={() => navigation("/admin/notificacoes?tab=notifications")}
                            >
                                Cancelar
                            </ButtonIcon>
                        </Col>

                        <Col className="col-auto col-lg-3 d-grid">
                            <ButtonIcon
                                icon={isSave ? "loading" : "save-action"}
                                className={styles.button_save}
                                disabled={!isValid || isSave}
                                onClick={handleSubmit(handleStoreNotification)}
                            >
                                {isSave ? "Salvando" : "Salvar"}
                            </ButtonIcon>
                        </Col>
                    </>
                }
            />

            <Form onSubmit={(e) => e.preventDefault()} className="col-11 col-lg-7 mx-auto">
                <CardBC className={`${styles.form_card} mb-3`}>
                    <CardBC.Body>
                        <Form.Group>
                            <Label text="Mensagem" />

                            <p className={styles.text_info}>
                                Selecione a mensagem para disparar aos usuários nessa notificação
                            </p>

                            <Select
                                placeholder="Procure aqui uma mensagem"
                                items={messages}
                                onChange={handleChangeMessage}
                            />

                            {errors.message_id && (
                                <Form.Text className="text_error">{errors.message_id?.message}</Form.Text>
                            )}
                        </Form.Group>
                    </CardBC.Body>
                </CardBC>

                <CardBC className={`${styles.form_card} mb-3`}>
                    <CardBC.Body>
                        <Form.Group>
                            <Label text="Público Alvo" />

                            <p className={styles.text_info}>
                                Selecione o(s) público(s) alvo que irão receber essa notificação
                            </p>

                            <MultipleSelect
                                placeholder="Procure aqui público(s) alvo"
                                aria-label="Procure aqui público(s) alvo"
                                items={targets}
                                onChange={selectTarget}
                            />

                            {errors.target_audiences && (
                                <Form.Text className="text_error">
                                    {errors.target_audiences?.message}
                                </Form.Text>
                            )}
                        </Form.Group>

                        <Col className="mt-4">
                            <Label
                                text="Público(s) alvo selecionado(s):"
                                aria-label="Público(s) Alvo(s) Selecionado(s)"
                            />

                            <Col className="d-flex gap-2 flex-wrap">
                                {targetsSelected.length <= 0 ? (
                                    <p className={styles.text_info}>
                                        Nenhum cartão selecionado. Selecione cartões para serem listados aqui
                                    </p>
                                ) : (
                                        targetsSelected.slice(0, 10).map(({ key, item: name }) => (
                                        <Badge
                                            key={key}
                                            onRemoveBadge={() => selectTarget(key)}
                                            iconClosed
                                            pill
                                        >
                                            {name}
                                        </Badge>
                                    ))
                                )}

                                {targetsSelected.length > 10 && (
                                    <Badge key={`badge-establishment-more`} pill>
                                        ...
                                    </Badge>
                                )}
                            </Col>
                        </Col>
                    </CardBC.Body>
                </CardBC>

                <CardBC className={`${styles.form_card} mb-3`}>
                    <CardBC.Body>
                        <Form.Group className="row col-6 p-0 m-0">
                            <Label text="Data do disparo da notificação" />

                            <Col className="col-7 p-0">
                                <InputDate
                                    className={styles.input_datetime_left}
                                    iconLeft="calendar"
                                    placeholder={getDate()}
                                    selected={datePublication}
                                    onChange={handleChangeDate('date')}
                                    dateFormat="dd/MM/yyyy"
                                    selectsStart
                                    startDate={new Date()}
                                    minDate={new Date()}
                                />
                            </Col>

                            <Col className="col-5 p-0">
                                <InputDate
                                    className={styles.input_datetime_right}
                                    style={{ borderLeft: "none !important" }}
                                    iconLeft="clock"
                                    placeholder={getHour()}
                                    selected={hourPublication}
                                    onChange={handleChangeDate('hour')}
                                    mask="99:99"
                                    showTimeSelect
                                    showTimeSelectOnly
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm aa"
                                    timeIntervals={1}
                                />
                            </Col>

                            {!datePublication && (
                                <Form.Text className="text_error">
                                    Selecione uma data de disparo da notificação
                                </Form.Text>
                            )}

                            {!hourPublication && (
                                <Form.Text className="text_error">
                                    Selecione uma hora de disparo da notificação
                                </Form.Text>
                            )}
                        </Form.Group>
                    </CardBC.Body>
                </CardBC>
            </Form>

            {/* Modal da criação de mensagens */}
            <Modal
                show={showModalAddMessage}
                title="Nova Mensagem"
                size="lg"
                onClose={handleCloseModalAddMessage}
                buttons={
                    <>
                        <Col className="col-2  d-grid">
                            <ButtonIcon icon="removeorcancel-action" className={styles.button_cancel}>
                                Cancelar
                            </ButtonIcon>
                        </Col>

                        <Col className="col-2  d-grid">
                            <ButtonIcon icon="save-action" className={styles.button_save}>
                                Salvar
                            </ButtonIcon>
                        </Col>
                    </>
                }
            >
                <Form className="mb-3">
                    <CardBC className={`${styles.form_card} mb-3`}>
                        <CardBC.Body>
                            <Form.Group>
                                <Label text="Título da notificação" />

                                <Input placeholder="Digite aqui o título da notificação" />

                                <p className={styles.text_info_title}>Caracteres 0/40</p>
                            </Form.Group>
                        </CardBC.Body>
                    </CardBC>

                    <CardBC className={styles.form_card}>
                        <CardBC.Body>
                            <Form.Group>
                                <Label text="Mensagem" />

                                <p className={styles.text_info}>
                                    Digite aqui a mensagem da notificação. Lembre-se do limite de caracteres.
                                </p>

                                <Textarea
                                    placeholder="Digite aqui a mensagem da notificação"
                                    aria-label="Digite aqui a mensagem da notificação"
                                ></Textarea>
                                <p className={styles.text_info_title}>Caracteres 0/200</p>
                            </Form.Group>
                        </CardBC.Body>
                    </CardBC>
                </Form>
            </Modal>
            {/* ---------------------------- */}

            {/* Modal da criação de Público alvo */}
            <Modal
                show={showModalAddPublication}
                title="Novo Público Alvo"
                size="lg"
                onClose={handleCloseModalAddPublication}
                buttons={
                    <>
                        <Col className="col-2  d-grid">
                            <ButtonIcon icon="removeorcancel-action" className={styles.button_cancel}>
                                Cancelar
                            </ButtonIcon>
                        </Col>

                        <Col className="col-2  d-grid">
                            <ButtonIcon icon="save-action" className={styles.button_save}>
                                Salvar
                            </ButtonIcon>
                        </Col>
                    </>
                }
            >
                <Form className={`mb-3`}>
                    <CardBC className={`${styles.form_card} mb-3`}>
                        <CardBC.Body>
                            <Form.Group>
                                <Label text="Título do Público Alvo" />
                                <Input
                                    placeholder="Digite aqui o título do público alvo"
                                    aria-label="Digite aqui o título do público alvo"
                                />
                            </Form.Group>
                        </CardBC.Body>
                    </CardBC>

                    <CardBC className={`${styles.form_card} mb-3`}>
                        <CardBC.Body>
                            <Form.Group>
                                <Label text="Localização" />
                                <p className={styles.text_info}>
                                    Selecione a(s) localização(oês) para onde deseja disparar a notificação
                                </p>
                                <MultipleSelect
                                    placeholder="Procure aqui uma ou mais localização(ões)"
                                    items={locations}
                                    onChange={selectLocation}
                                />
                            </Form.Group>
                            <Col className="mt-4">
                                <Label text="Localização(ões) selecionada(s)" />
                                <Col className="d-flex gap-2 flex-wrap">
                                    {locations.filter((item) => item.checked && item.key !== "all").length ===
                                        0 ? (
                                        <p className={styles.text_info_title}>
                                            Nenhuma localização selecionada
                                        </p>
                                    ) : (
                                        locations
                                            .filter((item) => item.checked && item.key !== "all")
                                            .map(({ key, item }) => (
                                                <Badge
                                                    key={key}
                                                    onRemoveBadge={() => selectLocation(key)}
                                                    iconClosed
                                                    pill
                                                >
                                                    {item}
                                                </Badge>
                                            ))
                                    )}
                                </Col>
                            </Col>
                        </CardBC.Body>
                    </CardBC>

                    <CardBC className={styles.form_card}>
                        <CardBC.Body>
                            <Form.Group>
                                <Label text="Cartões" />
                                <p className={styles.text_info}>Selecione os cartões</p>
                                <MultipleSelect
                                    placeholder="Procure aqui um ou mais cartão(ões)"
                                    items={cards}
                                    onChange={selectCard}
                                />
                            </Form.Group>
                            <Col className="mt-4">
                                <Label text="Cartão(ões) selecionado(s):" />
                                <Col className="d-flex gap-2 flex-wrap">
                                    {cards.filter((item) => item.checked && item.key !== "all").length ===
                                        0 ? (
                                        <p className={styles.text_info_title}>
                                            Nenhum cartão selecionado. Selecione cartões para serem listados
                                            aqui
                                        </p>
                                    ) : (
                                        cards
                                            .filter((item) => item.checked && item.key !== "all")
                                            .map(({ key, item }) => (
                                                <Badge
                                                    key={key}
                                                    onRemoveBadge={() => selectCard(key)}
                                                    iconClosed
                                                    pill
                                                >
                                                    {item}
                                                </Badge>
                                            ))
                                    )}
                                </Col>
                            </Col>
                        </CardBC.Body>
                    </CardBC>
                </Form>
            </Modal>
            {/* -------------------------------- */}
        </>
    );
}
