/* ----------- RESOURCES ----------- */
import { useState, cloneElement, useEffect, useRef } from "react";
import * as reactIs from "react-is";

import SortBothIcon from "assets/icons/sort-both.png";

/* ----------- CONTRACTS ----------- */
import { DataTableProps, PaginateItemProps } from "contracts/Admin";

/* ----------- COMPONENTS ----------- */
import ButtonIcon from "components/ButtonIcon";
import { Col, Accordion, Table, Pagination, FormSelect } from "react-bootstrap";

/* ----------- STYLES ----------- */
import styles from "components/admin/DataTable/styles.module.scss";
import { ReactElement } from "react";

const siblingsCount = 1;

function generatePagesArray(from: number, to: number) {
    return [...new Array(to - from)].map((_, i) => from + i + 1).filter((p) => p > 0);
}

function removeWordsWithAccent(words: string) {
    return words.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function PaginateItem({ number, active = false, className = "", ...props }: PaginateItemProps) {
    const class_active = active ? styles.active : "";

    return (
        <button
            className={`
                ${styles.paginate_item} 
                d-flex justify-content-center align-items-center
                ${className}
                ${class_active}
                `}
            {...props}
        >
            {number}
        </button>
    );
}

function PaginatePoints() {
    return (
        <span className={`${styles.paginate_options} d-flex justify-content-center py-1 border-0`}>...</span>
    );
}

export default function DataTable({
    title,
    filters,
    fields,
    data,
    currentPage,
    perPage = 10,
    total = 2,
    className = "",
    optionsLimit = [10, 25, 50],
    empty = null,
    onSearch = null,
    onClearFilters = null,
    onPaginate = null,
    onPerPage = null,
    onSelectLine = null,
    ...props
}: DataTableProps) {
    const [lineIndex, setLineIndex] = useState<number | null>(null);

    const tableRef = useRef<HTMLTableElement | null>(null);

    const pages = total % perPage !== 0 ? Math.floor(total / perPage) + 1 : Math.floor(total / perPage);

    let items = data.slice(0, perPage);

    empty = empty ? empty : <Col className={styles.table_empty}>Lista vazia</Col>;

    const previousPages =
        currentPage > 1 ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1) : [];

    const nextPages =
        currentPage < pages
            ? generatePagesArray(currentPage, Math.min(currentPage + siblingsCount, pages))
            : [];

    function handleChangeLimitItemsPage(perPage: number) {
        onPerPage && onPerPage(perPage);
    }

    function handleChangePaginate(page: number) {
        if (page === currentPage) return;

        onPaginate && onPaginate(page);
    }

    function handleClickLine(event: any, index: number) {
        setLineIndex(index);
        onSelectLine && onSelectLine(event?.currentTarget?.dataset?.id);
    }

    function handleNextPage() {
        if (currentPage >= total) return;

        onPaginate && onPaginate(currentPage + 1);
    }

    function handlePrevPage() {
        if (currentPage <= 1) return;

        onPaginate && onPaginate(currentPage - 1);
    }

    function classLine(index: number) {
        return lineIndex === index ? styles.item_selected : "";
    }

    useEffect(() => {
        function onClickDoc(event: any) {
            if (!tableRef.current || lineIndex === null || !onSelectLine) return;

            const doesContainsElement =
                !tableRef.current.querySelector("tbody").contains(event.target) ?? false;

            doesContainsElement && handleClickLine(null, null);
        }

        window.document.addEventListener("click", onClickDoc);

        return () => window.document.removeEventListener("click", onClickDoc);
        // eslint-disable-next-line
    }, [lineIndex]);

    return (
        <Col className={`mx-auto ${styles.container_dataTable} ${className}`} {...props}>
            <Accordion className="mb-3" defaultActiveKey="0">
                <Accordion.Item eventKey="0" className="border-0 rounded-0 rounded-top">
                    <Accordion.Header className={`accordion-custom ${styles.header}`}>
                        <h4 className={styles.header_title}>{title ?? "Filtros"}</h4>
                    </Accordion.Header>

                    <Accordion.Body className={`${styles.accordion_body} pb-3`}>
                        <Col className="col-12 d-flex flex-wrap justify-content-between">
                            <Col className="col-12 col-xl-9 d-flex flex-wrap justify-content-start gap-4 mb-4 mb-xl-0">
                                {filters}
                            </Col>

                            <Col className="col-12 col-lg-2 d-flex flex-column flex-lg-row justify-content-end align-items-end gap-2">
                                <Col
                                    className={`${styles.box_button_search} col-11 col-lg-7 mx-auto mx-lg-0 d-grid`}
                                >
                                    <ButtonIcon
                                        text="Limpar"
                                        icon="clear-action"
                                        aria-label="Pesquisar"
                                        onClick={() => onClearFilters && onClearFilters()}
                                    />
                                </Col>

                                <Col
                                    className={`${styles.box_button_search} col-11 col-lg-10 mx-auto mx-lg-0 d-grid`}
                                >
                                    <ButtonIcon
                                        text="Pesquisar"
                                        icon="search-action"
                                        aria-label="Pesquisar"
                                        onClick={() => onSearch && onSearch()}
                                    />
                                </Col>
                            </Col>
                        </Col>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <Table ref={tableRef} className={styles.table} responsive="lg" hover>
                <thead>
                    <tr>
                        {reactIs.isElement(fields)
                            ? fields
                            : fields.map((field, index) =>
                                reactIs.isElement(field) ? (
                                    cloneElement(field, {
                                        key: `td-head-${index}`,
                                    })
                                ) : (
                                    <td
                                        className={`datatable-td-${removeWordsWithAccent(field)}`}
                                            key={`td-head-${removeWordsWithAccent(field)}-${index}`}
                                        >
                                            {" "}
                                            <img
                                                src={SortBothIcon}
                                                alt="Sorted icon"
                                                className={styles.icon_table}
                                        />{" "}
                                        {field}{" "}
                                    </td>
                                )
                            )}
                    </tr>
                </thead>

                <tbody>
                    {items.length > 0 &&
                        items.map((line, index) =>
                            cloneElement(line as ReactElement, {
                                onClick: (event) => handleClickLine(event, index),
                                className: classLine(index),
                            })
                        )}
                </tbody>
            </Table>

            {items.length === 0 && empty}

            <Col className={`${styles.pagination_box} col-12 d-flex justify-content-end`}>
                <Col className="col-auto d-flex justify-content-center align-items-center">
                    <Col className={styles.select_box}>
                        <FormSelect
                            defaultValue={perPage}
                            className={styles.form_select}
                            onChange={(e) => handleChangeLimitItemsPage(parseInt(e.target.value))}
                        >
                            {optionsLimit.map((option, index) => (
                                <option key={`option-limit-${index}`} value={option}>
                                    {option}
                                </option>
                            ))}
                        </FormSelect>
                    </Col>

                    <Pagination className="mb-0">
                        {/* PREVIUS PAGES */}
                        <button
                            className={`${styles.paginate_options} d-flex justify-content-center align-items-center`}
                            onClick={handlePrevPage}
                            disabled={currentPage <= 1 || !items.length}
                        >
                            <p className={styles.paginate_arrow}> {"‹"} </p>
                        </button>

                        {/* FIRST PAGE */}
                        {currentPage > siblingsCount + 1 && (
                            <PaginateItem number={1} onClick={() => handleChangePaginate(1)} />
                        )}

                        {currentPage > 2 + siblingsCount && <PaginatePoints />}
                        {/* ------------ */}

                        {previousPages.length > 0 &&
                            previousPages.map((p) => (
                                <PaginateItem
                                    key={`btn-paginate-previous-item-${p}`}
                                    number={p}
                                    onClick={() => handleChangePaginate(p)}
                                />
                            ))}
                        {/* ------------ */}

                        {/* CURRENT PAGE */}
                        {items.length > 0 && <PaginateItem number={currentPage} active />}
                        {/* ------------ */}

                        {/* NEXT PAGES */}
                        {nextPages.length > 0 &&
                            nextPages.map((p) => (
                                <PaginateItem
                                    key={`btn-paginate-next-item-${p}`}
                                    number={p}
                                    onClick={() => handleChangePaginate(p)}
                                />
                            ))}

                        {/* LAST PAGE */}
                        {currentPage + siblingsCount < pages - 1 && <PaginatePoints />}

                        {currentPage < pages - 1 && (
                            <PaginateItem number={pages} onClick={() => handleChangePaginate(pages)} />
                        )}
                        {/* ------------ */}

                        <button
                            className={`${styles.paginate_options} d-flex justify-content-center align-items-center`}
                            onClick={handleNextPage}
                            disabled={currentPage === pages || !items.length}
                        >
                            <p className={styles.paginate_arrow}> {"›"} </p>
                        </button>
                        {/* ------------ */}
                    </Pagination>
                </Col>
            </Col>
        </Col>
    );
}
