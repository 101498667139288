/* ----------- RESOURCES ----------- */
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

/* ----------- LIBS ----------- */

/* ----------- UTILS ----------- */
import { useFetch } from "hooks/useFetch";
import { useForm } from "hooks/useForm";
import { useDebounce } from "hooks/useDebounce";

/* ----------- COMPONENTS ----------- */
import { Col, Form } from "react-bootstrap";
import Actions from "components/admin/Actions";
import DataTable from "components/admin/DataTable";
import ButtonIcon from "components/ButtonIcon";
import Input from "components/admin/Input";
import Label from "components/admin/Label";
import loadingDataTable from "components/admin/LoadingDataTable";
import Reloading from "components/Reloading";

/* ----------- CONTRACTS ----------- */
import { Paginate } from "contracts/Paginate";
import { FormFilters, ListLocationsProps, LocationModel } from "./types";

/* ----------- STYLES ----------- */
import styles from "pages/admin/location/styles.module.scss";

const breadcrumbs = [
    {
        label: "Início",
        path: "/admin/home",
    },
    {
        label: "Gestão de Localidades",
        path: "/admin/localidades",
    },
];

const fields = ["Nome", "Localização", "Raio"];

const form = {
    page: 1,
    perPage: 10,
    "name": "",
    "radius_km": "",
};

function listLocations(locations: Paginate<ListLocationsProps>) {
    return locations?.data?.map((l: LocationModel) => (
        <tr key={`location-${l.id}`} data-id={l.id}>
            <td className={styles.table_title}>{l.name}</td>
            <td className={styles.table_title}>{`${l.lat}, ${l.lng}`}</td>
            <td className={styles.table_title}>{`${l.radius_km} km`}</td>
        </tr>
    ));
}

export default function Location() {
    const [formFilters, updateFormFilters] = useForm<FormFilters>(form);
    const [itemIdEditable, setItemIdEditable] = useState<number | null>(null);

    const navigate = useNavigate();
    const debounce = useDebounce();
    const debounceClearFilter = useDebounce();

    const { data, error, isValidating, mutate } = useFetch(
        `location?page=${formFilters.page}&perPage=${formFilters.perPage}`,
        {
            params: {
                sort: "name",
                "filter[name]": formFilters.name,
                "filter[radius_km]": formFilters.radius_km,
            },
        }
    );

    const isLoadingLocation = !data && !error;
    const isReloadingLocation = data && isValidating;
    const isEmptyLocation = !data || !data?.data || error;

    const filters = [
        <Form.Group className="col-11 col-lg-5 mx-auto mx-lg-0" key="filter-search">
            <Label text="Pesquisar" />

            <Input
                type="search"
                iconRight="search"
                placeholder="Digite aqui sua pesquisa"
                aria-label="Digite aqui sua pesquisa"
                value={formFilters.name}
                onChange={handleFilterSearch}
            />
        </Form.Group>,

        <Form.Group className="col-11 col-lg-3 mx-auto mx-lg-0" key="filter-status">
            <Label text="Raio" />

            <Input
                placeholder="Buscar por raio de distância"
                aria-label="Selecione o status"
                value={formFilters.radius_km}
                onChange={handleFilterRadius}
            />
        </Form.Group>,
    ];

    function handleFilterSearch(event: ChangeEvent<HTMLInputElement>) {
        const name = event.target.value;
        updateFormFilters("name", name);

        debounce(() => {
            updateFormFilters("page", 1);
            mutate();
        }, 500)();
    }

    function handleFilterRadius(event: ChangeEvent<HTMLInputElement>) {
        updateFormFilters("radius_km", event.target.value?.replace(/[^\d.]/, ""));
    }

    function handleClearFilters() {
        updateFormFilters(["name", "radius_km", "page"], ["", "", 1]);
        debounceClearFilter(() => mutate(), 20)();
    }

    return (
        <>
            <Actions
                title="Gestão de Localidades"
                breadcrumb={breadcrumbs}
                actionButtons={
                    <>
                        {itemIdEditable && (
                            <Col className="col-auto col-xl-4 d-grid">
                                <ButtonIcon
                                    icon="edit-action"
                                    onClick={() => navigate(`editar-localizacao/${itemIdEditable}`)}
                                >
                                    Editar Localização
                                </ButtonIcon>
                            </Col>
                        )}

                        <Col className="col-auto col-xl-4  d-grid">
                            <ButtonIcon icon="add-action" onClick={() => navigate("nova-localizacao")}>
                                Nova Localização
                            </ButtonIcon>
                        </Col>
                    </>
                }
            />

            <DataTable
                filters={filters}
                fields={fields}
                currentPage={formFilters.page}
                perPage={formFilters.perPage}
                total={data?.total}
                onPaginate={(p) => updateFormFilters("page", p)}
                onPerPage={(p) => updateFormFilters("perPage", p)}
                onSelectLine={(id) => setItemIdEditable(id)}
                onSearch={() => mutate()}
                onClearFilters={handleClearFilters}
                data={
                    isLoadingLocation
                        ? loadingDataTable(fields.length, formFilters.perPage)
                        : isEmptyLocation
                            ? []
                            : listLocations(data)
                }
            />

            {/* RELOADING SPINNER */}
            {isReloadingLocation && <Reloading />}
            {/* ----------------- */}
        </>
    );
}
