/* ----------- RESOURCES ----------- */
import { ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

/* ----------- LIBS ----------- */

/* ----------- UTILS ----------- */
import { useFetch } from "hooks/useFetch";
import { useForm } from "hooks/useForm";
import { useDebounce } from "hooks/useDebounce";

/* ----------- COMPONENTS ----------- */
import { Col, Form } from "react-bootstrap";
import Actions from "components/admin/Actions";
import ButtonIcon from "components/ButtonIcon";
import DataTable from "components/admin/DataTable";
import Label from "components/admin/Label";
import Input from "components/admin/Input";
import Select from "components/admin/Select";
import Switch from "components/admin/Switch";
import Reloading from "components/Reloading";
import loadingDataTable from "components/admin/LoadingDataTable";

/* ----------- CONTRACTS ----------- */
import { Paginate } from "contracts/Paginate";
import { FormFilters, AdminModel } from "./types";

/* ----------- STYLES ----------- */
import styles from "pages/admin/users/styles.module.scss";

const breadcrumbs = [
    {
        label: "Início",
        path: "/admin/home",
    },
    {
        label: "Gestão de Usuários",
        path: "/admin/usuarios",
    },
];

const fields = ["Nome", "Email", "Status"];
const status = [
    { key: "todos", item: "Todos" },
    { key: "ativo", item: "Ativo" },
    { key: "inativo", item: "Inativo" },
];

const form = {
    page: 1,
    perPage: 10,
    "user_name": "",
    "active": "todos",
};

function listUsers(data: Paginate<AdminModel[]>) {
    return data?.data?.map((u: AdminModel) => (
        <tr key={u.id}>
            <td className={styles.table_title}>{u.user.name}</td>
            <td>{u.user.email}</td>
            <td>
                <Switch className="ml-auto" label={["Ativo", "Inativo"]} checked={u.active} disabled />
            </td>
        </tr>
    ));
}

export default function Users() {
    const [formFilters, updateFormFilters] = useForm<FormFilters>(form);

    const navigate = useNavigate();
    const debounce = useDebounce();
    const debounceClearFilter = useDebounce();

    const active = formFilters.active !== "todos"
        ? (formFilters.active === "ativo" ? true : false)
        : null;

    const { data, error, isValidating, mutate } = useFetch(
        `admin?page=${formFilters.page}&perPage=${formFilters.perPage}`,
        {
            params: {
                include: "user",
                "filter[user.name]": formFilters.user_name,
                "filter[active]": active,
            },
        }
    );

    const isLoadingAdmin = !data && !error;
    const isReloadingAdmin = data && isValidating;
    const isEmptyAdmin = !data || !data?.data || error;

    const filters = [
        <Form.Group className="col-11 col-lg-5 mx-auto mx-lg-0" key="filter-search">
            <Label text="Pesquisar" />

            <Input
                type="email"
                iconRight="search"
                placeholder="Pesquise aqui por usuários"
                aria-label="Pesquise aqui por usuários"
                value={formFilters.user_name}
                onChange={handleFilterSearch}
            />
        </Form.Group>,

        <Form.Group className="col-11 col-lg-3 mx-auto mx-lg-0" key="filter-status">
            <Label text="Status" />
            <Select
                placeholder="Selecione o status"
                aria-label="Selecione o status"
                items={status}
                value={formFilters.active}
                onChange={(key) => updateFormFilters("active", key)}
            />
        </Form.Group>,
    ];

    function handleFilterSearch(event: ChangeEvent<HTMLInputElement>) {
        const name = event.target.value;

        updateFormFilters("user_name", name);

        debounce(() => {
            updateFormFilters("page", 1);
            mutate();
        }, 500)();
    }

    function handleClearFilters() {
        updateFormFilters(["user_name", "active", "page"], ["", "todos", 1]);
        debounceClearFilter(() => mutate(), 20)();
    }

    return (
        <>
            <Actions
                title="Gestão de usuários"
                breadcrumb={breadcrumbs}
                actionButtons={
                    <Col className="col-auto col-lg-3  d-grid">
                        <ButtonIcon icon="add-action" onClick={() => navigate("adicionar")}>
                            Novo usuário
                        </ButtonIcon>
                    </Col>
                }
            />

            <DataTable
                filters={filters}
                fields={fields}
                currentPage={formFilters.page}
                perPage={formFilters.perPage}
                total={data?.total}
                onPaginate={(p) => updateFormFilters("page", p)}
                onPerPage={(p) => updateFormFilters("perPage", p)}
                onSearch={() => mutate()}
                onClearFilters={handleClearFilters}
                data={
                    isLoadingAdmin
                        ? loadingDataTable(fields.length, formFilters.perPage)
                        : isEmptyAdmin
                            ? []
                            : listUsers(data)
                }
            />

            {/* RELOADING SPINNER */}
            {isReloadingAdmin && <Reloading />}
            {/* ----------------- */}
        </>
    );
}
