/* ----------- RESOURCES  ----------- */
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

/* ----------- CONTEXT ----------- */
import { AuthProvider } from "providers/AuthProvider";

/* ----------- PAGES ----------- */
import AuthLayout from "layouts/auth";
import Login from "pages/auth/login";
import RecoverPassword from "pages/auth/recoverPassword";
import NewPassword from "pages/auth/newPassword";

/* ---------- PAGES COMPANY ---------- */
import CompanyLayout from "layouts/company";
import CompanyHome from "pages/company/home";
import CompanySubsidies from "pages/company/subsidies";

/* ---------- PAGES ADMIN ---------- */
import AdminLayout from "layouts/admin";

import AdminHome from "pages/admin/home";

import Dashboard from "pages/admin/dashboard";

import ImportData from "pages/admin/import";

/* import News from "pages/admin/news";
import AddNews from "pages/admin/news/new";
import EditNews from "pages/admin/news/edit"; */

import Notifications from "pages/admin/notifications";
import NewShot from "pages/admin/notifications/newShot";
import EditShot from "pages/admin/notifications/editShot";

import NewMessage from "pages/admin/notifications/newMessage";
import EditMessage from "pages/admin/notifications/editMessage";

import NewTargetAudience from "pages/admin/notifications/newTargetAudience";
import EditTargetAudience from "./admin/notifications/editTargetAudience";

import BenefitClub from "pages/admin/benefitClub";
import NewBenefitClub from "pages/admin/benefitClub/new";
import EditBenefitClub from "pages/admin/benefitClub/edit";

import Location from "pages/admin/location";
import NewLocation from "pages/admin/location/new";
import EditLocation from "pages/admin/location/edit";

import Users from "pages/admin/users";
import AddUser from "pages/admin/users/add";
import EditUser from "./admin/users/edit";

import AdminSubsidies from "pages/admin/subsidies";

export default function AppRouter() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route element={<AuthLayout />}>
                        <Route index element={<Login />} />
                        <Route path="recuperacao-senha" element={<RecoverPassword />} />
                        <Route path="nova-senha" element={<NewPassword />} />
                    </Route>

                    <Route path="admin/home" element={<AdminHome />} />
                    <Route path="empresa/home" element={<CompanyHome />} />

                    <Route path="admin" element={<AdminLayout />}>
                        <Route path="dashboard" element={<Dashboard />} />

                        <Route path="importacoes" element={<ImportData />} />

                        <Route path="subsidios" element={<AdminSubsidies />} />

                        {/* 
                          <Route path='noticias' element={<News />} />
                          <Route path='noticias/adicionar' element={<AddNews />} />
                          <Route path='noticias/editar' element={<EditNews />} /> 
                        */}

                        <Route path="notificacoes" element={<Notifications />} />
                        <Route path="notificacoes/novo-disparo" element={<NewShot />} />
                        <Route path="notificacoes/editar-disparo/:id" element={<EditShot />} />

                        <Route path="notificacoes/nova-mensagem" element={<NewMessage />} />
                        <Route path="notificacoes/editar-mensagem/:id" element={<EditMessage />} />

                        <Route path="notificacoes/novo-publico" element={<NewTargetAudience />} />
                        <Route path="notificacoes/editar-publico/:id" element={<EditTargetAudience />} />

                        <Route path="clube-beneficios" element={<BenefitClub />} />
                        <Route path="clube-beneficios/novo-beneficio" element={<NewBenefitClub />} />
                        <Route path="clube-beneficios/editar-beneficio/:id" element={<EditBenefitClub />} />

                        <Route path="localidades" element={<Location />} />
                        <Route path="localidades/nova-localizacao" element={<NewLocation />} />
                        <Route path="localidades/editar-localizacao/:id" element={<EditLocation />} />

                        <Route path="usuarios" element={<Users />} />
                        <Route path="usuarios/adicionar" element={<AddUser />} />
                        <Route path="usuarios/editar" element={<EditUser />} />
                    </Route>

                    <Route path="empresa" element={<CompanyLayout />}>
                        <Route path="subsidios" element={<CompanySubsidies />} />
                    </Route>

                    <Route path="*" element={<Navigate to='/' replace />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
}
