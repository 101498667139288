/* ---------- RESOURCES ---------- */
import { useEffect, useRef, useState } from "react";

/* ---------- CONTRACTS ---------- */
import { IconProps } from "contracts/Icon";

/* ---------- COMPONENTS ---------- */
import { Placeholder } from "react-bootstrap";

/* ---------- STYLES ---------- */
import styles from "components/Icon/styles.module.scss";

/**
 *
 * @param name Nome do ícone incluso em assets/icons
 * @returns
 */
export default function Icon({
    name,
    animation = "wave",
    className = "",
    classPlaceholder = "",
    ...props
}: IconProps) {
    const [icon, setIcon] = useState(null);
    const cancelRef = useRef(false);

    useEffect(() => {
        import(`../../assets/icons/${name}.svg`).then((icon) => {
            !cancelRef.current && setIcon(icon.default)

            cancelRef.current = false
        });

        return () => {
            cancelRef.current = true;
        };
    }, [icon]);

    return !icon ? (
        <Placeholder
            className={`${styles.placeholder_container_icon} ${classPlaceholder}`}
            animation={animation}
        >
            <Placeholder className={styles.placeholder_icon} />
        </Placeholder>
    ) : (
        <img src={icon} className={`${styles.icon} ${className}`} alt="Ícone ilustrativo" {...props} />
    );
}
