import { AxiosRequestConfig } from "axios";
import api from "./api";

interface establishmentData {
    name?: string;
    telenet_id?: number;
    telenet_type?: string;
}

const prefix = "establishment";

const establishmentService = {
    list: (config?: AxiosRequestConfig) => api.get(`/${prefix}`, config),
    store: (data: establishmentData) => api.post("/" + prefix, data),
    get: (id: number | string) => api.get("/" + prefix + "/" + id),
    destroy: (id: number | string) => api.delete("/" + prefix + "/" + id),
    import: (file) =>
        api.post(`/${prefix}-import`, file, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }),
};

export default establishmentService;
