/* ----------- RESOURCES ----------- */
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LatLngExpression, Map as MapLeaflet } from "leaflet";
import { Circle, Popup } from "react-leaflet";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { formatErrors400 } from "utils/formatErrors";
import locationService from "services/locationService";

/* -----------  COMPONENTS ----------- */
import Map from "components/admin/MapCustom";

/* -----------  COMPONENTS ----------- */
import Actions from "components/admin/Actions";
import ButtonIcon from "components/ButtonIcon";
import Label from "components/admin/Label";
import Input from "components/admin/Input";
import { Col, Card, Form } from "react-bootstrap";

/* -----------  COMPONENTS ----------- */
import styles from "pages/admin/location/new/styles.module.scss";

interface LocationMap {
    name: string;
    lat: number;
    lng: number;
    radius: number;
}

const zoom = 8;

const validator = yup.object({
    name: yup.string().required("Nome da localização é obrigatório"),
    lat: yup
        .string()
        .test({
            test: (value) => /([-]){0,1}([0-9])+/.test(value),
            message: "Latitude inválida",
        })
        .required("Latitude é obrigatório")
        .default("0"),
    lng: yup
        .string()
        .test({
            test: (value) => /([-]){0,1}([0-9])+/.test(value),
            message: "Longitude inválida",
        })
        .required("Longitude é obrigatório")
        .default("0"),
    radius_km: yup
        .string()
        .test({
            test: (value) => /([-]){0,1}([0-9])+/.test(value),
            message: "Raio inválido",
        })
        .required("Raio é obrigatório")
        .max(100000)
        .default("0"),
});
export default function NewLocation() {
    const navigate = useNavigate();

    const [isSave, setIsSave] = useState(false);
    const [map, setMap] = useState<MapLeaflet>(null);
    const [location, setLocation] = useState<LocationMap>({
        name: "",
        lat: -22.59372606,
        lng: -48.515625,
        radius: 10,
    } as LocationMap);

    const {
        handleSubmit,
        register,
        setError,
        setValue,
        getValues,
        formState: { isValid, errors },
    } = useForm({
        resolver: yupResolver(validator),
        mode: "onChange",
    });

    const breadcrumbs = [
        {
            label: "Início",
            path: "/admin/home",
        },
        {
            label: "Gestão de Localidades",
            path: "/admin/localidades",
        },
        {
            label: "Nova localização",
            path: "/admin/localidades/nova-localizacao",
        },
    ];

    const handleMove = useCallback(() => {
        if (!map) return;

        const { lat, lng } = map.getCenter();

        setValue("lat", lat);
        setValue("lng", lng);
        // eslint-disable-next-line
    }, [map]);

    const handleSearch = useCallback(() => {
        if (!map) return;

        const [name, lat, lng, radius] = getValues(["name", "lat", "lng", "radius_km"]);

        setLocation({
            name,
            lat,
            lng,
            radius,
        });
        // eslint-disable-next-line
    }, [map]);

    const handleClickPosition = useCallback(
        (event) => {
            if (!map || !event?.latlng) return;

            const { lat, lng } = event.latlng;
            const [name, radius] = getValues(["name", "radius_km"]);

            setLocation({
                name,
                lat,
                lng,
                radius: radius ? radius : 10,
            });

            setValue("lat", lat);
            setValue("lng", lng);
        },
        // eslint-disable-next-line
        [map]
    );

    function handleStoreLocation(form) {
        setIsSave(true);

        locationService
            .store(form)
            .then(() => {
                toast.success("Localização salva com sucesso!", { autoClose: 3000 });
                setTimeout(() => navigate("/admin/localidades"), 1000);
            })
            .catch((err) => {
                if (err.status === 400) {
                    formatErrors400(err.data?.errors ?? [], (e) => {
                        setError(e.name, {
                            type: "manual",
                            message: e.message,
                        });
                    });
                    return toast.error("Verifique os dados e tente novamente", { autoClose: 3000 });
                }

                toast.error("Ocorreu um erro ao criar a localização", { autoClose: 3000 });
            })
            .finally(() => setIsSave(false));
    }

    useEffect(() => {
        let unsubscribe = false;

        const success = (pos: GeolocationPosition) => {
            if (!pos || unsubscribe) return;

            const {
                coords: { latitude, longitude, accuracy },
            } = pos;

            setValue("lat", latitude);
            setValue("lng", latitude);

            setLocation({
                ...location,
                lat: latitude,
                lng: longitude,
                radius: accuracy,
            });
        };
        const error = (err: GeolocationPositionError) => toast.error(err.message);

        window.navigator.geolocation.getCurrentPosition(success, error, {
            maximumAge: Infinity,
            timeout: 50000,
            enableHighAccuracy: true,
        });

        return () => {
            unsubscribe = true;
        };

        // eslint-disable-next-line
    }, [navigate]);

    useEffect(() => {
        map && map.on("move", handleMove);
        map && map.on("click", handleClickPosition);

        return () => {
            map && map.off("move", handleMove);
            map && map.off("click", handleClickPosition);
        };
    }, [map, handleMove, handleClickPosition]);

    return (
        <>
            <Actions
                title="Nova localização"
                breadcrumb={breadcrumbs}
                actionButtons={
                    <>
                        <Col className="col-auto col-lg-3  d-grid">
                            <ButtonIcon
                                icon="removeorcancel-action"
                                className={styles.button_cancel}
                                onClick={() => navigate("/admin/localidades")}
                            >
                                Cancelar
                            </ButtonIcon>
                        </Col>

                        <Col className="col-3  d-grid">
                            <ButtonIcon
                                icon={isSave ? "loading" : "save-action"}
                                className={styles.button_save}
                                onClick={handleSubmit(handleStoreLocation)}
                                disabled={!isValid || isSave}
                            >
                                {isSave ? "Salvando" : "Salvar"}
                            </ButtonIcon>
                        </Col>
                    </>
                }
            />

            <Form onSubmit={(e) => e.preventDefault()} className="col-7 mx-auto">
                <Card className={`${styles.form_card} mb-3`}>
                    <Card.Body>
                        <Form.Group>
                            <Label text="Nome" />
                            <Input placeholder="Digite o nome da localização" {...register("name")} />

                            {errors.name && (
                                <Form.Text className="text_error">{errors.name?.message}</Form.Text>
                            )}
                        </Form.Group>
                    </Card.Body>
                </Card>

                <Card className={`${styles.form_card} mb-3`}>
                    <Card.Body>
                        <Form.Group className="mb-3">
                            <Label text="Localização" className={styles.text_title} />

                            <Col className="d-flex justify-content-between gx-5">
                                <Col className="col-5">
                                    <Label text="Latitude" />

                                    <Input
                                        placeholder="Insira aqui as coordenadas de Latitude"
                                        {...register("lat")}
                                    />

                                    {errors.lat && (
                                        <Form.Text className="text_error">{errors.lat?.message}</Form.Text>
                                    )}
                                </Col>

                                <Col className="col-5">
                                    <Label text="Longitude" />

                                    <Input
                                        placeholder="Insira aqui as coordenadas de Longitude"
                                        {...register("lng")}
                                    />

                                    {errors.lng && (
                                        <Form.Text className="text_error">{errors.lng?.message}</Form.Text>
                                    )}
                                </Col>
                            </Col>

                            <Col className="col-5 mt-4">
                                <Label text="Raio (em km)" />

                                <Input placeholder="Insira um raio de alcance" {...register("radius_km")} />

                                {errors.radius_km && (
                                    <Form.Text className="text_error">{errors.radius_km?.message}</Form.Text>
                                )}
                            </Col>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Col className="col-3 d-grid gap-1 mb-3">
                                <ButtonIcon onClick={handleSearch} icon="search-action">
                                    Pesquisar
                                </ButtonIcon>
                            </Col>

                            <Col className="col-12" style={{ height: 372 }}>
                                <Map
                                    center={{ lat: location.lat, lng: location.lng } as LatLngExpression}
                                    zoom={zoom}
                                    whenCreated={setMap}
                                    scrollWheelZoom
                                    doubleClickZoom
                                >
                                    <Circle
                                        center={[location.lat, location.lng]}
                                        radius={location.radius * 1000}
                                    >
                                        <Popup className={styles.map_popup}>{location.name}</Popup>
                                    </Circle>
                                </Map>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Form>
        </>
    );
}
