/* ----------- RESOURCES ----------- */
import { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import * as yup from "yup";
import { formatErrors400 } from "utils/formatErrors";
import messageService from "services/messageService";

/* ----------- COMPONENTS ----------- */
import Actions from "components/admin/Actions";
import ButtonIcon from "components/ButtonIcon";
import Label from "components/admin/Label";
import Input from "components/admin/Input";
import Textarea from "components/admin/Textarea";
import { Col, Card, Form } from "react-bootstrap";

/* ----------- STYLES ----------- */
import styles from "pages/admin/notifications/newMessage/styles.module.scss";

const validator = yup.object({
    title: yup.string().required("Título é obrigatório").max(40, "Título deve ter no máximo 40 caracteres"),
    message: yup
        .string()
        .required("Mensagem é obrigatório")
        .max(255, "Mensagem deve ter no máximo 255 caracteres"),
});
export default function NewMessage() {
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [isSave, setIsSave] = useState(false);

    const {
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        setError,
    } = useForm({
        resolver: yupResolver(validator),
        mode: "onChange",
    });

    const breadcrumbs = [
        {
            label: "Início",
            path: "/admin/home",
        },
        {
            label: "Gestão de Notificações",
            path: "/admin/notificacoes?tab=messages",
        },
        {
            label: "Nova mensagem",
            path: "/admin/notificacoes/nova-mensagem",
        },
    ];

    function handleChangeTitle(event: ChangeEvent<any>) {
        if (event.target.value?.length > 40) return;

        setTitle(event.target.value);
        setValue("title", event.target.value, { shouldValidate: true });
    }

    function handleChangeMessage(event: ChangeEvent<any>) {
        if (event.target.value?.length > 255) return;

        setMessage(event.target.value);
        setValue("message", event.target.value, { shouldValidate: true });
    }

    function handleStoreMessage(form) {
        setIsSave(true);

        messageService
            .store(form)
            .then(() => {
                toast.success("Mensagem salva com sucesso!", { autoClose: 3000 });
                setTimeout(() => navigate("/admin/notificacoes?tab=messages"), 1000);
            })
            .catch((err) => {
                if (err.status === 400) {
                    formatErrors400(err.data?.errors ?? [], (e) => {
                        setError(e.name, {
                            type: "manual",
                            message: e.message,
                        });
                    });
                    return toast.error("Verifique os dados e tente novamente", { autoClose: 3000 });
                }

                toast.error("Ocorreu um erro ao criar a conta", { autoClose: 3000 });
            })
            .finally(() => setIsSave(false));
    }

    return (
        <>
            <Actions
                title="Nova Mensagem"
                breadcrumb={breadcrumbs}
                actionButtons={
                    <>
                        <Col className="col-auto col-lg-3  d-grid">
                            <ButtonIcon
                                icon="removeorcancel-action"
                                className={styles.button_cancel}
                                onClick={() => navigate("/admin/notificacoes?tab=messages")}
                            >
                                Cancelar
                            </ButtonIcon>
                        </Col>

                        <Col className="col-3  d-grid">
                            <ButtonIcon
                                icon={isSave ? "loading" : "save-action"}
                                className={styles.button_save}
                                disabled={!isValid || isSave}
                                onClick={handleSubmit(handleStoreMessage)}
                            >
                                {isSave ? "Salvando" : "Salvar"}
                            </ButtonIcon>
                        </Col>
                    </>
                }
            />

            <Form className="col-7 mx-auto">
                <Card className={`${styles.form_card} mb-3`}>
                    <Card.Body>
                        <Form.Group>
                            <Label text="Título da mensagem" />

                            <Input
                                value={title}
                                placeholder="Digite aqui o título da mensagem"
                                aria-label="Digite aqui o título da mensagem"
                                onChange={handleChangeTitle}
                            />

                            <p className={styles.text_info_title}>{`Caracteres ${title.length}/40`}</p>

                            {errors.title && (
                                <Form.Text className="text_error">{errors.title?.message}</Form.Text>
                            )}
                        </Form.Group>
                    </Card.Body>
                </Card>

                <Card className={`${styles.form_card} mb-3`}>
                    <Card.Body>
                        <Form.Group>
                            <Label text="Mensagem" />

                            <p className={styles.text_info}>
                                Digite aqui a mensagem da notificação. Lembre-se do limite de caracteres
                            </p>

                            <Textarea
                                value={message}
                                placeholder="Digite aqui a mensagem da notificação"
                                aria-label="Digite aqui a mensagem da notificação"
                                onChange={handleChangeMessage}
                            ></Textarea>

                            <p className={styles.text_info_title}>{`Caracteres ${message.length}/255`}</p>

                            {errors.message && (
                                <Form.Text className="text_error">{errors.message?.message}</Form.Text>
                            )}
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Form>
        </>
    );
}
