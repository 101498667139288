/* ----------- RESOURCES ----------- */
import { forwardRef, Ref } from "react"

/* ----------- CONTRACTS ----------- */
import { ButtonProps } from 'contracts/Buttons'

/* -----------  COMPONENTS ----------- */
import {
    Button as ButtonBs,
} from 'react-bootstrap'

/* -----------  STYLES ----------- */
import styles from 'components/Button/styles.module.scss'

const Button = forwardRef((
    {
        children,
        text,
        eventClick,
        className = '',
        ...props
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>
) => {
    return (
        <ButtonBs
            ref={ref}
            type='button'
            className={`${styles.button} ${className} text-wrap`}
            onClick={eventClick}
            {...props}
        >
            { children || text || '' } 
        </ButtonBs>
    )
})

export default Button