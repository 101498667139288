const menusNavbar = [
    /* {
            title: 'Notícias',
            icon: "news-navbar",
            path: '/admin/noticias'
        }, */
    {
        title: "Dashboard",
        icon: "dashboard-navbar",
        path: "/admin/dashboard",
    },
    {
        title: "Importar",
        icon: "import-navbar",
        path: "/admin/importacoes",
    },
    {
        title: "Subsídios",
        icon: "attach-money-navbar",
        path: "/admin/subsidios",
    },
    {
        title: "Notificações",
        icon: "notification-navbar",
        path: "/admin/notificacoes",
    },
    {
        title: "Clube de Benefícios",
        icon: "percent-navbar",
        path: "/admin/clube-beneficios",
    },
    {
        title: "Localidades",
        icon: "location-navbar",
        path: "/admin/localidades",
    },
    {
        title: "Usuários",
        icon: "users-navbar",
        path: "/admin/usuarios",
    },
];

const menusHome = [
    /* {
            title: 'Notícias',
            icon: NewsIcon,
            path: '/admin/noticias'
        }, */
    {
        title: "Dashboard",
        icon: "dashboard",
        path: "/admin/dashboard",
    },
    {
        title: "Importar",
        icon: "import",
        path: "/admin/importacoes",
    },
    {
        title: "Subsídios",
        icon: "attach-money",
        path: "/admin/subsidios",
    },
    {
        title: "Notificações",
        icon: "notification",
        path: "/admin/notificacoes",
    },
    {
        title: "Clube de Benefícios",
        icon: "percent",
        path: "/admin/clube-beneficios",
    },
    {
        title: "Localidades",
        icon: "location",
        path: "/admin/localidades",
    },
    {
        title: "Usuários",
        icon: "users",
        path: "/admin/usuarios",
    },
];

export { menusNavbar, menusHome };
