import { AxiosRequestConfig } from "axios";
import api from "./api";

interface locationData {
    name?: string;
    lat?: string;
    lng?: string;
    radius_km?: number;
}

const prefix = "location";

const locationService = {
    list: (config?: AxiosRequestConfig) => api.get(`/${prefix}`, config),
    store: (data: locationData) => api.post(`/${prefix}`, data),
    update: (id: number | string, data: locationData) => api.put(`/${prefix}/${id}`, data),
    get: (id: number | string) => api.get(`/${prefix}/${id}`),
    destroy: (id: number | string) => api.delete(`/${prefix}/${id}`),
};

export default locationService;
