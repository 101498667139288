import { AxiosRequestConfig } from "axios";
import api from "./api";
interface notificationData {
    name?: string;
    locations?: any;
    cards?: any;
}

const prefix = "notification";

const notificationService = {
    list: (config?: AxiosRequestConfig) => api.get(`/${prefix}`, config),
    store: (data: notificationData) => api.post(`/${prefix}`, data),
    update: (id: number | string, data: notificationData) => api.put(`/${prefix}/${id}`, data),
    get: (id: number | string) => api.get(`/${prefix}/${id}`),
    destroy: (id: number | string) => api.delete(`/${prefix}/${id}`),
};

export default notificationService;
