/* ----------- RESOURCES ----------- */
import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "providers/AuthProvider";

/* ----------- ICONS ----------- */
import Logo from "assets/logo.svg";

/* ----------- COMPONENTS ----------- */
import Icon from "components/Icon";
import { Navbar as NavbarBs, Container, Nav, Col, Button } from "react-bootstrap";

/* ----------- STYLES ----------- */
import styles from "components/Navbar/styles.module.scss";

interface MenuProps {
    title: string;
    icon: string;
    path: string;
}

interface NavbarProps {
    menus: MenuProps[];
}

export default function Navbar({ menus }: NavbarProps) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { signOut } = useAuth();
    const [showNavbar, setShowNavbar] = useState(false);

    function isCurrentPath(path: string) {
        const pathSanitize = path.replaceAll(/\/+/g, "\\$&");
        return RegExp(`^(${pathSanitize})`).test(pathname);
    }

    return (
        <NavbarBs className={styles.navbar} expand="lg" expanded={showNavbar}>
            <Container fluid>
                <NavbarBs.Brand>
                    <img
                        src={Logo}
                        alt="Logo do Brasil convênio"
                        width="43"
                        height="43"
                        title="Brasil Convênios"
                        className={`${styles.logo} d-inline-block align-top`}
                        onClick={() => navigate("home")}
                    />

                    <p
                        className={`${styles.logo_name} d-inline-block d-xxl-none d-xl-none d-lg-none d-md-none`}
                    >
                        Brasil Convênios
                    </p>
                </NavbarBs.Brand>

                <NavbarBs.Toggle
                    aria-controls="navbarMobile"
                    className="text-white"
                    children={"☰"}
                    onClick={() => setShowNavbar((x) => !x)}
                />

                <NavbarBs.Collapse
                    id="navbarMobile"
                    className="justify-content-center mt-4 mt-xxl-0 mt-xl-0 mt-lg-0"
                >
                    <Nav className="d-flex gap-2 align-items-center">
                        {menus.map(({ title, icon, path }, index) => (
                            <Nav.Link
                                as={Link}
                                key={`navbar-item-${index}`}
                                to={path}
                                className={`${styles.link} ${
                                    isCurrentPath(path) ? styles.active : ""
                                } d-flex justify-content-center align-items-center`}
                                onClick={() => setShowNavbar(false)}
                            >
                                <Icon
                                    name={icon}
                                    className={styles.icon}
                                    classPlaceholder={styles.icon}
                                    alt={title}
                                />

                                {title}
                            </Nav.Link>
                        ))}
                    </Nav>
                </NavbarBs.Collapse>

                <Col className="col-auto d-none d-xxl-block d-xl-block d-lg-block ms-3">
                    <Button
                        className={`${styles.button_logout} d-flex justify-content-center align-items-center`}
                        onClick={() => signOut()}
                    >
                        <Icon
                            name="logout-navbar"
                            alt="Sair"
                            title="Sair"
                            className={`${styles.logout_icon} mx-auto`}
                        />
                    </Button>
                </Col>
            </Container>
        </NavbarBs>
    );
}
