/* ----------- RESOURCES ----------- */
import { forwardRef, Ref } from "react";
import * as reactIs from "react-is";

import { useIMask } from "react-imask";

/* -----------  CONTRACTS ----------- */
import { InputProps } from "contracts/Admin";

/* ----------- COMPONENTS ----------- */
import Icon from "components/Icon";
import { InputGroup, FormControl, FormControlProps } from "react-bootstrap";

/* ----------- STYLES ----------- */
import styles from "components/admin/Input/styles.module.scss";

const Input = forwardRef(
    (
        { iconLeft, iconRight, eventClick, mask = null, type = "text", className = "", ...props }: InputProps,
        ref: Ref<HTMLInputElement>
    ) => {
        const { value: maskValue, setValue, ref: refMask } = useIMask({ mask });

        function handleChange(e) {
            props.onChange && props.onChange(e);
            mask && setValue(e.target.value);
        }

        return (
            <InputGroup className={`${styles.input_group} ${className}`}>
                {/* Div do icon esquerdo */}
                <InputGroup.Text className={styles.box_icon_left}>
                    {iconLeft &&
                        (reactIs.isElement(iconLeft) ? (
                            iconLeft
                        ) : (
                            <Icon
                                name={iconLeft as string}
                                className={styles.icon}
                                classPlaceholder={styles.icon}
                                alt="Ícone representativo"
                            />
                        ))}
                </InputGroup.Text>

                {/* recurso de mascara do input */}
                <FormControl
                    ref={mask ? refMask : ref}
                    type={type}
                    className={`${styles.input} p-0`}
                    value={mask ? maskValue : props.value}
                    onChange={handleChange}
                    {...(props as FormControlProps)}
                />

                {/* Div do icon direito */}
                <InputGroup.Text className={styles.box_icon_right} onClick={eventClick}>
                    {iconRight &&
                        (reactIs.isElement(iconRight) ? (
                            iconRight
                        ) : (
                            <Icon
                                name={iconRight as string}
                                className={styles.icon}
                                classPlaceholder={styles.icon}
                                alt="Ícone representativo de alguma ação ou do tipo de entrada de formulário"
                            />
                        ))}
                </InputGroup.Text>
            </InputGroup>
        );
    }
);

export default Input;
