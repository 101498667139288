
/* ----------- RESOURCES ----------- */
import { useState } from 'react'
import EyeOpenIcon from 'assets/icons/eye-open.svg'
import EyeClosedIcon from 'assets/icons/eye-closed.svg'
import KeyIcon from 'assets/icons/key.svg'
import SaveIcon from 'assets/icons/save-action.svg'
import CancelIcon from 'assets/icons/removeorcancel-action.svg'

/* ----------- COMPONENTS ----------- */
import Actions from 'components/admin/Actions'
import ButtonIcon from 'components/ButtonIcon'
import Label from 'components/admin/Label'
import Input from 'components/admin/Input'
import SwitchSimples from 'components/admin/SwitchSimples'
import {
    Col,
    Card,
    Form
} from 'react-bootstrap'

/* ----------- STYLES ----------- */
import styles from 'pages/admin/users/edit/styles.module.scss'

export default function EditUser() {
    const [activeUser, setActiveUser] = useState(false)
    const [showPassword, setShowPassword] = useState({
        password: false,
        password_confirmation: false
    })

    const breadcrumbs = [
        {
            label: 'Início',
            path: '/home'
        },
        {
            label: 'Gestão de Usuários',
            path: '/admin/usuarios'
        },
        {
            label: 'Editar usuário',
            path: '/admin/usuarios/editar'
        }
    ]

    function handleShowPassword(inputKey: 'password' | 'password_confirmation') {
        showPassword[inputKey] = !showPassword[inputKey]
        setShowPassword({ ...showPassword })
    }

    return (
        <>
            <Actions
                title="Editar usuário"
                breadcrumb={breadcrumbs}
                actionButtons={
                    <>
                        <Col className="col-auto col-lg-3 d-grid" onClick={() => setActiveUser((x) => !x)}>
                            {activeUser ? (
                                <ButtonIcon className={styles.button_active}>Ativar</ButtonIcon>
                            ) : (
                                <ButtonIcon className={styles.button_inactive}>Inativar</ButtonIcon>
                            )}
                        </Col>

                        <Col className="col-3  d-grid">
                            <ButtonIcon icon={CancelIcon} className={styles.button_cancel}>
                                Cancelar
                            </ButtonIcon>
                        </Col>

                        <Col className="col-3  d-grid">
                            <ButtonIcon icon={SaveIcon} className={styles.button_save}>
                                Salvar
                            </ButtonIcon>
                        </Col>
                    </>
                }
            />

            <Form className="col-7 mx-auto">
                <Card className={`${styles.form_card} mb-3`}>
                    <Card.Body>
                        <Col className="row p-0 m-0">
                            <Form.Group className="col-6">
                                <Label text="Nome" />
                                <Input placeholder="Nome" aria-label="Nome" />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <Label text="Sobrenome" />
                                <Input placeholder="Sobrenome" aria-label="Sobrenome" />
                            </Form.Group>
                        </Col>
                    </Card.Body>
                </Card>

                <Card className={`${styles.form_card} mb-3`}>
                    <Card.Body>
                        <Form.Group className="col-8">
                            <Label text="Email" />
                            <Input type="email" placeholder="exemplo@mail.com" aria-label="exemplo@mail.com" />
                        </Form.Group>
                    </Card.Body>
                </Card>

                <Card className={`${styles.form_card} mb-3`}>
                    <Card.Body className="px-1">
                        <Col className="row p-0 m-0">
                            <Form.Group className="col-6">
                                <Label text="Senha" />
                                <Input
                                    type={showPassword.password ? "text" : "password"}
                                    className={`${styles.input_password}`}
                                    iconLeft={KeyIcon}
                                    iconRight={showPassword.password ? EyeOpenIcon : EyeClosedIcon}
                                    placeholder="Digite aqui sua senha"
                                    aria-label="Digite aqui sua senha"
                                    eventClick={() => handleShowPassword("password")}
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <Label text="Confirmar senha" />
                                <Input
                                    type={showPassword.password_confirmation ? "text" : "password"}
                                    className={`${styles.input_password}`}
                                    iconLeft={KeyIcon}
                                    iconRight={showPassword.password_confirmation ? EyeOpenIcon : EyeClosedIcon}
                                    placeholder="Repita a senha"
                                    aria-label="Repita a senha"
                                    eventClick={() => handleShowPassword("password_confirmation")}
                                />
                            </Form.Group>
                        </Col>
                    </Card.Body>
                </Card>

                <Card className={`${styles.form_card} mb-3`}>
                    <Card.Body>
                        <Form.Group className="col-6">
                            <Label text="Administrador ?" />
                            <SwitchSimples />
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Form>
        </>
    );
}