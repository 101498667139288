/* ---------- RESOURCES ---------- */
import { AxiosRequestConfig } from "axios";
import useSWR from "swr";
import api from "services/api";

const configSWR = {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    revalidateOnMount: true,
    loadingTimeout: 0,
};

export function useFetch<Data = any, Error = any>(url: string, config?: AxiosRequestConfig) {
    const swrHooks = useSWR<Data, Error>(
        url,
        async (url: string) => {
            const response = await api.get(url, config);

            return response.data;
        },
        configSWR
    );

    return swrHooks;
}
