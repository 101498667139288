/* ----------- COMPONENTS ----------- */
import {
    FormLabel
} from 'react-bootstrap'

/* ----------- CONTRACTS ----------- */
import { LabelProps } from 'contracts/Auth'

/* ----------- STYLES ----------- */
import styles from 'components/auth/Label/styles.module.scss'

export default function LabelAuth({ text, className='', ...props }: LabelProps) {
    return (
        <FormLabel
            className={`${styles.form_label} ${className}`}
            aria-label={text}
            {...props}
        >
            { text }
        </FormLabel>
    )
}