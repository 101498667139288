import { AxiosRequestConfig } from "axios";
import api from "./api";

interface cardData {
    name?: string;
    telenet_id?: number;
    telenet_type?: string;
}

const prefix = "card";

const cardService = {
    list: (config?: AxiosRequestConfig) => api.get(`/${prefix}`, config),
    store: (data: cardData) => api.post(`/${prefix}`, data),
    update: (id: number | string, data: cardData) => api.put(`/${prefix}/${id}`, data),
    get: (id: number | string) => api.get(`/${prefix}/${id}`),
    destroy: (id: number | string) => api.delete(`/${prefix}/${id}`),
};

export default cardService;
