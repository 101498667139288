/* ----------- RESOURCES ----------- */
import { ForwardedRef, forwardRef } from "react";
import { Circle } from "react-leaflet";

/* ---------- CONTRACTS ---------- */
import { PopoverLocationProps } from "contracts/Admin";

/* ---------- COMPONENTS ---------- */
import { Col, Overlay, Popover } from "react-bootstrap";
import Map from "components/admin/MapCustom";

/* ----------- STYLES ----------- */
import styles from "components/admin/PopoverLocation/styles.module.scss";

const PopoverLocation = forwardRef(
    (
        { location, children = null, zoom = 8, classPopover = "", ...props }: PopoverLocationProps,
        ref: ForwardedRef<any>
    ) => {
        return (
            <Overlay target={props.target ?? ref} {...props}>
                <Popover className={`${styles.popover_map_container} ${classPopover}`}>
                    <Popover.Body className={styles.popover_body}>
                        {children ? (
                            children
                        ) : (
                            <>
                                <Col className={`${styles.container_map} col-12`}>
                                    <Map center={[location.lat, location.lng]} zoom={zoom}>
                                        <Circle
                                            center={[location.lat, location.lng]}
                                            radius={location.radius * 1000}
                                        ></Circle>
                                    </Map>
                                </Col>

                                <Col>
                                    <Col className="mb-2">
                                        <p className={styles.popover_text_title}>{location.title}</p>
                                        <p className={styles.popover_text_info}>
                                            {`${location.lat}, ${location.lng}`}
                                        </p>
                                    </Col>

                                    <Col>
                                        <p className={styles.popover_text_title}>Raio</p>
                                        <p className={styles.popover_text_info}>{`${location.radius} km`}</p>
                                    </Col>
                                </Col>
                            </>
                        )}
                    </Popover.Body>
                </Popover>
            </Overlay>
        );
    }
);

export default PopoverLocation;
