/* ----------- RESOURCES ----------- */
import { forwardRef, MouseEvent, useEffect, useRef, useState } from 'react'
import * as reactIs from 'react-is'

/* ----------- ICONS ----------- */
import ChevronBottomIcon from 'assets/icons/chevron-right-bottom.svg'

/* ----------- CONTRACTS ----------- */
import { MultipleSelectProps } from 'contracts/Admin'

/* ----------- COMPONENTS ----------- */
import Form from 'react-bootstrap/Form'

/* ----------- STYLES ----------- */
import styles from 'components/admin/MultipleSelect/styles.module.scss'

interface DivRef {
    current: HTMLDivElement | null
}

const MultipleSelect = forwardRef((
    {
        items,
        onChange,
        className = '',
        placeholder = '',
        ...props
    }: MultipleSelectProps,
    divRef?: DivRef
) => {
    const [show, setShow] = useState(false)
    const divCustomRef = useRef<HTMLDivElement | null>(null)
    const dropdownRef = useRef<HTMLUListElement | null>(null)

    divRef ??= divCustomRef

    useEffect(() => {
        function pageClick(event: globalThis.MouseEvent) {
            if (
                show &&
                !Object.is(event.target, divRef.current) &&
                !Object.is(event.target, dropdownRef.current) &&
                !divRef.current?.contains(event.target as Node)
            )
                setShow(false)

        }

        let doc = document.querySelector('body')
        doc && doc.addEventListener('click', pageClick)

        return () => {
            doc && doc.removeEventListener('click', pageClick)
        }

    }, [show, divRef])


    /**
     * Mostra e esconde os items do select quando clicado no próprio select
     * @param event MouseEvent
     */
    function showDropdown(event: MouseEvent<HTMLDivElement>) {
        if (
            (Object.is(event.target, divRef.current) || !dropdownRef.current?.contains(event.target as Node)) &&
            !Object.is(event.target, dropdownRef.current)
        )
            setShow(x => !x)
    }

    return (
        <div
            ref={divRef}
            className={`${styles.multiple_select} ${className}`}
            onClick={showDropdown}
            {...props}
        >
            <div className={styles.placeholder}>
                { placeholder }
            </div>
            <img className={styles.chevron_icon} src={ChevronBottomIcon} alt='Ícone de seleção' />

            <ul ref={dropdownRef} className={`${styles.dropdown} ${show ? styles.active : ''}`} >
                {
                    items.map(({ key, checked, item }, i) => (
                        <li
                            key={`dropdown-item-${i}`}
                            className={styles.dropdown_item}
                            onClick={() => onChange(key)}
                        >
                            <Form.Check
                                checked={checked}
                                disabled
                            />
                            {
                                reactIs.isElement(item) ? item : (<span> {item} </span>)
                            }
                        </li>
                    ))
                }
            </ul>
        </div>
    )
})

export default MultipleSelect