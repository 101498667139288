/* ----------- RESOURCES ----------- */
import { MouseEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useMultipleSelect } from "hooks/useMultipleSelect";
import { formatErrors400 } from "utils/formatErrors";
import targetService from "services/targetService";
import locationService from "services/locationService";
import cardService from "services/cardService";

/* ----------- ICONS ----------- */
import UnionIcon from "assets/icons/union.svg";

/* ----------- COMPONENTS ----------- */
import Actions from "components/admin/Actions";
import ButtonIcon from "components/ButtonIcon";
import Label from "components/admin/Label";
import Input from "components/admin/Input";
import MultipleSelect from "components/admin/MultipleSelect";
import Badge from "components/admin/Badge";
import PopoverLocation from "components/admin/PopoverLocation";
import { Col, Card as CardBC, Form } from "react-bootstrap";

/* ----------- STYLES ----------- */
import styles from "pages/admin/notifications/editTargetAudience/styles.module.scss";

interface Card {
    id: number;
    name: string;
}

interface Location {
    id: number;
    name: string;
    lat: string;
    lng: string;
    radius_km: number;
}

const actionTargetName = `location_`;

const breadcrumbs = [
    {
        label: "Início",
        path: "/admin/home",
    },
    {
        label: "Gestão de notificações",
        path: "/admin/notificacoes?tab=target-public",
    },
    {
        label: "Novo Público",
        path: "/admin/notificacoes/editar-publico",
    },
];

const hasItemSelected = ({ checked, key }) => checked && key !== "all";

const validator = yup.object({
    name: yup.string().required("Título é obrigatório").max(255, "Título não pode ter mais de 255 caracteres"),
    locations: yup
        .array()
        .of(yup.number().required("Selecione pelo menos 1 localização"))
        .min(1, "Selecione pelo menos 1 localização"),
    cards: yup
        .array()
        .of(yup.number())
        .required("O card é obrigatório")
        .min(1, "Selecione pelo menos 1 cartão"),
});
export default function EditTargetAudience() {
    const navigation = useNavigate();
    const { id: targetAudienceId } = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        setError,
    } = useForm({
        resolver: yupResolver(validator),
        mode: "onChange",
    });

    const [isUpdating, setIsUpdating] = useState(false);
    const [showLocation, setShowLocation] = useState({});
    const [actionTarget, setActionTarget] = useState(null);
    const [badgeLocations, setBadgeLocations] = useState<Location[]>([]);

    const [cards, selectCard, setCards] = useMultipleSelect([], true);
    const [locations, selectLocation, setLocations] = useMultipleSelect([], true);

    const locationsSelected = locations.filter(hasItemSelected);
    const cardsSelected = cards.filter(hasItemSelected);

    function handleMouseEnterLocation(id: number, event: MouseEvent<HTMLSpanElement>) {
        showLocation[`${actionTargetName}${id}`] = true;
        setShowLocation({ ...showLocation });
        setActionTarget(event.currentTarget);
    }

    function handleMouseLeaveLocation(id: number) {
        showLocation[`${actionTargetName}${id}`] = false;
        setShowLocation({ ...showLocation });
        setActionTarget(null);
    }

    function handleUpdateTargetAudience(form) {
        setIsUpdating(true);

        targetService
            .update(targetAudienceId, form)
            .then(() => {
                toast.success("Publico alvo atualizado com sucesso!", { autoClose: 3000 });
                setTimeout(() => navigation("/admin/notificacoes?tab=target-public"), 1000);
            })
            .catch((err) => {
                if (err.status === 400) {
                    formatErrors400(err.data?.errors ?? [], (e) => {
                        setError(e.name, {
                            type: "manual",
                            message: e.message,
                        });
                    });
                    return toast.error("Verifique os dados e tente novamente", { autoClose: 3000 });
                }

                toast.error("Ocorreu um erro ao atualizar o publico alvo", { autoClose: 3000 });
            })
            .finally(() => setIsUpdating(false));
    }

    function formatLocationMultipleSelect(locations: Location[]) {
        return locations.map(({ id, name, lat, lng, radius_km }) => ({
            key: id,
            checked: false,
            item: (
                <Col key={`location-item-${id}`} className="d-flex justify-content-between align-items-center">
                    <p className={`${styles.select_title} m-0`}>{name}</p>

                    <span
                        className={`${styles.badge_circle} ${styles.badge_success}`}
                        onMouseEnter={(event) => handleMouseEnterLocation(id, event)}
                        onMouseLeave={() => handleMouseLeaveLocation(id)}
                    >
                        <img className={styles.union_icon} src={UnionIcon} alt="ícone da marca union" />

                        <PopoverLocation
                            show={showLocation[`${actionTargetName}${id}`]}
                            target={actionTarget}
                            location={{
                                title: name,
                                lat: parseFloat(lat),
                                lng: parseFloat(lng),
                                radius: radius_km,
                            }}
                        />
                    </span>
                </Col>
            ),
        }));
    }

    const formatCardMultipleSelect = (cards: Card[]) =>
        cards?.map((card) => ({
            key: card.id,
            checked: false,
            item: card.name,
        }));

    useEffect(() => {
        const loadProps = async () => {
            try {
                const { data: l1 } = await locationService.list();
                const { data: c1 } = await cardService.list();
                const { data: targetAudience } = await targetService.get(targetAudienceId);

                let lFormat = formatLocationMultipleSelect(l1 ?? []);
                lFormat = lFormat.map((x) => ({
                    ...x,
                    checked: targetAudience?.locations?.some((y) => y.id === x.key),
                }));

                let cFormat = formatCardMultipleSelect(c1 ?? []);
                cFormat = cFormat.map((x) => ({
                    ...x,
                    checked: targetAudience?.cards?.some((y) => y.id === x.key),
                }));

                setValue("name", targetAudience.name, { shouldValidate: true });

                l1?.data?.forEach(({ id }: Location) => {
                    showLocation[`${actionTargetName}${id}`] = false;
                });

                setShowLocation({ ...showLocation });
                setCards([...cards, ...cFormat]);
                setLocations([...locations, ...lFormat]);
                setBadgeLocations(l1?.data ?? []);
            } catch (err) {
                toast.error("Ocorreu um erro ao carregar os dados do publico alvo", { autoClose: 3000 });
            }
        };

        loadProps();
    }, []);

    useEffect(
        () =>
            setValue(
                "locations",
                locationsSelected.map(({ key }) => key),
                { shouldValidate: true }
            ),
        // eslint-disable-next-line
        [locations]
    );

    useEffect(
        () =>
            setValue(
                "cards",
                cardsSelected.map(({ key }) => key),
                { shouldValidate: true }
            ),
        // eslint-disable-next-line
        [cards]
    );

    return (
        <>
            <Actions
                title="Editar Público"
                breadcrumb={breadcrumbs}
                actionButtons={
                    <>
                        <Col className="col-3  d-grid">
                            <ButtonIcon
                                icon="removeorcancel-action"
                                className={styles.button_cancel}
                                onClick={() => navigation("/admin/notificacoes?tab=target-public")}
                            >
                                Cancelar
                            </ButtonIcon>
                        </Col>

                        <Col className="col-3  d-grid">
                            <ButtonIcon
                                icon={isUpdating ? "loading" : "save-action"}
                                className={styles.button_save}
                                disabled={!isValid || isUpdating}
                                onClick={handleSubmit(handleUpdateTargetAudience)}
                            >
                                {isUpdating ? "Atualizando" : "Atualizar"}
                            </ButtonIcon>
                        </Col>
                    </>
                }
            />

            <Form onSubmit={(e) => e.preventDefault()} className="col-7 mx-auto">
                <CardBC className={`${styles.form_card} mb-3`}>
                    <CardBC.Body>
                        <Form.Group>
                            <Label text="Título do público alvo" />

                            <Input
                                placeholder="Digite aqui o título do público"
                                aria-label="Digite aqui o título do público"
                                {...register("name")}
                            />

                            {errors.name && (
                                <Form.Text className="text_error">{errors.name?.message}</Form.Text>
                            )}
                        </Form.Group>
                    </CardBC.Body>
                </CardBC>

                <CardBC className={`${styles.form_card} mb-3`}>
                    <CardBC.Body>
                        <Form.Group>
                            <Label text="Localização" />

                            <p className={styles.text_info}>
                                Selecione a(s) localização(ões) para onde desejar disparar a notificação
                            </p>

                            <MultipleSelect
                                placeholder="Procure aqui uma ou mais localização(ões)"
                                aria-label="Procure aqui uma ou mais localização(ões)"
                                items={locations}
                                onChange={selectLocation}
                            />

                            {errors.locations && (
                                <Form.Text className="text_error">{errors.locations?.message}</Form.Text>
                            )}
                        </Form.Group>

                        <Col className="mt-4">
                            <Label text="Localização(ões) selecionada(s):" />

                            <Col className="d-flex gap-2 flex-wrap">
                                {locationsSelected.length <= 0 ? (
                                    <p className={styles.text_info}>Nenhuma localização selecionada.</p>
                                ) : (
                                    locationsSelected.map(({ key }) => (
                                        <Badge
                                            key={`badge-location-${key}`}
                                            onRemoveBadge={() => selectLocation(key)}
                                            iconClosed
                                            pill
                                        >
                                            {badgeLocations.find((x) => x.id === key)?.name ?? ""}
                                        </Badge>
                                    ))
                                )}
                            </Col>
                        </Col>
                    </CardBC.Body>
                </CardBC>

                <CardBC className={`${styles.form_card} mb-3`}>
                    <CardBC.Body>
                        <Form.Group>
                            <Label text="Cartões" />

                            <MultipleSelect
                                placeholder="Procure aqui um ou mais cartão(ões)"
                                aria-label="Procure aqui um ou mais cartão(ões)"
                                items={cards}
                                onChange={selectCard}
                            />

                            {errors.cards && (
                                <Form.Text className="text_error">{errors.cards?.message}</Form.Text>
                            )}
                        </Form.Group>

                        <Col className="mt-4">
                            <Label text="Cartão(ões) selecionado(s):" />

                            <Col className="d-flex gap-2 flex-wrap">
                                {cardsSelected.length <= 0 ? (
                                    <p className={styles.text_info}>
                                        Nenhum cartão selecionado. Selecione cartões para serem listados aqui
                                    </p>
                                ) : (
                                        cardsSelected.slice(0, 10).map(({ key, item: name }) => (
                                        <Badge
                                            key={`badge-card-${key}`}
                                            onRemoveBadge={() => selectCard(key)}
                                            iconClosed
                                            pill
                                        >
                                            {name}
                                        </Badge>
                                    ))
                                )}

                                {cardsSelected.length > 10 && (
                                    <Badge key={`badge-establishment-more`} pill>
                                        ...
                                    </Badge>
                                )}
                            </Col>
                        </Col>
                    </CardBC.Body>
                </CardBC>
            </Form>
        </>
    );
}
