const menusNavbar = [
    {
        title: "Subsídios",
        icon: "attach-money-navbar",
        path: "/empresa/subsidios",
    },
];

const menusHome = [
    {
        title: "Subsídios",
        icon: "attach-money",
        path: "/empresa/subsidios",
    },
];

export { menusNavbar, menusHome };
