import { AxiosRequestConfig } from "axios";
import api from "services/api";

interface CompanyData {
    id?: number;
    name?: string;
}

const prefix = "company";

const companyService = {
    list: (config?: AxiosRequestConfig) => api.get(`/${prefix}`, config),
    store: (data: CompanyData) => api.post(`/${prefix}`, data),
    update: (id: number | string, data: CompanyData) => api.put(`/${prefix}/${id}`, data),
    get: (id: number | string) => api.get(`/${prefix}/${id}`),
    destroy: (id: number | string) => api.delete(`/${prefix}/${id}`),
    import: (file) =>
        api.post(`/${prefix}-import`, file, {
            timeout: 0,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }),
};

export default companyService;
