/* ----------- RESOURCES ----------- */
import { Dispatch, SetStateAction, useState } from "react";

/* ----------- CONTRACT ----------- */
import { MultipleSelectState } from "contracts/Admin";

type MultipleSelectAction = [
    MultipleSelectState[],
    /**
     * Atualiza o checkbox selecionado
     * @param key 'all' | 'none' | string | number
     *
     * 'all' - funciona como um toggle, seleciona todos os valores caso todos os valores não esteja seleciona
     * ou remove todos os valores caso todos estejam selecionados
     *
     * 'none' - Remove todos os valores selecionado
     */
    (keyItem: "all" | "none" | string | number) => void,
    Dispatch<SetStateAction<MultipleSelectState[]>>
];

/**
 * Um hook para selecionar múltiplos itens.
 * @param {MultipleSelectAction} initialState Array com os itens inicial no formato de MultipleSelectState
 * @param {boolean} selectAll Se deve ter um checkbox para selecionar todos os itens
 */
export function useMultipleSelect(
    initialState: MultipleSelectState[] | (() => MultipleSelectState[]),
    selectAll: boolean = false
): MultipleSelectAction {
    const [selected, setSelected] = useState<MultipleSelectState[]>(() => {
        let selectedOption: MultipleSelectState[] = [];
        selectedOption = typeof initialState === "function" ? initialState() : initialState;

        if (selectAll) selectedOption.unshift({ key: "all", checked: false, item: "Selecionar todos" });

        return selectedOption;
    });

    /**
     * Atualiza o checkbox selecionado
     * @param key 'all' | 'none' | string | number
     *
     * 'all' - funciona como um toggle, seleciona todos os valores caso todos os valores não esteja seleciona
     * ou remove todos os valores caso todos estejam selecionados
     *
     * 'none' - Remove todos os valores selecionado
     */
    function selectedOption(keyItem: "all" | "none" | string | number) {
        if (keyItem === "all") {
            const some = selected.every(({ checked }) => checked === true) ? false : true;

            const checkedAll = ({ key, item }) => ({ key, item, checked: some });
            setSelected((x) => x.map(checkedAll));

            return;
        }

        if (keyItem === "none") {
            const deCheckedAll = ({ key, item }) => ({ key, item, checked: false });
            setSelected((x) => x.map(deCheckedAll));

            return;
        }

        const changeChecked = ({ key, checked, item }) => ({
            key,
            checked: keyItem === key ? !checked : checked,
            item,
        });

        setSelected((x) => x.map(changeChecked));
    }

    return [selected, selectedOption, setSelected];
}
