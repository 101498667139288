/* ----------- RESOURCES ----------- */
import { useState } from "react";
import { toast } from "react-toastify";

import companyService from "services/companyService";
import establishmentService from "services/establishmentService";

/* ----------- COMPONENTS ----------- */
import Actions from "components/admin/Actions";
import ButtonIcon from "components/ButtonIcon";
import UploadFile from "components/admin/UploadFile";
import { Form, Card, Col } from "react-bootstrap";

/* ----------- STYLES ----------- */
import styles from "pages/admin/import/styles.module.scss";

export default function Import() {
    const [fileCompany, setFileCompany] = useState<File | null>(null);
    const [fileAccreditedNetwork, setFileAccreditedNetwork] = useState<File | null>(null);

    const [isSubmit, setIsSubmit] = useState({
        file1: false,
        file2: false,
    });

    const breadcrumbs = [
        {
            label: "Início",
            path: "/admin/home",
        },
        {
            label: "Importações",
            path: "/admin/importacoes",
        },
    ];

    function handleChangeFileClient(files) {
        if (!files) return;

        setFileCompany(files[0]);
    }

    function handleChangeFileAccreditedNetwork(files) {
        if (!files) return;

        setFileAccreditedNetwork(files[0]);
    }

    function handleImport() {
        if (fileCompany) {
            const formData = new FormData();
            formData.append("file_companies", fileCompany);

            setIsSubmit((t) => ({ ...t, file1: true }));

            companyService
                .import(formData)
                .then(() => toast.success("Dados dos clientes importado", { autoClose: 3000 }))
                .catch((err) => {
                    if (err.status === 400) {
                        return toast.error("O formato dos dados são válidos", { autoClose: 3000 });
                    }

                    toast.error("Ocorreu um erro ao importar os dados dos clientes", { autoClose: 3000 });
                })
                .finally(() => setIsSubmit((t) => ({ ...t, file1: false })));
        }

        if (fileAccreditedNetwork) {
            const formData = new FormData();
            formData.append("file_establishments", fileAccreditedNetwork);

            setIsSubmit((t) => ({ ...t, file2: true }));

            establishmentService
                .import(formData)
                .then(() => toast.success("Dados dos credenciados importados"))
                .catch((err) => {
                    if (err.status === 400) {
                        return toast.error("O formato dos dados são válidos", { autoClose: 3000 });
                    }

                    toast.error("Ocorreu um erro ao importar os dados dos credenciados", { autoClose: 3000 });
                })
                .finally(() => setIsSubmit((t) => ({ ...t, file2: false })));
        }
    }

    return (
        <>
            <Actions
                breadcrumb={breadcrumbs}
                title="Importações"
                actionButtons={
                    <>
                        <Col className="col-auto col-lg-3  d-grid">
                            <ButtonIcon
                                icon={isSubmit.file1 || isSubmit.file2 ? "loading" : "csv-action"}
                                disabled={
                                    (!fileCompany && !fileAccreditedNetwork) ||
                                    isSubmit.file1 ||
                                    isSubmit.file2
                                }
                                onClick={handleImport}
                            >
                                {isSubmit.file1 || isSubmit.file2 ? "Importando" : "Importar"}
                            </ButtonIcon>
                        </Col>
                    </>
                }
            />

            <h3 className={styles.form_title}>Clientes (Brasil Convênios)</h3>
            <Col className="d-flex flex-wrap justify-content-around mb-5">
                <Card className={`${styles.form_card} col-11 mx-auto mb-3 col-xl-5 mx-xl-0 mb-xl-0`}>
                    <Card.Body>
                        <Form onSubmit={(e) => e.preventDefault()}>
                            <Form.Group>
                                <UploadFile
                                    label="Carregar arquivo CSV"
                                    accept="text/csv, application/vnd.ms-excel, application/vnd.oasis.opendocument.spreadsheet"
                                    labelAccept="CSV, XLS, XLSX, ODS"
                                    inputFileState={fileCompany}
                                    onChange={handleChangeFileClient}
                                    onRemoveFile={() => setFileCompany(null)}
                                />
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>

                <Card className={`${styles.form_card} col-11 mx-auto mb-3 col-xl-5 mx-xl-0 mb-xl-0`}>
                    <Card.Body>
                        <h5 className={styles.h5_info}>Importante:</h5>

                        <p className={styles.text_info}>
                            Para cada cliente importado, o acesso do cliente será realizado na seguinte URL:{" "}
                            <strong>https://admin.brasilconvenios.com.br</strong>
                        </p>

                        <p className={styles.text_info}>
                            O usuário do cliente será o <strong>e-mail</strong> cadastro no sistema e a
                            primeira senha de acesso será:{" "}
                            <strong className={styles.info_password}>CÓDIGO@CNPJ/CPF</strong>
                        </p>
                        <p className={styles.text_info}>
                            exem: <strong>28295@23467809822</strong>
                        </p>

                        <p className={styles.text_info}>
                            Caso o cliente já exista, as suas informações serão atualizadas.
                        </p>
                    </Card.Body>
                </Card>
            </Col>

            <h3 className={styles.form_title}>Rede Credenciada</h3>
            <Col className="d-flex flex-wrap justify-content-around mb-3">
                <Card className={`${styles.form_card} col-11 mx-auto mb-3 col-xl-5 mx-xl-0 mb-xl-0`}>
                    <Card.Body>
                        <Form onSubmit={(e) => e.preventDefault()}>
                            <Form.Group>
                                <UploadFile
                                    label="Carregar arquivo CSV"
                                    accept="text/csv, application/vnd.ms-excel, application/vnd.oasis.opendocument.spreadsheet"
                                    labelAccept="CSV, XLS, XLSX, ODS"
                                    inputFileState={fileAccreditedNetwork}
                                    onChange={handleChangeFileAccreditedNetwork}
                                    onRemoveFile={() => setFileAccreditedNetwork(null)}
                                />
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>

                <Card className={`${styles.form_card} col-11 mx-auto mb-3 col-xl-5 mx-xl-0 mb-xl-0`}>
                    <Card.Body>
                        <h5 className={styles.h5_info}>Importante:</h5>

                        <p className={styles.text_info}>
                            Caso o credenciado já exista, as suas informações serão atualizadas.
                        </p>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
}
