import { AxiosRequestConfig } from "axios";
import api from "./api";

interface messageData {
    title?: string;
    message?: string;
}

const prefix = "message";

const messageService = {
    list: (config?: AxiosRequestConfig) => api.get(`/${prefix}`, config),
    store: (data: messageData) => api.post(`/${prefix}`, data),
    update: (id: number | string, data: messageData) => api.put(`/${prefix}/${id}`, data),
    get: (id: number | string) => api.get(`/${prefix}/${id}`),
    destroy: (id: number | string) => api.delete(`/${prefix}/${id}`),
};

export default messageService;
