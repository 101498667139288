/* ----------- RESOURCES ----------- */
import { useNavigate } from 'react-router-dom'


import ImgIllustration from "assets/admin_esquece_senha.svg";

/* ----------- COMPONENTS ----------- */
import Input from "components/auth/Input";
import Label from "components/auth/Label";
import Link from "components/auth/Link";
import Button from "components/Button";
import { Card, Col, Form } from "react-bootstrap";

/* ----------- STYLES ----------- */
import styles from "pages/auth/newPassword/styles.module.scss";

export default function NewPassword() {
    const navigate = useNavigate();

    function sendNewPassword() {
        alert("Senha alterada com sucesso!");
        setTimeout(() => navigate("/"), 1000);
    }

    return (
        <Card className={`${styles.card_container} col-11 col-xxl-8 col-xl-8 col-lg-8 col-sm-11`}>
            <Card.Body className={`${styles.card_body} col-12 d-flex m-0`}>
                <Col className="col-12 col-xll-6 col-xl-6 col-lg-6 col-sm-12">
                    <Col className="col-12 col-xxl-9 col-xl-9 col-lg-9 col-md-10 col-sm-12 mx-auto mt-3">
                        <h1 className={styles.h1}>Nova Senha</h1>

                        <p className={`${styles.description} mb-0`}>
                            Defina a nova senha de acesso para a conta xxx.xxxxxxxx@mail.com.
                        </p>

                        <p className={styles.description}>
                            Lembre-se de que a senha deve ter entre 6 e 8 caracteres, e deve conter ao menos um
                            número, um símbolo, letras maiúsculas e minúsculas.
                        </p>

                        <Form style={{ marginBottom: ".85rem" }}>
                            <Form.Group style={{ marginBottom: "1rem" }}>
                                <Label text="Nova Senha" />

                                <Input
                                    type="password"
                                    iconLeft="key"
                                    iconEye
                                    placeholder="Digite aqui a sua nova senha"
                                    aria-label="Digite aqui a sua nova senha"
                                />
                            </Form.Group>

                            <Form.Group style={{ marginBottom: "2.2rem" }}>
                                <Label text="Repita nova senha" />

                                <Input
                                    type="password"
                                    iconLeft="key"
                                    iconEye
                                    placeholder="Repita aqui a sua nova senha"
                                    aria-label="Repita aqui a sua nova senha"
                                />
                            </Form.Group>

                            <Form.Group className="col-12 mx-auto p-0">
                                <Col className="d-grid gap-2">
                                    <Button text="Enviar" eventClick={sendNewPassword} />
                                </Col>
                            </Form.Group>
                        </Form>

                        <Col className="col-12 text-center">
                            <Link text="Cancelar" back />
                        </Col>
                    </Col>
                </Col>
                <Col className="d-none d-xxl-flex d-xl-flex d-lg-flex col-6 justify-content-center align-items-center">
                    <img
                        src={ImgIllustration}
                        className={styles.img_illustration}
                        alt="Imagem ilustrativa sobre recuperação de senha"
                        aria-label="Imagem ilustrativa sobre recuperação de senha"
                    />
                </Col>
            </Card.Body>
        </Card>
    );
}