import { AxiosRequestConfig } from "axios";
import api from "./api";

export interface BenefitData {
    type?: string;
    title?: string;
    details?: string;
    start_date?: string;
    end_date?: string;
    active?: boolean;
    picture_url?: string;
    establishments?: any;
    cards?: any;
}

const prefix = "benefit";

const benefitService = {
    list: (config?: AxiosRequestConfig) => api.get(`/${prefix}`, config),
    store: (data: BenefitData, config?: AxiosRequestConfig) => api.post(`/${prefix}`, data, config),
    update: (id: number | string, data: BenefitData) => api.put(`/${prefix}/${id}`, data),
    get: (id: number | string) => api.get(`/${prefix}/${id}`),
    destroy: (id: number | string) => api.delete(`/${prefix}/${id}`),
};

export default benefitService;
